var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var Benefit = function Benefit(_ref) {
  var benefit = _ref.benefit;
  return _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center",
    wrap: "wrap"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: "xl"
  }, _react.default.createElement(_reactNative.Image, {
    source: {
      uri: benefit.photoUrl
    },
    style: {
      width: 32,
      height: 32
    }
  })), _react.default.createElement(_Spacing.default, {
    flex: 1,
    direction: "column",
    alignItems: "flex-start"
  }, _react.default.createElement(_core.Typography, null, _react.default.createElement("strong", null, benefit.title)), _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, benefit.description)));
};

var _default = Benefit;
exports.default = _default;