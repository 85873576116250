var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _spacing = require("../../spacing");

function SectionView(props) {
  var sectionStyles = [props.style];

  if (props.spacing) {
    sectionStyles.push({
      padding: _spacing.Spacing[props.spacing]
    });
  }

  if (props.spacingVertical) {
    sectionStyles.push({
      paddingVertical: _spacing.Spacing[props.spacingVertical]
    });
  }

  if (props.spacingHorizontal) {
    sectionStyles.push({
      paddingHorizontal: _spacing.Spacing[props.spacingHorizontal]
    });
  }

  if (props.spacingTop) {
    sectionStyles.push({
      paddingTop: _spacing.Spacing[props.spacingTop]
    });
  }

  if (props.spacingRight) {
    sectionStyles.push({
      paddingRight: _spacing.Spacing[props.spacingRight]
    });
  }

  if (props.spacingBottom) {
    sectionStyles.push({
      paddingBottom: _spacing.Spacing[props.spacingBottom]
    });
  }

  if (props.spacingLeft) {
    sectionStyles.push({
      paddingLeft: _spacing.Spacing[props.spacingLeft]
    });
  }

  return _react.default.createElement(_reactNative.View, {
    style: sectionStyles
  }, props.children);
}

var _default = SectionView;
exports.default = _default;