Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useSessionTitleStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginLeft: theme.spacing.leftMenu
    },
    speakerContainer: {
      marginVertical: theme.spacing.l,
      marginHorizontal: theme.spacing.m
    },
    noAccessText: {
      backgroundColor: theme.colors.errorColor + '22'
    },
    title: {
      marginBottom: theme.spacing.s
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: {
      marginLeft: 0
    },
    speakerContainer: {
      flex: 0,
      marginTop: theme.spacing.s,
      marginBottom: theme.spacing.s
    },
    title: {
      fontSize: '1.2rem'
    },
    subtitle: {
      fontSize: '1rem'
    }
  };
}).buildHook();
var _default = useSessionTitleStyles;
exports.default = _default;