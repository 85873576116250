Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)({
  indicator: {
    marginRight: 15,
    opacity: 0.6
  },
  description: {
    opacity: 0.85
  }
});
var _default = styles;
exports.default = _default;