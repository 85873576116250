var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../core/checklist/selectors");

var _checklistView = _interopRequireDefault(require("./checklist-view"));

var ChecklistContainer = function ChecklistContainer() {
  var checklist = (0, _reactRedux.useSelector)(_selectors.selectChecklist);
  return _react.default.createElement(_checklistView.default, {
    checklist: checklist
  });
};

var _default = ChecklistContainer;
exports.default = _default;