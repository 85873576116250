Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrentTip = exports.isTipTheDjInProgress = exports.getNumClaps = exports.isFollowingFestival = exports.isFollowFestivalInProgress = exports.getNumFollowers = exports.getFollowing = exports.isFollowing = exports.isFollowInProgress = void 0;

var _constants = require("./constants");

var isFollowInProgress = function isFollowInProgress(state, userId) {
  return state[_constants.NAME].hasIn(['followInProgress', parseInt(userId, 10)]);
};

exports.isFollowInProgress = isFollowInProgress;

var isFollowing = function isFollowing(state, userId) {
  return state[_constants.NAME].hasIn(['following', parseInt(userId, 10)]);
};

exports.isFollowing = isFollowing;

var getFollowing = function getFollowing(state) {
  return state[_constants.NAME].get('following');
};

exports.getFollowing = getFollowing;

var getNumFollowers = function getNumFollowers(state, userId) {
  return state[_constants.NAME].getIn(['numFollowers', userId], 0);
};

exports.getNumFollowers = getNumFollowers;

var isFollowFestivalInProgress = function isFollowFestivalInProgress(state, festivalId) {
  return state[_constants.NAME].hasIn(['followFestivalInProgress', parseInt(festivalId, 10)]);
};

exports.isFollowFestivalInProgress = isFollowFestivalInProgress;

var isFollowingFestival = function isFollowingFestival(state, festivalId) {
  return state[_constants.NAME].hasIn(['followingFestival', parseInt(festivalId, 10)]);
};

exports.isFollowingFestival = isFollowingFestival;

var getNumClaps = function getNumClaps(state) {
  return state[_constants.NAME].get('numClaps', 0);
};

exports.getNumClaps = getNumClaps;

var isTipTheDjInProgress = function isTipTheDjInProgress(state) {
  return state[_constants.NAME].get('tipTheDjInProgress', false);
};

exports.isTipTheDjInProgress = isTipTheDjInProgress;

var getCurrentTip = function getCurrentTip(state) {
  return state[_constants.NAME].getIn(['tipsQueue', 0]);
};

exports.getCurrentTip = getCurrentTip;