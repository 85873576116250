var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserRoles = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _selectors = require("../user/selectors");

var _constants = require("./constants");

var _selectors2 = require("../event-info/selectors");

var _reactNativeFirebase = _interopRequireDefault(require("react-native-firebase"));

var getUserRoles = (0, _toolkit.createAsyncThunk)(_constants.GET_USER_ROLES, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(uid, _ref) {
    var getState, eventId, userId, getRoles, _yield$getRoles, data;

    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            eventId = (0, _selectors2.selectEventInfoId)(getState());
            userId = (0, _selectors.getFirebaseUserUid)(getState()) || uid;

            if (eventId) {
              _context.next = 5;
              break;
            }

            throw Error('Invalid event id');

          case 5:
            if (userId) {
              _context.next = 7;
              break;
            }

            throw Error('Invalid user id');

          case 7:
            getRoles = _reactNativeFirebase.default.functions.httpsCallable('roles-getUserRolesAndFeatures');
            _context.next = 10;
            return getRoles({
              eventId: eventId
            });

          case 10:
            _yield$getRoles = _context.sent;
            data = _yield$getRoles.data;
            return _context.abrupt("return", data);

          case 13:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getUserRoles = getUserRoles;