Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.padding = exports.dimensions = void 0;

var _reactNative = require("react-native");

var dimensions = {
  fullHeight: _reactNative.Dimensions.get('window').height,
  fullWidth: _reactNative.Dimensions.get('window').width
};
exports.dimensions = dimensions;
var padding = {
  sm: 10,
  md: 20,
  lg: 30,
  xl: 40
};
exports.padding = padding;