Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  chatContainer: {
    flex: 1,
    justifyContent: 'flex-end'
  },
  gradientStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0
  },
  djContainer: {
    position: 'absolute',
    top: 48,
    marginLeft: 16
  },
  djNameAndAvatar: {
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    paddingRight: 16,
    backgroundColor: 'rgba(0,0,0,0.7)',
    paddingVertical: 4,
    paddingLeft: 4,
    borderRadius: 100
  },
  djAvatar: {
    marginRight: 8,
    overflow: 'hidden',
    borderWidth: 1
  },
  djName: {
    color: 'white',
    fontWeight: 'bold'
  },
  beatsIcon: {
    marginRight: 4
  },
  djBeats: {
    color: 'white'
  },
  replayLabel: {
    backgroundColor: 'white',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 20
  },
  replayLabelText: {
    color: 'black',
    fontWeight: 'bold'
  },
  livelabelContainer: {
    position: 'absolute',
    paddingHorizontal: 16,
    top: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  liveLabel: {
    backgroundColor: 'red',
    paddingLeft: 2,
    paddingRight: 4,
    paddingVertical: 4,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 20
  },
  liveLabelText: {
    color: '#fff',
    lineHeight: 11,
    height: 11
  },
  numUsersLive: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'rgba(0,0,0, 0.7)',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2
  },
  timeCount: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0, 0.7)',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 2,
    marginLeft: 8
  },
  numUsersLiveText: {
    color: '#fff',
    lineHeight: 12,
    height: 12,
    marginLeft: 4
  },
  fullScreenButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 8
  }
});

exports.default = _default;