var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useOpenState;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _rxjs = require("rxjs");

var _react = _interopRequireDefault(require("react"));

var OpenState = function () {
  function OpenState() {
    (0, _classCallCheck2.default)(this, OpenState);
    this.state = new _rxjs.BehaviorSubject(false);
    this._state$ = this.state.asObservable();
  }

  (0, _createClass2.default)(OpenState, [{
    key: "setState",
    value: function setState(state) {
      this.state.next(state);
    }
  }, {
    key: "getState",
    value: function getState() {
      return this._state$;
    }
  }]);
  return OpenState;
}();

var instance = new OpenState();

function useOpenState(component) {
  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      open = _React$useState2[0],
      setOpen = _React$useState2[1];

  var setState = _react.default.useCallback(function (state) {
    instance.setState(state);
  }, []);

  _react.default.useEffect(function () {
    var subscription = instance.getState().subscribe(function (state) {
      setOpen(state);
    });
    return function () {
      subscription.unsubscribe();
    };
  }, [setOpen]);

  return [open, setState];
}