Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useBoothDetailStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    image: {
      width: '100%',
      paddingBottom: 250,
      cursor: 'pointer'
    },
    content: {
      padding: theme.spacing.l,
      backgroundColor: theme.colors.alternativeBackground
    },
    social: {
      cursor: 'pointer'
    },
    offer: {
      backgroundColor: theme.colors.pageBackground,
      borderColor: theme.colors.borderColor,
      borderWidth: 1
    }
  };
}).buildHook();
var _default = useBoothDetailStyles;
exports.default = _default;