var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var _live = _interopRequireDefault(require("../live"));

var _user = _interopRequireDefault(require("../user"));

var InitialState = _immutable.default.fromJS({
  followInProgress: _immutable.default.Map(),
  followFestivalInProgress: _immutable.default.Map(),
  numFollowers: _immutable.default.Map(),
  following: _immutable.default.Map(),
  followingFestival: _immutable.default.Map(),
  numClaps: 0,
  tipTheDjInProgress: false,
  tipsQueue: _immutable.default.List()
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.LOAD_NUMBER_OF_FOLLOWERS:
      newState = newState.setIn(['numFollowers', action.userId], action.numFollowers);
      break;

    case _actionTypes.RESET_MY_FOLLOWING:
      newState = newState.set('following', InitialState.get('following'));
      break;

    case _actionTypes.RESET_MY_FESTIVAL_FOLLOWING:
      newState = newState.set('followingFestival', InitialState.get('followingFestival'));
      break;

    case _actionTypes.LOAD_PART_OF_MY_FOLLOWING:
      for (var i in action.following) {
        newState = newState.setIn(['following', action.following[i].id], true);
      }

      break;

    case _actionTypes.FOLLOW_IN_PROGRESS:
      newState = newState.setIn(['followInProgress', action.userId], true);
      break;

    case _actionTypes.FOLLOW_FAILED:
      newState = newState.deleteIn(['followInProgress', action.userId]);
      break;

    case _actionTypes.FOLLOW_COMPLETED:
      newState = newState.deleteIn(['followInProgress', action.userId]);
      newState = newState.setIn(['following', action.userId], true);
      newState = newState.updateIn(['numFollowers', action.userId], 0, function (value) {
        return value + 1;
      });
      break;

    case _actionTypes.UNFOLLOW_COMPLETED:
      newState = newState.deleteIn(['followInProgress', action.userId]);
      newState = newState.deleteIn(['following', action.userId]);
      newState = newState.updateIn(['numFollowers', action.userId], 0, function (value) {
        return value - 1;
      });
      break;

    case _actionTypes.LOAD_PART_OF_MY_FOLLOWING_FESTIVAL:
      for (var _i in action.followingFestival) {
        newState = newState.setIn(['followingFestival', action.followingFestival[_i].id], true);
      }

      break;

    case _actionTypes.FOLLOW_FESTIVAL_IN_PROGRESS:
      newState = newState.setIn(['followFestivalInProgress', action.festivalId], true);
      break;

    case _actionTypes.FOLLOW_FESTIVAL_FAILED:
      newState = newState.deleteIn(['followFestivalInProgress', action.festivalId]);
      break;

    case _actionTypes.FOLLOW_FESTIVAL_COMPLETED:
      newState = newState.deleteIn(['followFestivalInProgress', action.festivalId]);
      newState = newState.setIn(['followingFestival', action.festivalId], true);
      break;

    case _actionTypes.UNFOLLOW_FESTIVAL_COMPLETED:
      newState = newState.deleteIn(['followFestivalInProgress', action.festivalId]);
      newState = newState.deleteIn(['followingFestival', action.festivalId]);
      break;

    case _actionTypes.NEW_FOLLOWER:
      newState = newState.updateIn(['numFollowers', action.userId], 0, function (value) {
        return value + 1;
      });
      break;

    case _actionTypes.LOST_FOLLOWER:
      newState = newState.updateIn(['numFollowers', action.userId], 0, function (value) {
        return value - 1;
      });
      break;

    case _actionTypes.CLAP:
      newState = newState.update('numClaps', 0, function (value) {
        return value + 1;
      });
      break;

    case _actionTypes.REFRESH_CLAPS:
      newState = newState.update('numClaps', 0, function (value) {
        return Math.max(value, action.numClaps);
      });
      break;

    case _actionTypes.TIP_IN_PROGRESS:
      newState = newState.set('tipTheDjInProgress', true);
      break;

    case _actionTypes.TIP_FAILED:
      newState = newState.set('tipTheDjInProgress', false);
      break;

    case _actionTypes.TIP_COMPLETED:
      newState = newState.set('tipTheDjInProgress', false);
      newState = newState.update('tipsQueue', function (list) {
        return list.push(_immutable.default.fromJS(action.user));
      });
      break;

    case _actionTypes.NEW_TIP:
      newState = newState.update('tipsQueue', function (list) {
        return list.push(_immutable.default.fromJS(action.user));
      });
      break;

    case _actionTypes.CONSUME_TIP:
      newState = newState.update('tipsQueue', function (list) {
        return list.shift();
      });
      break;

    case _live.default.constants.LEAVE:
      newState = InitialState;
      newState = newState.set('followInProgress', state.get('followInProgress'));
      newState = newState.set('numFollowers', state.get('numFollowers'));
      newState = newState.set('following', state.get('following'));
      newState = newState.set('followingFestival', state.get('followingFestival'));
      break;

    case _user.default.constants.USER_LOGOUT:
      newState = InitialState;
      break;

    default:
      break;
  }

  return newState;
}