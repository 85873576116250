var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasReconnecting;

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

function CanvasReconnecting(_ref) {
  var width = _ref.width,
      height = _ref.height;
  var fontSize = Math.min(Math.round(width / 100 * 10), 16);
  return _react.default.createElement(_reactKonva.Group, {
    width: width,
    height: height
  }, _react.default.createElement(_reactKonva.Rect, {
    fill: "#101010E0",
    width: width,
    height: height
  }), _react.default.createElement(_reactKonva.Text, {
    fontSize: fontSize,
    height: height,
    width: width,
    fill: "#FFF",
    wrap: "none",
    ellipsis: true,
    align: "center",
    verticalAlign: "middle",
    text: _i18n.default.translate('chat.reconnecting')
  }));
}