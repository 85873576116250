var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Avatar;

var _react = _interopRequireDefault(require("react"));

var _ImageWithLoading = _interopRequireDefault(require("../image/ImageWithLoading"));

var _Section = _interopRequireDefault(require("../section/Section"));

var _core = require("@material-ui/core");

var _absoluteSpinner = _interopRequireDefault(require("../../../components/common/absolute-spinner"));

var _styles = _interopRequireDefault(require("./styles"));

function Avatar(_ref) {
  var source = _ref.source,
      name = _ref.name,
      onPress = _ref.onPress,
      size = _ref.size;
  var classes = (0, _styles.default)();
  return _react.default.createElement(_ImageWithLoading.default, {
    imageProps: {
      style: {
        width: size,
        height: size,
        borderRadius: size
      }
    },
    url: source,
    containerProps: {
      alignItems: 'center',
      justifyContent: 'center',
      onClick: onPress
    },
    errorFallback: _react.default.createElement(_Section.default, {
      color: "#000",
      style: {
        width: size,
        height: size,
        borderRadius: size,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }, _react.default.createElement(_core.Typography, {
      className: classes.textColor
    }, name && name.split(' ').slice(0, 2).reduce(function (initial, chunk, i) {
      return i === 0 ? chunk[0] : "".concat(initial).concat(chunk[0]);
    }, ''))),
    loadingIndicator: _react.default.createElement(_absoluteSpinner.default, null)
  });
}