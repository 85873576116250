Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textWhite: {
      color: theme.palette.headerText.main
    },
    fontWeightNormal: {
      fontWeight: '400'
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    navLinks: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 540
    },
    loginMailSent: {
      backgroundColor: theme.palette.successColor.main
    },
    bgWhite: {
      backgroundColor: theme.palette.containerBackground.main
    }
  };
});
var _default = styles;
exports.default = _default;