Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_NUMBER_OF_FOLLOWERS = exports.LOAD_PART_OF_MY_FOLLOWING_FESTIVAL = exports.LOAD_PART_OF_MY_FOLLOWING = exports.RESET_MY_FESTIVAL_FOLLOWING = exports.RESET_MY_FOLLOWING = exports.CONSUME_TIP = exports.NEW_TIP = exports.TIP_FAILED = exports.TIP_COMPLETED = exports.TIP_IN_PROGRESS = exports.REFRESH_CLAPS = exports.CLAP = exports.LOST_FOLLOWER = exports.NEW_FOLLOWER = exports.UNFOLLOW_FESTIVAL_COMPLETED = exports.FOLLOW_FESTIVAL_COMPLETED = exports.FOLLOW_FESTIVAL_FAILED = exports.FOLLOW_FESTIVAL_IN_PROGRESS = exports.UNFOLLOW_COMPLETED = exports.FOLLOW_COMPLETED = exports.FOLLOW_FAILED = exports.FOLLOW_IN_PROGRESS = void 0;
var FOLLOW_IN_PROGRESS = 'appraisal/FOLLOW_IN_PROGRESS';
exports.FOLLOW_IN_PROGRESS = FOLLOW_IN_PROGRESS;
var FOLLOW_FAILED = 'appraisal/FOLLOW_FAILED';
exports.FOLLOW_FAILED = FOLLOW_FAILED;
var FOLLOW_COMPLETED = 'appraisal/FOLLOW_COMPLETED';
exports.FOLLOW_COMPLETED = FOLLOW_COMPLETED;
var UNFOLLOW_COMPLETED = 'appraisal/UNFOLLOW_COMPLETED';
exports.UNFOLLOW_COMPLETED = UNFOLLOW_COMPLETED;
var FOLLOW_FESTIVAL_IN_PROGRESS = 'appraisal/FOLLOW_FESTIVAL_IN_PROGRESS';
exports.FOLLOW_FESTIVAL_IN_PROGRESS = FOLLOW_FESTIVAL_IN_PROGRESS;
var FOLLOW_FESTIVAL_FAILED = 'appraisal/FOLLOW_FESTIVAL_FAILED';
exports.FOLLOW_FESTIVAL_FAILED = FOLLOW_FESTIVAL_FAILED;
var FOLLOW_FESTIVAL_COMPLETED = 'appraisal/FOLLOW_FESTIVAL_COMPLETED';
exports.FOLLOW_FESTIVAL_COMPLETED = FOLLOW_FESTIVAL_COMPLETED;
var UNFOLLOW_FESTIVAL_COMPLETED = 'appraisal/UNFOLLOW_FESTIVAL_COMPLETED';
exports.UNFOLLOW_FESTIVAL_COMPLETED = UNFOLLOW_FESTIVAL_COMPLETED;
var NEW_FOLLOWER = 'appraisal/NEW_FOLLOWER';
exports.NEW_FOLLOWER = NEW_FOLLOWER;
var LOST_FOLLOWER = 'appraisal/LOST_FOLLOWER';
exports.LOST_FOLLOWER = LOST_FOLLOWER;
var CLAP = 'appraisal/CLAP';
exports.CLAP = CLAP;
var REFRESH_CLAPS = 'appraisal/REFRESH_CLAPS';
exports.REFRESH_CLAPS = REFRESH_CLAPS;
var TIP_IN_PROGRESS = 'appraisal/TIP_IN_PROGRESS';
exports.TIP_IN_PROGRESS = TIP_IN_PROGRESS;
var TIP_COMPLETED = 'appraisal/TIP_COMPLETED';
exports.TIP_COMPLETED = TIP_COMPLETED;
var TIP_FAILED = 'appraisal/TIP_FAILED';
exports.TIP_FAILED = TIP_FAILED;
var NEW_TIP = 'appraisal/NEW_TIP';
exports.NEW_TIP = NEW_TIP;
var CONSUME_TIP = 'appraisal/CONSUME_TIP';
exports.CONSUME_TIP = CONSUME_TIP;
var RESET_MY_FOLLOWING = 'appraisal/RESET_MY_FOLLOWING';
exports.RESET_MY_FOLLOWING = RESET_MY_FOLLOWING;
var RESET_MY_FESTIVAL_FOLLOWING = 'appraisal/RESET_MY_FESTIVAL_FOLLOWING';
exports.RESET_MY_FESTIVAL_FOLLOWING = RESET_MY_FESTIVAL_FOLLOWING;
var LOAD_PART_OF_MY_FOLLOWING = 'appraisal/LOAD_PART_OF_MY_FOLLOWING';
exports.LOAD_PART_OF_MY_FOLLOWING = LOAD_PART_OF_MY_FOLLOWING;
var LOAD_PART_OF_MY_FOLLOWING_FESTIVAL = 'appraisal/LOAD_PART_OF_MY_FOLLOWING_FESTIVAL';
exports.LOAD_PART_OF_MY_FOLLOWING_FESTIVAL = LOAD_PART_OF_MY_FOLLOWING_FESTIVAL;
var LOAD_NUMBER_OF_FOLLOWERS = 'appraisal/LOAD_NUMBER_OF_FOLLOWERS';
exports.LOAD_NUMBER_OF_FOLLOWERS = LOAD_NUMBER_OF_FOLLOWERS;