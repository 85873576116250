Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useAudioVideoStyle = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    buttonSize: {
      justifyContent: 'center',
      alignItems: 'center',
      width: 24,
      height: 24
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, {
  buttonSize: {
    width: 16,
    height: 16
  }
}).buildHook();
var _default = useAudioVideoStyle;
exports.default = _default;