var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _user = _interopRequireDefault(require("../../../core/user"));

var _emailLoginContainer = _interopRequireDefault(require("./emailLogin-container"));

var _auth = _interopRequireDefault(require("../../../core/auth"));

var _default = (0, _reactRedux.connect)(null, {
  login: _user.default.actions.firebaseLogin,
  resetPassword: _auth.default.actions.resetPassword
})(_emailLoginContainer.default);

exports.default = _default;