Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MAIN_WEBSITE = exports.DJ_PLATFORM = exports.TERMS_OF_USE = exports.PRIVACY_POLICY = void 0;
var PRIVACY_POLICY = "http://www.soundmix.live/Soundmix-Privacy-Policy.pdf";
exports.PRIVACY_POLICY = PRIVACY_POLICY;
var TERMS_OF_USE = "http://www.soundmix.live/Soundmix-Terms.pdf";
exports.TERMS_OF_USE = TERMS_OF_USE;
var DJ_PLATFORM = "https://dj.soundmix.live";
exports.DJ_PLATFORM = DJ_PLATFORM;
var MAIN_WEBSITE = "https://soundmix.live";
exports.MAIN_WEBSITE = MAIN_WEBSITE;