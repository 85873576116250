Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    background: {
      backgroundColor: theme.palette.containerBackground.main,
      minHeight: 0
    },
    container: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.containerBackground.main,
      minHeight: 0
    },
    arrowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      cursor: 'pointer'
    },
    assignTicketBox: {
      display: 'flex',
      flexDirection: 'column'
    },
    borderBottom: {
      borderBottom: "1px dotted ".concat(theme.palette.borderColor.main)
    },
    boldText: {
      fontWeight: 'bold',
      marginLeft: 5
    }
  };
});
var _default = styles;
exports.default = _default;