var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _react = _interopRequireDefault(require("react"));

var _ThemeGrid = _interopRequireDefault(require("./ThemeGrid"));

var _icons = require("@material-ui/icons");

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _DesignSystem = require("../../../../theme/DesignSystem");

var _styles = _interopRequireDefault(require("./styles"));

function GreenRoomWelcomeMessage(_ref) {
  var children = _ref.children;
  var classes = (0, _styles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  return _react.default.createElement(_DynamicSection.default, {
    style: {
      flex: 1,
      padding: spacing.xxl,
      justifyContent: 'center'
    },
    shaping: "cards",
    darkColor: "#008000",
    lightColor: "#F2F2F2"
  }, function (color) {
    return _react.default.createElement(_core.Grid, {
      style: {
        flex: 1
      },
      direction: "column",
      alignItems: "center",
      justify: "center",
      wrap: "nowrap",
      container: true
    }, _react.default.createElement(_ThemeGrid.default, {
      item: true,
      container: true,
      color: color,
      alignItems: "center",
      justify: "center"
    }, _react.default.createElement(_core.Box, {
      p: 2,
      className: classes.messageContainer
    }, _react.default.createElement(_core.Typography, {
      align: "center",
      variant: "h5",
      gutterBottom: true
    }, _react.default.createElement(_icons.Favorite, {
      style: {
        marginRight: 20,
        color: '#008000'
      }
    }), _i18n.default.translate('backstage.welcomeToTheGreenRoom')), _react.default.createElement(_core.Typography, {
      align: "center"
    }, _i18n.default.translate('backstage.welcomeToTheGreenRoomText')), _react.default.createElement(_core.Box, {
      m: 2
    }), _react.default.createElement(_core.Typography, {
      align: "center"
    }, _i18n.default.translate('backstage.welcomeToTheGreenRoomText2'))), _react.default.createElement(_core.Grid, {
      style: {
        width: 'max-content'
      },
      item: true,
      container: true,
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_core.Box, {
      m: 2
    }), children)));
  });
}

var _default = GreenRoomWelcomeMessage;
exports.default = _default;