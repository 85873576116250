Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numberFormatter = numberFormatter;
exports.toFixedNumber = toFixedNumber;

function numberFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.00$/, '') + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.00$/, '') + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.00$/, '') + 'K';
  }

  return num;
}

function toFixedNumber(num, digits, base) {
  var pow = Math.pow(base || 10, digits);
  return Math.round(num * pow) / pow;
}