var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _TextButton = _interopRequireDefault(require("../../../theme/components/buttons/TextButton"));

var _core = require("@material-ui/core");

var _reactFeather = require("react-feather");

var BackButton = function BackButton(props) {
  return _react.default.createElement(_TextButton.default, {
    iconLeft: _react.default.createElement(_reactFeather.ArrowLeft, {
      size: '0.875rem'
    }),
    onPress: props.onBackNavigation,
    style: styles.backButton
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, _i18n.default.t('global.back')));
};

var styles = _reactNative.StyleSheet.create({
  backButton: {
    display: 'flex',
    alignItems: 'center'
  }
});

var _default = BackButton;
exports.default = _default;