Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ON_CHAT_REACTIONS_CHANGED_BY_ID = exports.SUBSCRIBE_CHAT_REACTIONS_CHANGE_BY_ID = exports.INCREASE_CHAT_REACTION = exports.NAME = void 0;
var NAME = 'chat-reactions';
exports.NAME = NAME;
var INCREASE_CHAT_REACTION = "".concat(NAME, "/INCREASE_CHAT_REACTION");
exports.INCREASE_CHAT_REACTION = INCREASE_CHAT_REACTION;
var SUBSCRIBE_CHAT_REACTIONS_CHANGE_BY_ID = "".concat(NAME, "/SUBSCRIBE_CHAT_REACTIONS_CHANGE_BY_ID");
exports.SUBSCRIBE_CHAT_REACTIONS_CHANGE_BY_ID = SUBSCRIBE_CHAT_REACTIONS_CHANGE_BY_ID;
var ON_CHAT_REACTIONS_CHANGED_BY_ID = "".concat(NAME, "/ON_CHAT_REACTIONS_CHANGED_BY_ID");
exports.ON_CHAT_REACTIONS_CHANGED_BY_ID = ON_CHAT_REACTIONS_CHANGED_BY_ID;