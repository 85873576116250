var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _selectors2 = require("../../../core/ticket-addons/selectors");

var _selectors3 = require("../../../core/tickets/selectors");

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../theme/components/typography/Typography"));

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _checkoutContainer = _interopRequireDefault(require("../../checkout/checkout-container"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _myTicketsPageStyles = _interopRequireDefault(require("./my-tickets-page-styles"));

var _ticketCardView = _interopRequireDefault(require("./ticket-card/ticket-card-view"));

var _unassignedTicketsListView = _interopRequireDefault(require("./unassignedTicketsList/unassigned-tickets-list-view"));

var _selectors4 = require("../../../core/checkout/selectors");

var _assignedTicketsListView = _interopRequireDefault(require("./assignedTicketsList/assigned-tickets-list-view"));

var MyTicketsPageContainer = function MyTicketsPageContainer() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var Styles = (0, _myTicketsPageStyles.default)();
  var tickets = (0, _reactRedux.useSelector)(_selectors3.selectTicketsForEvent);
  var boughtAddons = (0, _reactRedux.useSelector)(_selectors2.selectAddonsList);
  var currency = (0, _reactRedux.useSelector)(_selectors.getCurrency);
  var event = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var eventSlug = (0, _reactRedux.useSelector)(_selectors.selectEventSlug);
  var eventTickets = (0, _reactRedux.useSelector)(_selectors4.validTicketsSelector);
  var assignedTickets = (0, _reactRedux.useSelector)((0, _selectors3.selectAssignedTickets)(eventTickets));
  var unnasignedTickets = (0, _reactRedux.useSelector)((0, _selectors3.selectUnnaAssignedTickets)(eventTickets));

  var renderAddons = function renderAddons(addonsAttachedToTicket) {
    return addonsAttachedToTicket.map(function (ticketAddon) {
      return boughtAddons.map(function (addon) {
        return ticketAddon.id === addon.id ? _react.default.createElement(_Spacing.default, {
          mSpacing: [0, 0, 's', 0],
          direction: "column",
          alignItems: "flex-start",
          key: addon.name
        }, _react.default.createElement(_Typography.default, {
          variant: "h4"
        }, addon.name), Object.values(ticketAddon.variations).map(function (ticketVariation) {
          return Object.values(addon.variations).map(function (boughtVariation) {
            if (ticketVariation.id === boughtVariation.id) {
              return _react.default.createElement(_Typography.default, {
                key: boughtVariation.name
              }, ' ', "- ", boughtVariation.name);
            }
          });
        })) : null;
      });
    });
  };

  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    pSpacing: ['xl', 'xxl'],
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    style: Styles.container
  }, _react.default.createElement(_Typography.default, {
    variant: "h2"
  }, _i18n.default.t('checkout.myTickets')), tickets.map(function (ticket) {
    var _ticket$rights, _ticket$rights2;

    return _react.default.createElement(_Spacing.default, {
      key: ticket.id,
      direction: "column",
      alignItems: "stretch",
      mSpacing: ['m', 0, 'xl', 0]
    }, _react.default.createElement(_Section.default, {
      color: "containerBackground",
      shaped: true,
      shaping: "forms",
      style: [Styles.border, {
        maxWidth: 800
      }]
    }, _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      direction: "column",
      mSpacing: ['s', 0],
      key: ticket.id
    }, _react.default.createElement(_ticketCardView.default, (0, _extends2.default)({}, ticket, {
      currency: currency,
      timezone: event.timezone
    })), ((_ticket$rights = ticket.rights) === null || _ticket$rights === void 0 ? void 0 : _ticket$rights.selectedAddons) && _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      direction: "column",
      pSpacing: "l",
      key: "addons",
      style: Styles.addonContainer
    }, boughtAddons && boughtAddons.length ? renderAddons((_ticket$rights2 = ticket.rights) === null || _ticket$rights2 === void 0 ? void 0 : _ticket$rights2.selectedAddons) : null)))));
  }), _react.default.createElement(_Typography.default, {
    variant: "h2"
  }, _i18n.default.t('checkout.otherTickets')), _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 0, 'xl', 0],
    style: {
      maxWidth: 800
    },
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Section.default, {
    shaped: true,
    style: Styles.border
  }, _react.default.createElement(_checkoutContainer.default, {
    useModalCheckout: event.useModalCheckout,
    callbackUrl: "https://".concat(window.location.host, "/").concat(eventSlug, "/tickets")
  }))), assignedTickets.length >= 1 && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Typography.default, {
    variant: "h2"
  }, _i18n.default.t('assignTickets.assignedTickets')), _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 0, 'xl', 0],
    style: {
      maxWidth: 800
    },
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Section.default, {
    shaped: true,
    style: Styles.border
  }, _react.default.createElement(_assignedTicketsListView.default, {
    assignedTickets: assignedTickets
  })))), unnasignedTickets.length >= 1 && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Typography.default, {
    variant: "h2"
  }, _i18n.default.t('assignTickets.unassignedTickets')), _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 0, 'xl', 0],
    style: {
      maxWidth: 800
    },
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Section.default, {
    shaped: true,
    style: Styles.border
  }, _react.default.createElement(_unassignedTicketsListView.default, {
    unassignedTickets: unnasignedTickets
  }))))), _react.default.createElement(_footer.default, null));
};

var _default = MyTicketsPageContainer;
exports.default = _default;