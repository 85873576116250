var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _asyncActionHandler = _interopRequireDefault(require("../../utils/redux/async-action-handler"));

var _actions = require("../session/actions");

var _actions2 = require("../tickets/actions");

var _actions3 = require("./actions");

function createMiddleware() {
  return function (store) {
    var asyncActionHandler = new _asyncActionHandler.default(function () {
      store.dispatch((0, _actions3.getRecordedVideos)());
    }).waitOnce({
      type: _actions.getSessions.fulfilled
    }).waitOnce({
      type: _actions2.getTicketRights.fulfilled
    });
    return function (next) {
      return function (action) {
        var result = next(action);
        asyncActionHandler.next(action);
        return result;
      };
    };
  };
}