var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _resetPasswordContainer.default;
  }
});

var _resetPasswordContainer = _interopRequireDefault(require("./reset-password-container"));