var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _benefits = _interopRequireDefault(require("../../core/benefits"));

var _checklist = _interopRequireDefault(require("../../core/checklist"));

var _checkout = _interopRequireDefault(require("../../core/checkout"));

var _eventInfo = _interopRequireDefault(require("../../core/event-info"));

var _landingPageSections = _interopRequireDefault(require("../../core/landing-page-sections"));

var _letter = _interopRequireDefault(require("../../core/letter"));

var _partners = _interopRequireDefault(require("../../core/partners"));

var _session = _interopRequireDefault(require("../../core/session"));

var _speakers = _interopRequireDefault(require("../../core/speakers"));

var _testimonials = _interopRequireDefault(require("../../core/testimonials"));

var _user = _interopRequireDefault(require("../../core/user"));

var _landingScreenContainer = _interopRequireDefault(require("./landing-screen-container"));

function mapStateToProps(state) {
  return {
    isLoading: _eventInfo.default.selectors.selectEventInfoLoading(state),
    eventInfo: _eventInfo.default.selectors.selectEventInfo(state),
    isLoggedIn: _user.default.selectors.isFirebaseUserLoggedIn(state),
    userName: _user.default.selectors.getDisplayName(state),
    sessionsLength: _session.default.selectors.selectSessionsLength(state),
    speakersLength: _speakers.default.selectors.selectSpeakersLength(state),
    partnersLength: _partners.default.selectors.selectPartnersLength(state),
    testimonialsLength: _testimonials.default.selectors.selectTestimonialLength(state),
    benefitsLength: _benefits.default.selectors.selectBenefitsLength(state),
    checklistLength: _checklist.default.selectors.selectChecklistLength(state),
    lettersLength: _letter.default.selectors.selectLettersLength(state),
    sectionSorting: _landingPageSections.default.selectors.selectSections(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  removePreviousCheckoutIntent: _checkout.default.actions.removeCheckoutIntent
})(_landingScreenContainer.default);

exports.default = _default;