var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasLiveIndicator;

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

function CanvasLiveIndicator(_ref) {
  var _ref$current;

  var children = _ref.children,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 12 : _ref$y,
      _ref$x = _ref.x,
      x = _ref$x === void 0 ? 12 : _ref$x;

  var ref = _react.default.useRef(null);

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactKonva.Label, {
    ref: ref,
    x: x,
    y: y
  }, _react.default.createElement(_reactKonva.Tag, {
    fill: "red",
    shadowColor: 'red',
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowOpacity: 0.3
  }), _react.default.createElement(_reactKonva.Text, {
    padding: 4,
    height: 20,
    fontSize: 16,
    fill: 'white',
    text: '• LIVE',
    align: "center"
  })), children && children(((_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.width()) + x));
}