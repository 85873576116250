var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/tickets/selectors");

var _Redirect = _interopRequireDefault(require("../Redirect"));

var _routesConsts = require("../routes-consts");

var _absoluteSpinner = _interopRequireDefault(require("../../../components/common/absolute-spinner"));

var _selectors2 = require("../../../core/team/selectors");

var _selectors3 = require("../../../core/user-roles/selectors");

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var HasTicketGuard = function HasTicketGuard(_ref) {
  var children = _ref.children;
  var hasTickets = (0, _reactRedux.useSelector)(_selectors.userHasTicketsForEvent);
  var staffMember = (0, _reactRedux.useSelector)(_selectors2.isStaffMember);
  var hasAccessByRole = (0, _reactRedux.useSelector)(_selectors3.hasUserAccessByRolesSelector);
  var isTeamMembersLoading = (0, _reactRedux.useSelector)(_selectors2.selectTeamsLoading);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectTicketsIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.selectTicketsIsLoaded);
  var isRolesLoading = (0, _reactRedux.useSelector)(_selectors3.userRolesLoadingSelector);
  if (isLoading && !isLoaded || isTeamMembersLoading || isRolesLoading) return _react.default.createElement(_absoluteSpinner.default, null);
  if (!hasTickets && !staffMember && !hasAccessByRole) return children;

  _logger.default.debug('[HasTicketGuard] Redirect to LOBBY!');

  return _react.default.createElement(_Redirect.default, {
    to: _routesConsts.Routes.LOBBY
  });
};

var _default = HasTicketGuard;
exports.default = _default;