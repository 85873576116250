var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var InitialState = _immutable.default.fromJS({
  updateNotification: {}
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.CHECKING_FOR_UPDATES:
      newState = newState.setIn(['updateNotification', 'inProgress'], true);
      break;

    case _actionTypes.CHECKING_FOR_UPDATES_COMPLETED:
      newState = newState.setIn(['updateNotification', 'inProgress'], false);
      newState = newState.setIn(['updateNotification', 'body'], _immutable.default.fromJS(action.notification));
      newState = newState.setIn(['updateNotification', 'lastCheckTime'], Date.now());
      break;

    case _actionTypes.SNOOZE_DEPRECATION_WARNING:
      newState = newState.setIn(['updateNotification', 'snoozeUntil'], Date.now() + action.durationInMinutes * 60 * 1000);
      break;

    default:
      break;
  }

  return newState;
}