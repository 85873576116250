Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REMOVE_LOGIN_MAIL_MESSAGE = exports.REMOVE_RESET_PASSWORD_ERROR = exports.REMOVE_LOGIN_ERROR = exports.CONFIRM_RESET_PASSWORD = exports.VERIFY_RESET_PASSWORD = exports.RESET_PASSWORD = exports.SET_REGISTRATION_PROGRESS = exports.EVENT_REGISTRATION = exports.REGISTRATION = exports.NAME = void 0;
var NAME = 'auth';
exports.NAME = NAME;
var REGISTRATION = "".concat(NAME, "/REGISTRATION");
exports.REGISTRATION = REGISTRATION;
var EVENT_REGISTRATION = "".concat(NAME, "/EVENT_REGISTRATION");
exports.EVENT_REGISTRATION = EVENT_REGISTRATION;
var SET_REGISTRATION_PROGRESS = "".concat(NAME, "/SET_REGISTRATION_PROGRESS");
exports.SET_REGISTRATION_PROGRESS = SET_REGISTRATION_PROGRESS;
var RESET_PASSWORD = "".concat(NAME, "/RESET_PASSWORD");
exports.RESET_PASSWORD = RESET_PASSWORD;
var VERIFY_RESET_PASSWORD = "".concat(NAME, "/VERIFY_RESET_PASSWORD");
exports.VERIFY_RESET_PASSWORD = VERIFY_RESET_PASSWORD;
var CONFIRM_RESET_PASSWORD = "".concat(NAME, "/CONFIRM_RESET_PASSWORD");
exports.CONFIRM_RESET_PASSWORD = CONFIRM_RESET_PASSWORD;
var REMOVE_LOGIN_ERROR = "".concat(NAME, "/REMOVE_LOGIN_ERROR");
exports.REMOVE_LOGIN_ERROR = REMOVE_LOGIN_ERROR;
var REMOVE_RESET_PASSWORD_ERROR = "".concat(NAME, "/REMOVE_RESET_PASSWORD_ERROR");
exports.REMOVE_RESET_PASSWORD_ERROR = REMOVE_RESET_PASSWORD_ERROR;
var REMOVE_LOGIN_MAIL_MESSAGE = "".concat(NAME, "/REMOVE_LOGIN_MAIL_MESSAGE");
exports.REMOVE_LOGIN_MAIL_MESSAGE = REMOVE_LOGIN_MAIL_MESSAGE;