var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _eventInfo = _interopRequireDefault(require("../../../core/event-info"));

var _session = _interopRequireDefault(require("../../../core/session"));

var _user = _interopRequireDefault(require("../../../core/user"));

var _actions = require("../../../core/user-profile/actions");

var _lobbyPageContainer = _interopRequireDefault(require("./lobby-page-container"));

var _userProfile = _interopRequireDefault(require("../../../core/user-profile"));

var _selectors = require("../../../core/user-profile/selectors");

function mapStateToProps(state) {
  return {
    isLoading: _eventInfo.default.selectors.selectEventInfoLoading(state),
    eventInfo: _eventInfo.default.selectors.selectEventInfo(state),
    isLoggedIn: _user.default.selectors.isFirebaseUserLoggedIn(state),
    userName: (0, _selectors.selectUserDisplayName)(state),
    firstSession: _session.default.selectors.selectFirstSession(state),
    profile: _userProfile.default.selectors.selectUserProfile(state)
  };
}

var _default = (0, _reactRedux.connect)(mapStateToProps, {
  onDisplayNameUpdate: _actions.updateUserProfile
})(_lobbyPageContainer.default);

exports.default = _default;