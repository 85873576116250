var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDjsMusicGenre = exports.getWebsiteUrl = exports.getResidentAdvisorUrl = exports.getBeatPortUrl = exports.getInstagramUrl = exports.getMixCloudUrl = exports.getSoundcloudUrl = exports.getFacebookUrl = exports.getTwitterUrl = exports.getNumFollowing = exports.getNumFollowers = exports.getUserPlayerId = exports.getUserPicture = exports.getHashtags = exports.isDj = exports.getBio = exports.getUserName = exports.getFollowing = exports.getFollowers = exports.hasLoaded = exports.getUserPriority = exports.hasDjLoaded = exports.getDjs = void 0;

var _constants = require("./constants");

var _appraisal = _interopRequireDefault(require("../appraisal"));

var _user = _interopRequireDefault(require("../user"));

var _lodash = _interopRequireDefault(require("lodash"));

var getDjs = function getDjs(state) {
  var djs = state[_constants.NAME].filter(function (dj) {
    return dj.getIn(['profile', 'dj']) === true && !_user.default.selectors.checkIfBlockedContent(state, dj.getIn(['profile', 'id']).toString());
  });

  return _lodash.default.sortBy(djs.toArray(), function (dj) {
    return getUserPriority(state, dj.getIn(['profile', 'id']));
  }).reverse().slice(0, 10);
};

exports.getDjs = getDjs;

var hasDjLoaded = function hasDjLoaded(state) {
  return state[_constants.NAME].size > 0;
};

exports.hasDjLoaded = hasDjLoaded;

var getUserPriority = function getUserPriority(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'priority']);
};

exports.getUserPriority = getUserPriority;

var hasLoaded = function hasLoaded(state, userId) {
  return state[_constants.NAME].getIn(['' + userId]);
};

exports.hasLoaded = hasLoaded;

var getFollowers = function getFollowers(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'followers']);
};

exports.getFollowers = getFollowers;

var getFollowing = function getFollowing(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'following']);
};

exports.getFollowing = getFollowing;

var getUserName = function getUserName(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'displayName']);
};

exports.getUserName = getUserName;

var getBio = function getBio(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'bio']);
};

exports.getBio = getBio;

var isDj = function isDj(state, userId) {
  return userId ? state[_constants.NAME].getIn([userId.toString(), 'profile', 'dj']) : false;
};

exports.isDj = isDj;

var getHashtags = function getHashtags(state, userId) {
  return state[_constants.NAME].getIn([userId.toString(), 'profile', 'hashTags']);
};

exports.getHashtags = getHashtags;

var getUserPicture = function getUserPicture(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'image', 'contentUrl']);
};

exports.getUserPicture = getUserPicture;

var getUserPlayerId = function getUserPlayerId(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'playerId']);
};

exports.getUserPlayerId = getUserPlayerId;

var getNumFollowers = function getNumFollowers(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'numFollowers'], 0);
};

exports.getNumFollowers = getNumFollowers;

var getNumFollowing = function getNumFollowing(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'numFollowing'], 0);
};

exports.getNumFollowing = getNumFollowing;

var getTwitterUrl = function getTwitterUrl(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'twitterProfileLink']);
};

exports.getTwitterUrl = getTwitterUrl;

var getFacebookUrl = function getFacebookUrl(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'facebookProfileLink']);
};

exports.getFacebookUrl = getFacebookUrl;

var getSoundcloudUrl = function getSoundcloudUrl(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'soundcloudProfileLink']);
};

exports.getSoundcloudUrl = getSoundcloudUrl;

var getMixCloudUrl = function getMixCloudUrl(state, userId) {
  return state[_constants.NAME].getIn(['' + userId, 'profile', 'mixcloudProfileLink']);
};

exports.getMixCloudUrl = getMixCloudUrl;

var getInstagramUrl = function getInstagramUrl(state, userId) {
  return state[_constants.NAME].getIn([userId.toString(), 'profile', 'instagramProfileLink']);
};

exports.getInstagramUrl = getInstagramUrl;

var getBeatPortUrl = function getBeatPortUrl(state, userId) {
  return state[_constants.NAME].getIn([userId.toString(), 'profile', 'beatPortProfileLink']);
};

exports.getBeatPortUrl = getBeatPortUrl;

var getResidentAdvisorUrl = function getResidentAdvisorUrl(state, userId) {
  return state[_constants.NAME].getIn([userId.toString(), 'profile', 'residentAdvisorProfileLink']);
};

exports.getResidentAdvisorUrl = getResidentAdvisorUrl;

var getWebsiteUrl = function getWebsiteUrl(state, userId) {
  return state[_constants.NAME].getIn([userId.toString(), 'profile', 'websiteUrl']);
};

exports.getWebsiteUrl = getWebsiteUrl;

var getDjsMusicGenre = function getDjsMusicGenre(state, djId, hashtags) {
  var djs = state[_constants.NAME].filter(function (dj) {
    var currentDjId = dj.getIn(['profile', 'id']);
    var djHashtags = dj.getIn(['profile', 'hashTags']);
    var foundSimilarMusicType = false;

    if (djHashtags) {
      djHashtags.forEach(function (type) {
        if (hashtags) {
          if (hashtags.indexOf(type) > -1) {
            foundSimilarMusicType = true;
          }
        }
      });
    }

    return _appraisal.default.selectors.isFollowing(state, currentDjId) === false && foundSimilarMusicType === true && currentDjId !== djId;
  });

  return _lodash.default.sortBy(djs.toArray(), function (dj) {
    return dj.get('numFollowers');
  }).reverse().slice(0, 10);
};

exports.getDjsMusicGenre = getDjsMusicGenre;