var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _dateFormatter = require("../../utils/date-formatter");

var _Comment = _interopRequireDefault(require("./Comment"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

var _usePrevious = _interopRequireDefault(require("../../utils/hooks/usePrevious"));

var _UnreadCounter = _interopRequireDefault(require("./UnreadCounter"));

var _roleConstants = require("../../constants/role-constants");

var THRESHOLD = 20;

function CommentList(_ref) {
  var messages = _ref.messages,
      _onDelete = _ref.onDelete,
      _onBlock = _ref.onBlock,
      role = _ref.role,
      scrollToBottom = _ref.scrollToBottom;

  var ref = _react.default.useRef();

  var _React$useState = _react.default.useState(true),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      userDidScroll = _React$useState2[0],
      setUserDidScroll = _React$useState2[1];

  var prevScrollToBottom = (0, _usePrevious.default)(scrollToBottom);
  var hasAccess = _roleConstants.USER_ROLES_HIERARCHY[role] > _roleConstants.USER_ROLES_HIERARCHY.STAGE_MANAGER;

  _react.default.useEffect(function () {
    if (ref.current) {
      ref.current.scrollToEnd({
        animated: false
      });
      setUserDidScroll(false);
    }
  }, [ref.current, setUserDidScroll]);

  _react.default.useEffect(function () {
    if (ref.current && prevScrollToBottom !== scrollToBottom) {
      ref.current.scrollToEnd();
    }
  }, [scrollToBottom]);

  _react.default.useEffect(function () {
    if (ref.current && !userDidScroll) {
      ref.current.scrollToEnd({
        animated: false
      });
    }
  }, [messages, userDidScroll]);

  var scrollBottom = _react.default.useCallback(function () {
    if (ref.current) {
      ref.current.scrollToEnd();
      setUserDidScroll(false);
    }
  });

  return _react.default.createElement(_Spacing.default, {
    style: {
      position: 'relative'
    },
    flex: 1,
    pSpacing: "xs",
    direction: "column",
    justifyContent: "flex-end",
    alignItems: "stretch"
  }, _react.default.createElement(_reactNative.ScrollView, {
    showsVerticalScrollIndicator: false,
    onScrollBeginDrag: function onScrollBeginDrag() {
      setUserDidScroll(true);
    },
    onScrollEndDrag: function onScrollEndDrag() {
      setUserDidScroll(false);
    },
    scrollEventThrottle: 500,
    onScroll: function onScroll(_ref2) {
      var _ref2$nativeEvent = _ref2.nativeEvent,
          layoutHeight = _ref2$nativeEvent.layoutMeasurement.height,
          y = _ref2$nativeEvent.contentOffset.y,
          contentHeight = _ref2$nativeEvent.contentSize.height;

      if (layoutHeight + y > contentHeight - THRESHOLD) {
        setUserDidScroll(false);
      } else if (!userDidScroll) {
        setUserDidScroll(true);
      }
    },
    contentContainerStyle: {
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      minWidth: '100%'
    },
    ref: ref
  }, messages.map(function (message) {
    return _react.default.createElement(_Spacing.default, {
      direction: "column",
      style: {
        width: '100%'
      },
      alignItems: "stretch",
      key: message.sig
    }, _react.default.createElement(_Comment.default, {
      elapsedTime: (0, _dateFormatter.getElapsedTime)(message.iat),
      message: message.msg,
      userName: message.fnm,
      userId: message.usr,
      loading: message.loading ? _i18n.default.translate('chat.sending') : message.deleting ? _i18n.default.translate('chat.deleting') : null,
      failed: message.failed,
      role: _roleConstants.USER_ROLES_HIERARCHY[message.role] > _roleConstants.USER_ROLES_HIERARCHY.AUDIENCE ? message.role : null,
      hasAccess: hasAccess,
      onDelete: function onDelete() {
        return _onDelete(message);
      },
      avatar: message.avt,
      isBlocked: message.isBlocked,
      onBlock: function onBlock() {
        return _onBlock({
          id: message.usr,
          usr: message.usr,
          fnm: message.fnm,
          avt: message.avt,
          version: 'firebase'
        });
      }
    }));
  })), _react.default.createElement(_Spacing.default, {
    style: {
      position: 'absolute',
      bottom: -5,
      left: 0,
      right: 0
    },
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_UnreadCounter.default, {
    onScroll: scrollBottom,
    userDidScroll: userDidScroll
  })));
}

var _default = _react.default.memo(CommentList);

exports.default = _default;