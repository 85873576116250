var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNativeGestureHandler = require("react-native-gesture-handler");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _liveNowPageStyles = _interopRequireDefault(require("./live-now-page-styles"));

var _liveNowList = _interopRequireDefault(require("./live-now-list"));

var LiveNowPage = function LiveNowPage() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      counter = _React$useState2[0],
      setCounter = _React$useState2[1];

  var Styles = (0, _liveNowPageStyles.default)();

  _react.default.useEffect(function () {
    var now = new Date();
    var timer = setInterval(function () {
      return setCounter(function (oldState) {
        return !oldState;
      });
    }, 1000 * (60 - now.getSeconds()));
    return function () {
      return clearInterval(timer);
    };
  }, [counter]);

  return _react.default.createElement(_reactNativeGestureHandler.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    pSpacing: ['xl', 'xxl'],
    style: Styles.container
  }, _react.default.createElement(_liveNowList.default, null)), _react.default.createElement(_footer.default, null));
};

var _default = LiveNowPage;
exports.default = _default;