var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _asyncUtils = require("../async-utils");

var _useClientState = require("./useClientState");

var _useSafeState5 = _interopRequireDefault(require("./useSafeState"));

function useTrackControl(track, client) {
  var _useSafeState = (0, _useSafeState5.default)(_react.default.useState(false)),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      published = _useSafeState2[0],
      setPublished = _useSafeState2[1];

  var _useSafeState3 = (0, _useSafeState5.default)(_react.default.useState(true)),
      _useSafeState4 = (0, _slicedToArray2.default)(_useSafeState3, 2),
      enabled = _useSafeState4[0],
      setEnabled = _useSafeState4[1];

  var clientIsConnected = (0, _useClientState.useClientIsConnected)(client);

  var enableTrack = _react.default.useCallback((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            if (!(!track || !client)) {
              _context.next = 2;
              break;
            }

            return _context.abrupt("return");

          case 2:
            _context.next = 4;
            return track.setEnabled(true);

          case 4:
            if (!(clientIsConnected && !published)) {
              _context.next = 10;
              break;
            }

            _context.next = 7;
            return client.publish(track);

          case 7:
            setPublished(true);
            _context.next = 12;
            break;

          case 10:
            _context.next = 12;
            return (0, _asyncUtils.wait)(500);

          case 12:
            setEnabled(true);

          case 13:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  })), [setEnabled, client, setPublished, clientIsConnected, track]);

  var disableTrack = _react.default.useCallback((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
    return _regenerator.default.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            if (track) {
              _context2.next = 2;
              break;
            }

            return _context2.abrupt("return");

          case 2:
            _context2.next = 4;
            return track.setEnabled(false);

          case 4:
            _context2.next = 6;
            return (0, _asyncUtils.wait)(500);

          case 6:
            setEnabled(false);

          case 7:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  })), [setEnabled, track]);

  var toggleTrack = _react.default.useCallback((0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3() {
    return _regenerator.default.wrap(function _callee3$(_context3) {
      while (1) {
        switch (_context3.prev = _context3.next) {
          case 0:
            if (!enabled) {
              _context3.next = 5;
              break;
            }

            _context3.next = 3;
            return disableTrack();

          case 3:
            _context3.next = 7;
            break;

          case 5:
            _context3.next = 7;
            return enableTrack();

          case 7:
          case "end":
            return _context3.stop();
        }
      }
    }, _callee3);
  })), [enableTrack, disableTrack, enabled]);

  return {
    toggleTrack: toggleTrack,
    enableTrack: enableTrack,
    disableTrack: disableTrack,
    enabled: enabled,
    published: published,
    onPublish: function onPublish(publish) {
      return setPublished(publish);
    }
  };
}

var _default = useTrackControl;
exports.default = _default;