var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAccessTicketCode = exports.getAccessTicketID = exports.getAccessTicket = exports.hasAccessTicket = exports.getEventHashtags = exports.getFestivalName = exports.getFestivalId = exports.getNoAdsTicketID = exports.getStartDate = exports.getAdUrl = exports.getFreeWatchTime = exports.getLiveUrl = exports.getCoverImage = exports.getDjPlayerId = exports.getDjPicture = exports.getDjName = exports.getDjId = exports.getLiveId = exports.hasStarted = exports.isReplay = exports.getUsers = exports.getNumTotalUsers = exports.getNumUsers = exports.isConnected = void 0;

var _constants = require("./constants");

var _events = _interopRequireDefault(require("../events"));

var _profile = _interopRequireDefault(require("../profile"));

var isConnected = function isConnected(state, liveId) {
  return liveId === getLiveId(state) && state[_constants.NAME].get('readyState', _constants.CONNECTION_STATUS.OPEN) === _constants.CONNECTION_STATUS.CONNECTED;
};

exports.isConnected = isConnected;

var getNumUsers = function getNumUsers(state) {
  return state[_constants.NAME].get('numUsers', 0);
};

exports.getNumUsers = getNumUsers;

var getNumTotalUsers = function getNumTotalUsers(state) {
  return state[_constants.NAME].get('numTotalUsers', 0);
};

exports.getNumTotalUsers = getNumTotalUsers;

var getUsers = function getUsers(state) {
  return state[_constants.NAME].get('users');
};

exports.getUsers = getUsers;

var isReplay = function isReplay(state) {
  return _events.default.selectors.isReplay(state, getLiveId(state));
};

exports.isReplay = isReplay;

var hasStarted = function hasStarted(state) {
  return _events.default.selectors.hasStarted(state, getLiveId(state));
};

exports.hasStarted = hasStarted;

var getLiveId = function getLiveId(state) {
  return state[_constants.NAME].get('liveId');
};

exports.getLiveId = getLiveId;

var getDjId = function getDjId(state) {
  return _events.default.selectors.getDjId(state, getLiveId(state));
};

exports.getDjId = getDjId;

var getDjName = function getDjName(state) {
  return _profile.default.selectors.getUserName(state, getDjId(state));
};

exports.getDjName = getDjName;

var getDjPicture = function getDjPicture(state) {
  return _profile.default.selectors.getUserPicture(state, getDjId(state));
};

exports.getDjPicture = getDjPicture;

var getDjPlayerId = function getDjPlayerId(state) {
  return _profile.default.selectors.getUserPlayerId(state, getDjId(state));
};

exports.getDjPlayerId = getDjPlayerId;

var getCoverImage = function getCoverImage(state) {
  return _events.default.selectors.getCoverImage(state, getLiveId(state));
};

exports.getCoverImage = getCoverImage;

var getLiveUrl = function getLiveUrl(state) {
  return _events.default.selectors.getLiveUrl(state, getLiveId(state));
};

exports.getLiveUrl = getLiveUrl;

var getFreeWatchTime = function getFreeWatchTime(state) {
  return _events.default.selectors.getFreeWatchTime(state, getLiveId(state));
};

exports.getFreeWatchTime = getFreeWatchTime;

var getAdUrl = function getAdUrl(state) {
  return _events.default.selectors.getAdUrl(state, getLiveId(state));
};

exports.getAdUrl = getAdUrl;

var getStartDate = function getStartDate(state) {
  return _events.default.selectors.getStartDate(state, getLiveId(state));
};

exports.getStartDate = getStartDate;

var getNoAdsTicketID = function getNoAdsTicketID(state) {
  return _events.default.selectors.getNoAdsTicketID(state, getLiveId(state));
};

exports.getNoAdsTicketID = getNoAdsTicketID;

var getFestivalId = function getFestivalId(state) {
  return _events.default.selectors.getFestivalId(state, getLiveId(state));
};

exports.getFestivalId = getFestivalId;

var getFestivalName = function getFestivalName(state) {
  return _events.default.selectors.getFestivalName(state, getLiveId(state));
};

exports.getFestivalName = getFestivalName;

var getEventHashtags = function getEventHashtags(state) {
  return _events.default.selectors.getEventHashtags(state, getLiveId(state));
};

exports.getEventHashtags = getEventHashtags;

var hasAccessTicket = function hasAccessTicket(state) {
  var FREE_TICKET_CODE = 'FREE';
  return getAccessTicketCode(state) !== FREE_TICKET_CODE && getAccessTicket(state) != null;
};

exports.hasAccessTicket = hasAccessTicket;

var getAccessTicket = function getAccessTicket(state) {
  return _events.default.selectors.getAccessTicket(state, getLiveId(state));
};

exports.getAccessTicket = getAccessTicket;

var getAccessTicketID = function getAccessTicketID(state) {
  return _events.default.selectors.getAccessTicketID(state, getLiveId(state));
};

exports.getAccessTicketID = getAccessTicketID;

var getAccessTicketCode = function getAccessTicketCode(state) {
  return _events.default.selectors.getAccessTicketCode(state, getLiveId(state));
};

exports.getAccessTicketCode = getAccessTicketCode;