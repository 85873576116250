Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadDataCompleted = loadDataCompleted;
exports.loadReferrals = loadReferrals;
exports.loadReferralsCompleted = loadReferralsCompleted;

var _actionTypes = require("./actionTypes");

function loadDataCompleted(data) {
  return {
    data: data,
    type: _actionTypes.LOAD_DATA_COMPLETED
  };
}

function loadReferrals(userId) {
  return function (dispatch, getState, _ref) {
    var api = _ref.api;
    return api.get({
      action: "/soundmix_users/".concat(userId, "/referrals")
    }).then(function (response) {
      dispatch(loadReferralsCompleted(response.data));
    }).catch(function () {});
  };
}

function loadReferralsCompleted(data) {
  return {
    data: {
      referralsList: data
    },
    type: _actionTypes.LOAD_REFERRALS_LIST_COMPLETED
  };
}