var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LeaveButton;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _ColoredIconButton = _interopRequireDefault(require("../../../theme/components/buttons/ColoredIconButton"));

var _AudioVideoProvider = require("./AudioVideoProvider");

var _reactFeather = require("react-feather");

var _ContainedButton = _interopRequireDefault(require("../../../theme/components/buttons/ContainedButton"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _audioVideoStyles = _interopRequireDefault(require("./audio-video-styles"));

function LeaveButton(_ref) {
  var color = _ref.color;
  var Styles = (0, _audioVideoStyles.default)();

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      leaveAndRemoveTracks = _useAudioVideoContext.leaveAndRemoveTracks;

  var theme = (0, _core.useTheme)();
  var match = (0, _core.useMediaQuery)(theme.breakpoints.down('sm'));

  var _useNavigationExtende = (0, _useNavigationExtended.default)(),
      navigate = _useNavigationExtende.navigate;

  var onClick = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return leaveAndRemoveTracks();

            case 2:
              navigate(_routesConsts.Routes.NETWORKING_PAGE);

            case 3:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function onClick() {
      return _ref2.apply(this, arguments);
    };
  }();

  if (match) return _react.default.createElement(_ColoredIconButton.default, {
    customColor: color,
    onClick: onClick
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.buttonSize
  }, _react.default.createElement(_reactFeather.LogOut, null)));
  return _react.default.createElement(_ContainedButton.default, {
    onPress: onClick
  }, _i18n.default.translate('networking.leaveTable'));
}