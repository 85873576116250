var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CalendarButtonView;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _moment = _interopRequireDefault(require("moment"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _calendarButtonStyles = _interopRequireDefault(require("./calendar-button-styles"));

var _DesignSystem = require("../../../theme/DesignSystem");

var _dayNames = _interopRequireDefault(require("../../../utils/day-names"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

var _clsx = _interopRequireDefault(require("clsx"));

function CalendarButtonView(_ref) {
  var daySelected = _ref.daySelected,
      day = _ref.day,
      selected = _ref.selected;
  var Styles = (0, _calendarButtonStyles.default)();
  var classes = (0, _styles.default)();
  var dayName = (0, _moment.default)(day);
  var dayNumber = day.getDate();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      hover = _React$useState2[0],
      setHover = _React$useState2[1];

  return _react.default.createElement(_reactNative.TouchableHighlight, (0, _extends2.default)({
    underlayColor: colors.buttonHover
  }, _reactNative.Platform.select({
    web: {
      onMouseOver: function onMouseOver() {
        return setHover(true);
      },
      onMouseOut: function onMouseOut() {
        return setHover(false);
      }
    }
  }), {
    style: [Styles.buttonContainer, selected ? {
      backgroundColor: colors.button
    } : {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: "".concat(colors.pageText, "55"),
      backgroundColor: hover ? "".concat(colors.buttonHover, "55") : 'transparent'
    }],
    onPress: function onPress() {
      return daySelected(day);
    }
  }), _react.default.createElement(_reactNative.View, {
    style: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "column"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'xs', 0]
  }, _react.default.createElement(_core.Typography, {
    className: (0, _clsx.default)(classes.calendarButtonText, selected ? classes.selectedColor : classes.defaltColor),
    variant: "overline"
  }, "".concat(_i18n.default.translate(_dayNames.default[dayName.day()]).substring(0, 3)))), _react.default.createElement(_core.Typography, {
    variant: "h4",
    className: (0, _clsx.default)(classes.textBold, selected ? classes.selectedColor : classes.defaltColor)
  }, dayNumber))));
}