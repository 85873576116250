var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = TicketContainer;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

function TicketContainer(_ref) {
  var _ref$tickets = _ref.tickets,
      tickets = _ref$tickets === void 0 ? [] : _ref$tickets,
      children = _ref.children;

  var _React$useState = _react.default.useState(null),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      selectedTicket = _React$useState2[0],
      setSelectedTicket = _React$useState2[1];

  var onSelect = function onSelect(ticket) {
    setSelectedTicket(ticket);
  };

  return children({
    tickets: tickets,
    selectedTicket: selectedTicket || tickets.find(function (ticket) {
      return !ticket.disabled;
    }),
    onSelect: onSelect
  });
}