var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Steps;

var _react = _interopRequireDefault(require("react"));

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _core = require("@material-ui/core");

var _stepsStyles = _interopRequireDefault(require("./steps-styles"));

function Steps(_ref) {
  var steps = _ref.steps;
  var navigation = (0, _useNavigationExtended.default)();
  var classes = (0, _stepsStyles.default)();
  return _react.default.createElement(_core.Box, {
    className: classes.stepsContainer
  }, steps.filter(function (step) {
    return step;
  }).map(function (step, index) {
    return _react.default.createElement(_core.Box, {
      className: navigation.state.routeName === step.route ? "".concat(classes.step, " ").concat(classes.activeStep) : classes.step,
      key: index
    }, _react.default.createElement(_core.Box, {
      className: classes.indexContainer
    }, _react.default.createElement(_core.Typography, {
      variant: "h2"
    }, index + 1)), _react.default.createElement(_core.Box, {
      className: classes.titleContainer
    }, _react.default.createElement(_core.Typography, {
      variant: "h2"
    }, step.label)));
  }));
}