Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOTAL_PRIZE = exports.GS_PROPERTY_SET_COMPLETED = exports.IS_GS_PROPERTY_SET_LOADING = exports.GS_PROPERTY_COMPLETED = exports.IS_GS_PROPERTY_LOADING = exports.REFERRAL_BEATS_NEW_DJ = exports.REFERRAL_BEATS = exports.LEADERBOARD_SCREEN_PROPS = exports.MY_BEATS_SCREEN_PROPERTIES = exports.ITEMS_ON_PAGE = exports.TOP_DJ_COUNT = exports.AROUND_ME_ENTRY_COUNT = exports.TOP_DJ_LEADERBOARD = exports.LOAD_AROUND_ME_COMPLETED = exports.LOAD_LEADERBOARD_COMPLETED = exports.IS_LEADERBOARD_LOADING = exports.LOAD_SPECIFIC_PLAYER_COMPLETED = exports.IS_SPECIFIC_PLAYER_LOADING = exports.LOAD_PLAYER_COMPLETED = exports.IS_PLAYER_LOADING = exports.LOAD_LEADERBOARD_ENTRY_COMPLETED = exports.IS_LEADERBOARD_ENTRY_LOADING = exports.GS_ERROR = exports.GS_INIT_COMPLETED = exports.BEATS = exports.NAME = void 0;
var NAME = 'gameSparks';
exports.NAME = NAME;
var BEATS = 'beats';
exports.BEATS = BEATS;
var GS_INIT_COMPLETED = 'gameSparks/GS_INIT_COMPLETED';
exports.GS_INIT_COMPLETED = GS_INIT_COMPLETED;
var GS_ERROR = 'gameSparks/GS_ERROR';
exports.GS_ERROR = GS_ERROR;
var IS_LEADERBOARD_ENTRY_LOADING = 'gameSparks/IS_LEADERBOARD_ENTRY_LOADING';
exports.IS_LEADERBOARD_ENTRY_LOADING = IS_LEADERBOARD_ENTRY_LOADING;
var LOAD_LEADERBOARD_ENTRY_COMPLETED = 'gameSparks/LOAD_LEADERBOARD_ENTRY_COMPLETED';
exports.LOAD_LEADERBOARD_ENTRY_COMPLETED = LOAD_LEADERBOARD_ENTRY_COMPLETED;
var IS_PLAYER_LOADING = 'gameSparks/IS_PLAYER_LOADING';
exports.IS_PLAYER_LOADING = IS_PLAYER_LOADING;
var LOAD_PLAYER_COMPLETED = 'gameSparks/LOAD_PLAYER_COMPLETED';
exports.LOAD_PLAYER_COMPLETED = LOAD_PLAYER_COMPLETED;
var IS_SPECIFIC_PLAYER_LOADING = 'gameSparks/IS_SPECIFIC_PLAYER_LOADING';
exports.IS_SPECIFIC_PLAYER_LOADING = IS_SPECIFIC_PLAYER_LOADING;
var LOAD_SPECIFIC_PLAYER_COMPLETED = 'gameSparks/LOAD_SPECIFIC_PLAYER_COMPLETED';
exports.LOAD_SPECIFIC_PLAYER_COMPLETED = LOAD_SPECIFIC_PLAYER_COMPLETED;
var IS_LEADERBOARD_LOADING = 'gameSparks/IS_LEADERBOARD_LOADING';
exports.IS_LEADERBOARD_LOADING = IS_LEADERBOARD_LOADING;
var LOAD_LEADERBOARD_COMPLETED = 'gameSparks/LOAD_LEADERBOARD_COMPLETED';
exports.LOAD_LEADERBOARD_COMPLETED = LOAD_LEADERBOARD_COMPLETED;
var LOAD_AROUND_ME_COMPLETED = 'gameSparks/LOAD_AROUND_ME_COMPLETED';
exports.LOAD_AROUND_ME_COMPLETED = LOAD_AROUND_ME_COMPLETED;
var TOP_DJ_LEADERBOARD = 'TOP_DJ.MAX-month.';
exports.TOP_DJ_LEADERBOARD = TOP_DJ_LEADERBOARD;
var AROUND_ME_ENTRY_COUNT = 1;
exports.AROUND_ME_ENTRY_COUNT = AROUND_ME_ENTRY_COUNT;
var TOP_DJ_COUNT = 3;
exports.TOP_DJ_COUNT = TOP_DJ_COUNT;
var ITEMS_ON_PAGE = 10;
exports.ITEMS_ON_PAGE = ITEMS_ON_PAGE;
var MY_BEATS_SCREEN_PROPERTIES = 'MY_BEATS_SCREEN_PROPERTIES';
exports.MY_BEATS_SCREEN_PROPERTIES = MY_BEATS_SCREEN_PROPERTIES;
var LEADERBOARD_SCREEN_PROPS = 'LEADERBOARD_SCREEN_PROPS';
exports.LEADERBOARD_SCREEN_PROPS = LEADERBOARD_SCREEN_PROPS;
var REFERRAL_BEATS = 'BEATS_EARNED_FOR_NEW_REFERRAL';
exports.REFERRAL_BEATS = REFERRAL_BEATS;
var REFERRAL_BEATS_NEW_DJ = 'BEATS_EARNED_FOR_NEW_REFERRAL_DJ';
exports.REFERRAL_BEATS_NEW_DJ = REFERRAL_BEATS_NEW_DJ;
var IS_GS_PROPERTY_LOADING = 'gameSparks/IS_GS_PROPERTY_LOADING';
exports.IS_GS_PROPERTY_LOADING = IS_GS_PROPERTY_LOADING;
var GS_PROPERTY_COMPLETED = 'gameSparks/GS_PROPERTY_COMPLETED';
exports.GS_PROPERTY_COMPLETED = GS_PROPERTY_COMPLETED;
var IS_GS_PROPERTY_SET_LOADING = 'gameSparks/IS_GS_PROPERTY_SET_LOADING';
exports.IS_GS_PROPERTY_SET_LOADING = IS_GS_PROPERTY_SET_LOADING;
var GS_PROPERTY_SET_COMPLETED = 'gameSparks/GS_PROPERTY_SET_COMPLETED';
exports.GS_PROPERTY_SET_COMPLETED = GS_PROPERTY_SET_COMPLETED;
var TOTAL_PRIZE = 'LEADERBOARD_TOTAL_PRIZE';
exports.TOTAL_PRIZE = TOTAL_PRIZE;