Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WEBSOCKET_TIP_EVENT = exports.WEBSOCKET_NUMBER_OF_CLAPS_CHANGED_EVENT = exports.WEBSOCKET_CLAP_EVENT = exports.WEBSOCKET_LOST_FOLLOWER_EVENT = exports.WEBSOCKET_NEW_FOLLOWER_EVENT = exports.LOST_FOLLOWER = exports.NEW_FOLLOWER = exports.UNFOLLOW_FESTIVAL_COMPLETED = exports.FOLLOW_FESTIVAL_COMPLETED = exports.UNFOLLOW_COMPLETED = exports.FOLLOW_COMPLETED = exports.NAME = void 0;
var NAME = 'appraisal';
exports.NAME = NAME;
var FOLLOW_COMPLETED = 'appraisal/FOLLOW_COMPLETED';
exports.FOLLOW_COMPLETED = FOLLOW_COMPLETED;
var UNFOLLOW_COMPLETED = 'appraisal/UNFOLLOW_COMPLETED';
exports.UNFOLLOW_COMPLETED = UNFOLLOW_COMPLETED;
var FOLLOW_FESTIVAL_COMPLETED = 'appraisal/FOLLOW_FESTIVAL_COMPLETED';
exports.FOLLOW_FESTIVAL_COMPLETED = FOLLOW_FESTIVAL_COMPLETED;
var UNFOLLOW_FESTIVAL_COMPLETED = 'appraisal/UNFOLLOW_FESTIVAL_COMPLETED';
exports.UNFOLLOW_FESTIVAL_COMPLETED = UNFOLLOW_FESTIVAL_COMPLETED;
var NEW_FOLLOWER = 'appraisal/NEW_FOLLOWER';
exports.NEW_FOLLOWER = NEW_FOLLOWER;
var LOST_FOLLOWER = 'appraisal/LOST_FOLLOWER';
exports.LOST_FOLLOWER = LOST_FOLLOWER;
var WEBSOCKET_NEW_FOLLOWER_EVENT = 'newFollower';
exports.WEBSOCKET_NEW_FOLLOWER_EVENT = WEBSOCKET_NEW_FOLLOWER_EVENT;
var WEBSOCKET_LOST_FOLLOWER_EVENT = 'lostFollower';
exports.WEBSOCKET_LOST_FOLLOWER_EVENT = WEBSOCKET_LOST_FOLLOWER_EVENT;
var WEBSOCKET_CLAP_EVENT = 'chat:clap';
exports.WEBSOCKET_CLAP_EVENT = WEBSOCKET_CLAP_EVENT;
var WEBSOCKET_NUMBER_OF_CLAPS_CHANGED_EVENT = 'numClapsChanged';
exports.WEBSOCKET_NUMBER_OF_CLAPS_CHANGED_EVENT = WEBSOCKET_NUMBER_OF_CLAPS_CHANGED_EVENT;
var WEBSOCKET_TIP_EVENT = 'chat:tip';
exports.WEBSOCKET_TIP_EVENT = WEBSOCKET_TIP_EVENT;