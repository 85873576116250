Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIME_SPENT_IN_LIVE = exports.HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION = exports.SET_AUTO_READ_MODE = exports.READ_NEW_MESSAGES = void 0;
var READ_NEW_MESSAGES = 'activity/READ_NEW_MESSAGES';
exports.READ_NEW_MESSAGES = READ_NEW_MESSAGES;
var SET_AUTO_READ_MODE = 'activity/SET_AUTO_READ_MODE';
exports.SET_AUTO_READ_MODE = SET_AUTO_READ_MODE;
var HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION = 'activity/HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION';
exports.HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION = HAS_SEEN_PUSH_NOTIFICATION_SUGGESTION;
var TIME_SPENT_IN_LIVE = 'activity/TIME_SPENT_IN_LIVE';
exports.TIME_SPENT_IN_LIVE = TIME_SPENT_IN_LIVE;