var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _myFilesPageStyles = _interopRequireDefault(require("./my-files-page-styles"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-files/selectors");

var _selectors2 = require("../../../core/event-info/selectors");

var _actions = require("../../../core/event-files/actions");

var _fileCardView = _interopRequireDefault(require("./file-card/file-card-view"));

var MyFilesPageContainer = function MyFilesPageContainer() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectEventFilesIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.selectEventFilesIsLoaded);
  var eventFiles = (0, _reactRedux.useSelector)(_selectors.selectEventFilesList);
  var isLoadedEvent = (0, _reactRedux.useSelector)(_selectors2.selectEventInfoLoaded);
  var Styles = (0, _myFilesPageStyles.default)();

  _react.default.useEffect(function () {
    if (isLoadedEvent) dispatch((0, _actions.getEventFiles)());
  }, [dispatch, isLoadedEvent]);

  return _react.default.createElement(_react.default.Fragment, null, isLoading && _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 'auto']
  }, _react.default.createElement(_reactNative.ActivityIndicator, {
    size: "large"
  })), isLoaded && eventFiles && _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    pSpacing: ['xl', 'xxl'],
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    style: Styles.container
  }, _react.default.createElement(_core.Typography, {
    variant: "h2"
  }, _i18n.default.t('header.myFiles')), _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    mSpacing: ['m', 0, 'xl', 0]
  }, eventFiles.map(function (file) {
    return _react.default.createElement(_Spacing.default, {
      alignItems: "stretch",
      direction: "column",
      mSpacing: ['s', 0],
      key: file.id
    }, _react.default.createElement(_fileCardView.default, {
      file: file
    }));
  }))), _react.default.createElement(_footer.default, null)));
};

var _default = MyFilesPageContainer;
exports.default = _default;