var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RemoteVideo;

var _react = _interopRequireDefault(require("react"));

var _Video = _interopRequireDefault(require("./Video"));

function RemoteVideo(_ref) {
  var width = _ref.width,
      height = _ref.height,
      _ref$absoluteFill = _ref.absoluteFill,
      absoluteFill = _ref$absoluteFill === void 0 ? false : _ref$absoluteFill,
      _ref$children = _ref.children,
      children = _ref$children === void 0 ? null : _ref$children,
      videoTrack = _ref.videoTrack;
  if (!videoTrack) return _react.default.createElement(_react.default.Fragment, null, children);
  return _react.default.createElement(_Video.default, {
    videoTrack: videoTrack,
    width: width,
    height: height,
    mirror: false,
    absoluteFill: absoluteFill
  });
}