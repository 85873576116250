Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../../theme/DesignSystem");

var useSpeakerStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    image: {
      padding: theme.spacing.l
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    image: {
      padding: theme.spacing.l
    }
  };
}).buildHook();
var _default = useSpeakerStyles;
exports.default = _default;