var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RenderUnassignedAddons = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var RenderUnassignedAddons = function RenderUnassignedAddons(_ref) {
  var ticketAddons = _ref.ticketAddons;
  return ticketAddons.map(function (addon) {
    return _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 's', 0],
      direction: "column",
      alignItems: "flex-start",
      key: addon.name
    }, _react.default.createElement(_core.Typography, {
      variant: "h4"
    }, addon.name), Object.values(addon.variations).map(function (addonVariation) {
      return _react.default.createElement(_core.Typography, {
        key: addonVariation.name
      }, "- ", addonVariation.name);
    }));
  });
};

exports.RenderUnassignedAddons = RenderUnassignedAddons;