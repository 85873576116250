var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("../user/constants");

var _actions = require("./actions");

var _createReducer;

var InitialState = {
  loading: false,
  loaded: false,
  profile: null,
  error: null
};
var userProfileReducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getUserProfile.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.getUserProfile.rejected, function (state, _ref) {
  var error = _ref.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.getUserProfile.fulfilled, function (state, _ref2) {
  var payload = _ref2.payload;
  state.loaded = true;
  state.loading = false;
  state.profile = payload;
}), (0, _defineProperty2.default)(_createReducer, _actions.updateUserProfile.pending, function (state, _) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.updateUserProfile.rejected, function (state, _ref3) {
  var error = _ref3.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.updateUserProfile.fulfilled, function (state, _ref4) {
  var payload = _ref4.payload;
  state.loaded = true;
  state.loading = false;
  state.profile = payload;
}), (0, _defineProperty2.default)(_createReducer, _constants.USER_LOGOUT, function () {
  return InitialState;
}), _createReducer));
var _default = userProfileReducer;
exports.default = _default;