Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    background: {
      backgroundColor: theme.palette.containerBackground.main,
      minHeight: 0
    },
    container: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.containerBackground.main,
      minHeight: 0
    },
    button: {
      width: '100%',
      height: '100%',
      padding: '12px 10px',
      borderRadius: 8,
      color: theme.palette.buttonText.main
    }
  };
});
var _default = styles;
exports.default = _default;