var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _App = require("../../App");

var useHeader = function useHeader(_ref) {
  var visible = _ref.visible,
      withScroll = _ref.withScroll,
      withLeftMenu = _ref.withLeftMenu;

  var _React$useContext = _react.default.useContext(_App.HeaderContext),
      setOptions = _React$useContext.setOptions;

  var isPageFocused = (0, _reactNavigationHooks.useIsFocused)();

  _react.default.useLayoutEffect(function () {
    if (isPageFocused) setOptions({
      visible: visible,
      withScroll: withScroll,
      withLeftMenu: withLeftMenu
    });
  }, [isPageFocused, visible]);
};

var _default = useHeader;
exports.default = _default;