var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _absoluteSpinner = _interopRequireDefault(require("../../../components/common/absolute-spinner"));

var _eventInfo = _interopRequireDefault(require("../../../core/event-info"));

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var _selectors = require("../../../core/team/selectors");

var _selectors2 = require("../../../core/tickets/selectors");

var _selectors3 = require("../../../core/user-roles/selectors");

var _Redirect = _interopRequireDefault(require("../Redirect"));

var _routesConsts = require("../routes-consts");

var TicketGuard = function TicketGuard(_ref) {
  var children = _ref.children;
  var hasTickets = (0, _reactRedux.useSelector)(_selectors2.userHasTicketsForEvent);
  var staffMember = (0, _reactRedux.useSelector)(_selectors.isStaffMember);
  var hasAccessByRole = (0, _reactRedux.useSelector)(_selectors3.hasUserAccessByRolesSelector);
  var isTicketsLoaded = (0, _reactRedux.useSelector)(_selectors2.selectTicketsIsLoaded);
  var isTeamMembersLoaded = (0, _reactRedux.useSelector)(_selectors.selectTeamsLoaded);
  var isRolesLoaded = (0, _reactRedux.useSelector)(_selectors3.userRolesLoadedSelector);
  var isEventInfoLoaded = (0, _reactRedux.useSelector)(_eventInfo.default.selectors.selectEventInfoLoaded);
  if (!isTicketsLoaded || !isEventInfoLoaded || !isTeamMembersLoaded || !isRolesLoaded) return _react.default.createElement(_absoluteSpinner.default, null);
  if (hasTickets || staffMember || hasAccessByRole) return children;

  _logger.default.debug('[TicketGuard] Redirect to HOME!');

  return _react.default.createElement(_Redirect.default, {
    to: _routesConsts.Routes.HOME
  });
};

var _default = TicketGuard;
exports.default = _default;