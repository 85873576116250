Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_DJS_MUSIC_GENRE_COMPLETED = exports.LOAD_DJS_COMPLETED = exports.UPDATE_PROFILE_COMPLETED = exports.RESET_FOLLOWING = exports.LOAD_PART_OF_FOLLOWING = exports.LOAD_FOLLOWING_COMPLETED = exports.RESET_FOLLOWERS = exports.LOAD_PART_OF_FOLLOWERS = exports.LOAD_FOLLOWERS_COMPLETED = exports.LOAD_NR_FOLLOWERS_COMPLETED = exports.LOAD_NR_FOLLOWING_COMPLETED = exports.LOAD_PROFILE_COMPLETED = exports.NAME = void 0;
var NAME = 'profile';
exports.NAME = NAME;
var LOAD_PROFILE_COMPLETED = 'profile/LOAD_PROFILE_COMPLETED';
exports.LOAD_PROFILE_COMPLETED = LOAD_PROFILE_COMPLETED;
var LOAD_NR_FOLLOWING_COMPLETED = 'profile/LOAD_NR_FOLLOWING_COMPLETED';
exports.LOAD_NR_FOLLOWING_COMPLETED = LOAD_NR_FOLLOWING_COMPLETED;
var LOAD_NR_FOLLOWERS_COMPLETED = 'profile/LOAD_NR_FOLLOWERS_COMPLETED';
exports.LOAD_NR_FOLLOWERS_COMPLETED = LOAD_NR_FOLLOWERS_COMPLETED;
var LOAD_FOLLOWERS_COMPLETED = 'profile/LOAD_FOLLOWERS_COMPLETED';
exports.LOAD_FOLLOWERS_COMPLETED = LOAD_FOLLOWERS_COMPLETED;
var LOAD_PART_OF_FOLLOWERS = 'profile/LOAD_PART_OF_FOLLOWERS';
exports.LOAD_PART_OF_FOLLOWERS = LOAD_PART_OF_FOLLOWERS;
var RESET_FOLLOWERS = 'profile/RESET_FOLLOWERS';
exports.RESET_FOLLOWERS = RESET_FOLLOWERS;
var LOAD_FOLLOWING_COMPLETED = 'profile/LOAD_FOLLOWING_COMPLETED';
exports.LOAD_FOLLOWING_COMPLETED = LOAD_FOLLOWING_COMPLETED;
var LOAD_PART_OF_FOLLOWING = 'profile/LOAD_PART_OF_FOLLOWING';
exports.LOAD_PART_OF_FOLLOWING = LOAD_PART_OF_FOLLOWING;
var RESET_FOLLOWING = 'profile/RESET_FOLLOWING';
exports.RESET_FOLLOWING = RESET_FOLLOWING;
var UPDATE_PROFILE_COMPLETED = 'profile/UPDATE_PROFILE_COMPLETED';
exports.UPDATE_PROFILE_COMPLETED = UPDATE_PROFILE_COMPLETED;
var LOAD_DJS_COMPLETED = 'profile/LOAD_DJS_COMPLETED';
exports.LOAD_DJS_COMPLETED = LOAD_DJS_COMPLETED;
var LOAD_DJS_MUSIC_GENRE_COMPLETED = 'profile/LOAD_DJS_MUSIC_GENRE_COMPLETED';
exports.LOAD_DJS_MUSIC_GENRE_COMPLETED = LOAD_DJS_MUSIC_GENRE_COMPLETED;