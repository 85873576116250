Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRACKABLE_EVENTS = void 0;
var TRACKABLE_EVENTS = {
  LOBBY_PAGE_ACCESSED: 'lobbyPageAccessed',
  BOOTH_PAGE_ACCESSED: 'boothPageAccessed',
  SESSION_PAGE_ACCESSED: 'sessionPageAccessed',
  NETWORKING_ROOM_ACCESSED: 'networkingRoomAccessed',
  NETWORKING_ROOM_VIEWED: 'networkingRoomViewed',
  RECORDED_VIDEOS_ACCESSED: 'recordedVideosViewed'
};
exports.TRACKABLE_EVENTS = TRACKABLE_EVENTS;