Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var _reactNative = require("react-native");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1.5),
      padding: theme.spacing(0.5)
    }
  };
});
var _default = styles;
exports.default = _default;