var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _backButtonView = _interopRequireDefault(require("./back-button-view"));

var BackButtonContainer = function BackButtonContainer(props) {
  var handleNavigateBack = function handleNavigateBack() {
    window.history.back();
  };

  return _react.default.createElement(_backButtonView.default, (0, _extends2.default)({}, props, {
    onBackNavigation: handleNavigateBack
  }));
};

var _default = BackButtonContainer;
exports.default = _default;