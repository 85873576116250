var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getZoneAbbreviation = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var getZoneAbbreviation = function getZoneAbbreviation() {
  var abbreviation = (0, _momentTimezone.default)().tz(_momentTimezone.default.tz.guess()).format('z');

  if (!isNaN(abbreviation)) {
    var zPlusMinus = abbreviation.toString()[0];
    var zDistance = abbreviation.toString().substring(1);

    if (zDistance < 100) {
      zDistance += ':00';
    } else {
      zDistance = "".concat(zDistance.substring(0, 2), ":").concat(zDistance.substring(2));
    }

    abbreviation = "".concat(zPlusMinus + zDistance, " UTC");
  }

  return abbreviation;
};

exports.getZoneAbbreviation = getZoneAbbreviation;