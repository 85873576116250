var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _actions = require("./actions");

var _user = _interopRequireDefault(require("../user"));

function createMiddleware() {
  return function (store) {
    return function (next) {
      return function (action) {
        switch (action.type) {
          case _user.default.constants.USER_LOGOUT:
            break;

          default:
            break;
        }

        var result = next(action);

        switch (action.type) {
          case _user.default.constants.FIREBASE_LOGIN_COMPLETED:
            break;

          default:
            break;
        }

        return result;
      };
    };
  };
}