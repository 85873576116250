var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.checkoutIntentReducer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("../user/constants");

var _actions = require("./actions");

var _constants2 = require("./constants");

var _createReducer, _createReducer2;

var initialTicketState = _constants2.eventTicketEntityAdapter.getInitialState({
  loaded: false,
  loading: false,
  error: null
});

var initialPromoCodeState = _constants2.promoCodeEntityAdapter.getInitialState({
  loaded: false,
  loading: false,
  error: null
});

var initialState = {
  ticketState: initialTicketState,
  promoCodeState: initialPromoCodeState,
  loading: false,
  error: null
};
var checkoutReducer = (0, _toolkit.createReducer)(initialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.getEventTickets.pending, function (state) {
  state.ticketState.loading = true;
  state.ticketState.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.getEventTickets.fulfilled, function (state, _ref) {
  var payload = _ref.payload;

  _constants2.eventTicketEntityAdapter.upsertMany(state.ticketState, payload);

  state.ticketState.loaded = true;
  state.ticketState.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.getEventTickets.rejected, function (state, _ref2) {
  var error = _ref2.error;
  state.ticketState.loading = false;
  state.ticketState.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.getPromoCodes.pending, function (state) {
  state.promoCodeState.loading = true;
  state.promoCodeState.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.getPromoCodes.fulfilled, function (state, _ref3) {
  var payload = _ref3.payload;

  _constants2.promoCodeEntityAdapter.upsertMany(state.promoCodeState, payload);

  state.promoCodeState.loaded = true;
  state.promoCodeState.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.getPromoCodes.rejected, function (state, _ref4) {
  var error = _ref4.error;
  state.promoCodeState.loading = false;
  state.promoCodeState.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.startCheckout.pending, function (state) {
  state.loading = true;
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.startCheckout.fulfilled, function (state) {
  state.loading = false;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.startCheckout.rejected, function (state, _ref5) {
  var error = _ref5.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.finishCheckout.pending, function (state) {
  state.loading = true;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.finishCheckout.fulfilled, function (state) {
  state.loading = false;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.finishCheckout.rejected, function (state, _ref6) {
  var error = _ref6.error;
  state.loading = false;
  state.error = error;
}), (0, _defineProperty2.default)(_createReducer, _actions.createFreeUnassignedTickets.pending, function (state) {
  state.loading = true;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.createFreeUnassignedTickets.fulfilled, function (state) {
  state.loading = false;
  state.error = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.createFreeUnassignedTickets.rejected, function (state, _ref7) {
  var error = _ref7.error;
  state.loading = false;
  state.error = error;
}), _createReducer));
var initialIntentState = {
  callbackUrl: null,
  ticketId: null,
  promoCodeId: null
};
var checkoutIntentReducer = (0, _toolkit.createReducer)(initialIntentState, (_createReducer2 = {}, (0, _defineProperty2.default)(_createReducer2, _actions.removeCheckoutIntent.type, function () {
  return initialIntentState;
}), (0, _defineProperty2.default)(_createReducer2, _actions.startCheckout.pending, function (state, _ref8) {
  var arg = _ref8.meta.arg;
  var ticketId = arg.ticketId,
      callbackUrl = arg.callbackUrl,
      promoCodeId = arg.promoCodeId;
  state.callbackUrl = callbackUrl;
  state.ticketId = ticketId;
  state.promoCodeId = promoCodeId;
}), (0, _defineProperty2.default)(_createReducer2, _actions.setPromoCode.type, function (state, _ref9) {
  var payload = _ref9.payload;
  state.promoCodeId = payload ? payload === null || payload === void 0 ? void 0 : payload.id : '';
}), (0, _defineProperty2.default)(_createReducer2, _actions.setSelectedAddonVariations.type, function (state, _ref10) {
  var payload = _ref10.payload;
  state.selectedAddonVariations = payload.selectedAddonVariations;
  state.variationsPrice = payload.variationsPrice;
  state.totalPrice = payload.totalPrice;
}), (0, _defineProperty2.default)(_createReducer2, _actions.resetSelectedAddonVariations.type, function (state) {
  state.selectedAddonVariations = [];
  state.variationsPrice = 0;
  state.totalPrice = 0;
}), (0, _defineProperty2.default)(_createReducer2, _constants.USER_LOGOUT, function (_) {
  return initialIntentState;
}), _createReducer2));
exports.checkoutIntentReducer = checkoutIntentReducer;
var _default = checkoutReducer;
exports.default = _default;