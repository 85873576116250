var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactRedux = require("react-redux");

var _user = _interopRequireDefault(require("../../../core/user"));

var _phoneLoginContainer = _interopRequireDefault(require("./phoneLogin-container"));

var _default = (0, _reactRedux.connect)(null, {
  login: _user.default.actions.loginWithProvider,
  phoneLogin: _user.default.actions.firebasePhoneNumberLogin
})(_phoneLoginContainer.default);

exports.default = _default;