var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

function Timeout(_ref) {
  var onTimeout = _ref.onTimeout,
      ms = _ref.ms,
      children = _ref.children;

  var _React$useState = _react.default.useState(0),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      diff = _React$useState2[0],
      setDiff = _React$useState2[1];

  _react.default.useEffect(function () {
    setDiff(ms / 1e3);
    var interval = setInterval(function () {
      return setDiff(function (old) {
        return --old;
      });
    }, 1000);
    var timeout = setTimeout(function () {
      setDiff(0);
      clearInterval(interval);
      onTimeout();
    }, ms);

    function onBlur() {
      clearInterval(interval);
      clearTimeout(timeout);
      onTimeout();
    }

    window.addEventListener('blur', onBlur);
    return function () {
      clearInterval(interval);
      clearTimeout(timeout);
      window.removeEventListener('blur', onBlur);
    };
  }, [onTimeout, ms, setDiff]);

  return _react.default.createElement(_react.default.Fragment, null, children(diff));
}

var _default = Timeout;
exports.default = _default;