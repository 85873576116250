var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _useHeader = _interopRequireDefault(require("../../utils/hooks/useHeader"));

var _Section = _interopRequireDefault(require("../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _backButton = _interopRequireDefault(require("../auth/back-button"));

var _privacyPolicyView = _interopRequireDefault(require("../privacy-policy/privacy-policy-view"));

var PrivacyPolicyPage = function PrivacyPolicyPage() {
  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });
  return _react.default.createElement(_Section.default, {
    style: {
      flex: 1
    },
    color: "pageBackground"
  }, _react.default.createElement(_reactNative.ScrollView, null, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
    mSpacing: ['xl', 'xxl']
  }, _react.default.createElement(_backButton.default, null), _react.default.createElement(_privacyPolicyView.default, null))));
};

var _default = PrivacyPolicyPage;
exports.default = _default;