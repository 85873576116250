Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectPollsLength = exports.selectPollById = exports.selectPollsIsLoaded = exports.selectPollsIsLoading = exports.selectPollsList = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var selectPollsState = function selectPollsState(state) {
  return state[_constants.NAME];
};

var selectPollsList = _constants.pollsEntityAdapter.getSelectors(selectPollsState).selectAll;

exports.selectPollsList = selectPollsList;
var selectPollsIsLoading = (0, _toolkit.createSelector)(selectPollsState, function (state) {
  return state.loading;
});
exports.selectPollsIsLoading = selectPollsIsLoading;
var selectPollsIsLoaded = (0, _toolkit.createSelector)(selectPollsState, function (state) {
  return state.loaded;
});
exports.selectPollsIsLoaded = selectPollsIsLoaded;

var selectPollById = _constants.pollsEntityAdapter.getSelectors(selectPollsState).selectById;

exports.selectPollById = selectPollById;
var selectPollsLength = (0, _toolkit.createSelector)(selectPollsList, function (state) {
  return state.length;
});
exports.selectPollsLength = selectPollsLength;