Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONNECTION_STATUS = exports.LEAVE = exports.WEBSOCKET_LOAD_USERS = exports.WEBSOCKET_NUMBER_OF_USERS_CHANGED_EVENT = exports.WEBSOCKET_WELCOME_EVENT = exports.NAME = void 0;
var NAME = 'live';
exports.NAME = NAME;
var WEBSOCKET_WELCOME_EVENT = 'welcome';
exports.WEBSOCKET_WELCOME_EVENT = WEBSOCKET_WELCOME_EVENT;
var WEBSOCKET_NUMBER_OF_USERS_CHANGED_EVENT = 'numUsersChanged';
exports.WEBSOCKET_NUMBER_OF_USERS_CHANGED_EVENT = WEBSOCKET_NUMBER_OF_USERS_CHANGED_EVENT;
var WEBSOCKET_LOAD_USERS = 'all:users';
exports.WEBSOCKET_LOAD_USERS = WEBSOCKET_LOAD_USERS;
var LEAVE = 'live/LEAVE';
exports.LEAVE = LEAVE;
var CONNECTION_STATUS = {
  OPEN: 'open',
  CONNECTING: 'opening',
  CONNECTED: 'connected'
};
exports.CONNECTION_STATUS = CONNECTION_STATUS;