var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RemoteAudio;

var _react = _interopRequireDefault(require("react"));

var _Audio = _interopRequireDefault(require("./Audio"));

function RemoteAudio(_ref) {
  var audioTrack = _ref.audioTrack;
  return _react.default.createElement(_Audio.default, {
    audioTrack: audioTrack
  });
}