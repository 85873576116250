var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var ExternalVideoView = function ExternalVideoView(props) {
  return _react.default.createElement("iframe", {
    title: props.title,
    height: props.height,
    width: props.width,
    src: props.video,
    frameBorder: "0",
    allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
    allowFullScreen: true
  });
};

var _default = ExternalVideoView;
exports.default = _default;