Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectExposLength = exports.selectBoothById = exports.selectExposLoaded = exports.selectExposLoading = exports.selectExposList = exports.selectExposState = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectExposState = function selectExposState(state) {
  return state[_constants.NAME];
};

exports.selectExposState = selectExposState;

var selectExposList = _reducer.expoEntityAdapter.getSelectors(selectExposState).selectAll;

exports.selectExposList = selectExposList;
var selectExposLoading = (0, _toolkit.createSelector)(selectExposState, function (state) {
  return state.loading;
});
exports.selectExposLoading = selectExposLoading;
var selectExposLoaded = (0, _toolkit.createSelector)(selectExposState, function (state) {
  return state.loaded;
});
exports.selectExposLoaded = selectExposLoaded;

var selectBoothById = _reducer.expoEntityAdapter.getSelectors(selectExposState).selectById;

exports.selectBoothById = selectBoothById;
var selectExposLength = (0, _toolkit.createSelector)(selectExposList, function (state) {
  return state.length;
});
exports.selectExposLength = selectExposLength;