var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UserView;

var _react = _interopRequireDefault(require("react"));

var _Avatar = _interopRequireDefault(require("../../../theme/components/avatar/Avatar"));

var _LinkButton = _interopRequireDefault(require("../../../theme/components/buttons/LinkButton"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _stringFormatter = require("../../../utils/string-formatter");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _relativeModal = _interopRequireDefault(require("../../../theme/components/relative-modal/relative-modal"));

var _profileCardModal = _interopRequireDefault(require("../profile-card-modal"));

var _styles = _interopRequireDefault(require("./styles"));

var _MuiLinkButton = _interopRequireDefault(require("./../../common/custom-design-components/MuiLinkButton"));

function UserView(_ref) {
  var fnm = _ref.fnm,
      avt = _ref.avt,
      usr = _ref.usr,
      onBlock = _ref.onBlock,
      onUnblock = _ref.onUnblock,
      isBlocked = _ref.isBlocked;
  var classes = (0, _styles.default)();
  return _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 'l'],
    direction: "row",
    wrap: "nowrap"
  }, _react.default.createElement(_relativeModal.default, {
    content: _react.default.createElement(_profileCardModal.default, {
      userId: usr,
      displayName: fnm
    })
  }, function (_ref2) {
    var onOpen = _ref2.onOpen;
    return _react.default.createElement(_Spacing.default, {
      style: {
        cursor: 'pointer'
      }
    }, _react.default.createElement(_Avatar.default, {
      onPress: function onPress(e) {
        var messagePositions = e.target.getBoundingClientRect();
        e.preventDefault();
        e.stopPropagation();
        onOpen(messagePositions);
      },
      source: avt || "https://via.placeholder.com/40/000000/FFFFFF/?text=".concat((0, _stringFormatter.getInitials)(fnm)),
      name: fnm,
      size: 40
    }));
  }), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 0, 's'],
    direction: "column",
    flex: 1,
    alignItems: "stretch"
  }, _react.default.createElement(_core.Typography, {
    className: classes.textTruncate,
    variant: "h4",
    align: "left"
  }, fnm), onBlock && onUnblock && _react.default.createElement(_Spacing.default, {
    mSpacing: ['xs', 0, 0, 0],
    alignItems: "center",
    justifyContent: "flex-start"
  }, _react.default.createElement(_MuiLinkButton.default, {
    color: "error",
    translationKey: isBlocked ? 'chat.unblockUser' : 'chat.blockUser',
    variant: "overline",
    onClick: !isBlocked ? onBlock : onUnblock
  }))));
}