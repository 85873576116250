Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectCategoriesLength = exports.selectCategoriesLoaded = exports.selectCategoriesLoading = exports.selectCategoriesList = exports.selectCategoriesState = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectCategoriesState = function selectCategoriesState(state) {
  return state[_constants.NAME];
};

exports.selectCategoriesState = selectCategoriesState;

var selectCategoriesList = _reducer.categoryEntityAdapter.getSelectors(selectCategoriesState).selectAll;

exports.selectCategoriesList = selectCategoriesList;
var selectCategoriesLoading = (0, _toolkit.createSelector)(selectCategoriesState, function (state) {
  return state.loading;
});
exports.selectCategoriesLoading = selectCategoriesLoading;
var selectCategoriesLoaded = (0, _toolkit.createSelector)(selectCategoriesState, function (state) {
  return state.loaded;
});
exports.selectCategoriesLoaded = selectCategoriesLoaded;
var selectCategoriesLength = (0, _toolkit.createSelector)(selectCategoriesList, function (state) {
  return state.length;
});
exports.selectCategoriesLength = selectCategoriesLength;