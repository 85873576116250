var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useListenEvent;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _useSafeState3 = _interopRequireDefault(require("./useSafeState"));

function useListenEvent(observer) {
  var _useSafeState = (0, _useSafeState3.default)((0, _react.useState)()),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      event = _useSafeState2[0],
      setEvent = _useSafeState2[1];

  (0, _react.useEffect)(function () {
    return observer.subscribe(function (ev) {
      return setEvent(ev);
    });
  }, [setEvent, observer]);
  return event;
}