var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResetPasswordCustom;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _authStyles = _interopRequireDefault(require("../auth-styles"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/auth/selectors");

var _actions = require("../../../core/auth/actions");

var _absoluteSpinner = _interopRequireDefault(require("../../common/absolute-spinner"));

var _resetPasswordCustomView = _interopRequireDefault(require("./reset-password-custom-view"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _invalidCodeView = _interopRequireDefault(require("./screens/invalid-code-view"));

var _resetSuccessView = _interopRequireDefault(require("./screens/reset-success-view"));

function ResetPasswordCustom() {
  var navigation = (0, _useNavigationExtended.default)();
  var Styles = (0, _authStyles.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var error = (0, _reactRedux.useSelector)(_selectors.getResetPasswordActionError);
  var resetActionError = (0, _reactRedux.useSelector)(_selectors.getResetPasswordConfirmError);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.isResetPasswordActionLoading);
  var validResetCode = (0, _reactRedux.useSelector)(_selectors.isValidResetCode);
  var resetActionSuccess = (0, _reactRedux.useSelector)(_selectors.resetActionSucceeded);
  var currentUrl = new URL(window.location.href);
  var code = currentUrl.searchParams.get('oobCode');
  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });

  _react.default.useEffect(function () {
    dispatch((0, _actions.verifyPasswordResetCode)(code));
  }, []);

  return _react.default.createElement(_Section.default, {
    style: {
      flex: 1
    },
    color: "pageBackground"
  }, _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: Styles.scrollView
  }, isLoading && _react.default.createElement(_absoluteSpinner.default, {
    size: "large"
  }), !isLoading && (error || !validResetCode) && _react.default.createElement(_invalidCodeView.default, {
    goToResetPassword: function goToResetPassword() {
      return navigation.navigate(_routesConsts.Routes.RESET_PASSWORD);
    }
  }), !isLoading && validResetCode && !resetActionSuccess && _react.default.createElement(_resetPasswordCustomView.default, {
    onResetPassword: function onResetPassword(newPassword) {
      return dispatch((0, _actions.launchPasswordResetAction)({
        newPassword: newPassword,
        code: code
      }));
    },
    goToRegister: function goToRegister() {
      return navigation.navigate(_routesConsts.Routes.REGISTER);
    },
    goToLogin: function goToLogin() {
      return navigation.navigate(_routesConsts.Routes.LOGIN);
    },
    isLoading: isLoading,
    resetPasswordFeedback: error || resetActionError
  }), !isLoading && resetActionSuccess && _react.default.createElement(_resetSuccessView.default, {
    goToLoginScreen: function goToLoginScreen() {
      return navigation.navigate(_routesConsts.Routes.LOGIN);
    }
  })));
}