var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BaseVideo;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _openplayerjs = _interopRequireDefault(require("openplayerjs"));

function BaseVideo(_ref) {
  var src = _ref.src,
      currentTime = _ref.currentTime,
      jumpAfterResume = _ref.jumpAfterResume,
      onError = _ref.onError,
      lobbyVideo = _ref.lobbyVideo,
      onEnded = _ref.onEnded,
      loopVideo = _ref.loopVideo;

  var ref = _react.default.useRef();

  var _React$useState = _react.default.useState(null),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      video = _React$useState2[0],
      setVideo = _React$useState2[1];

  var _React$useState3 = _react.default.useState(true),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      isPlaying = _React$useState4[0],
      setIsPlaying = _React$useState4[1];

  var _React$useState5 = _react.default.useState(false),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      videoLoaded = _React$useState6[0],
      setVideoLoaded = _React$useState6[1];

  var _React$useState7 = _react.default.useState(null),
      _React$useState8 = (0, _slicedToArray2.default)(_React$useState7, 2),
      pausedAt = _React$useState8[0],
      setPausedAt = _React$useState8[1];

  _react.default.useEffect(function () {
    var _video = new _openplayerjs.default(ref.current, {
      controls: {
        layers: {
          left: ['play', 'volume'],
          right: ['fullscreen']
        }
      },
      live: {
        showLabel: true,
        showProgress: false
      },
      showLoaderOnInit: lobbyVideo ? false : true
    });

    _video.init();

    setVideo(_video);
    return function () {
      _video.destroy();
    };
  }, [setVideo]);

  _react.default.useEffect(function () {
    if (!video) return;

    if (src instanceof MediaStream) {
      video.getElement().srcObject = src;
    } else {
      video.src = src;
    }

    setVideoLoaded(false);
    video.load();
  }, [video, src, setVideoLoaded]);

  _react.default.useEffect(function () {
    if (!video) return;
    video.getElement().muted = !isPlaying;
  }, [video, isPlaying]);

  _react.default.useEffect(function () {
    if (!video) return;
    var element = video.getElement();

    function onStop() {
      setIsPlaying(false);
    }

    function onStart() {
      setIsPlaying(true);
    }

    function handleMetadataLoaded() {
      setVideoLoaded(true);
    }

    function handleError(e) {
      onError && onError(e);
    }

    function handleEnd(e) {
      onEnded && onEnded(e);
    }

    element.addEventListener('loadedmetadata', handleMetadataLoaded);
    element.addEventListener('playererror', handleError);
    element.addEventListener('ended', handleEnd);
    element.addEventListener('play', onStart);
    element.addEventListener('pause', onStop);
    return function () {
      element.removeEventListener('play', onStart);
      element.removeEventListener('pause', onStop);
      element.removeEventListener('loadedmetadata', handleMetadataLoaded);
      element.removeEventListener('playererror', handleError);
      element.removeEventListener('ended', handleEnd);
    };
  }, [video, setIsPlaying, onError, onEnded, setIsPlaying, setVideoLoaded]);

  _react.default.useEffect(function () {
    if (!videoLoaded) return;
    if (!currentTime) return;
    if (!video) return;
    if (src instanceof MediaStream) return;
    video.getMedia().currentTime = currentTime;
  }, [currentTime, video, videoLoaded, src]);

  _react.default.useEffect(function () {
    if (!jumpAfterResume) return;
    setPausedAt(!isPlaying ? Date.now() : null);
  }, [jumpAfterResume, isPlaying, setPausedAt]);

  _react.default.useEffect(function () {
    if (!jumpAfterResume) return;
    if (!video) return;
    if (!isPlaying) return;
    if (!pausedAt) return;
    if (src instanceof MediaStream) return;
    video.getMedia().currentTime += (Date.now() - pausedAt) / 1000;
  }, [jumpAfterResume, isPlaying, pausedAt, video, src]);

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("video", {
    ref: ref,
    className: "op-player__media",
    autoPlay: lobbyVideo ? false : true,
    controls: true,
    loop: loopVideo || false,
    playsInline: true
  }));
}