var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textInfo: (0, _defineProperty2.default)({
      marginBottom: 15,
      lineHeight: '2rem'
    }, theme.breakpoints.down('md'), {
      marginTop: 0,
      marginBottom: 10,
      fontSize: 14
    })
  };
});
var _default = styles;
exports.default = _default;