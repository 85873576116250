Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useNextSessionViewStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    countDownInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    countdownText: {
      color: '#FFFFFF',
      fontSize: 15,
      lineHeight: 24,
      letterSpacing: -1,
      marginTop: theme.spacing.l,
      marginBottom: theme.spacing.s
    },
    avatars: {
      alignItems: 'center',
      marginBottom: theme.spacing.m
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.md;
}, function (theme) {
  return {
    countDownInnerContainer: {
      paddingVertical: theme.spacing.s
    },
    countdownText: {
      marginTop: theme.spacing.m,
      marginBottom: theme.spacing.m
    },
    avatars: {
      display: 'none'
    }
  };
}).buildHook();
var _default = useNextSessionViewStyles;
exports.default = _default;