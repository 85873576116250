Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    calendarButtonText: {
      opacity: 0.6,
      textTransform: 'uppercase'
    },
    selectedColor: {
      color: theme.palette.headerText.main
    },
    defaltColor: {
      color: theme.palette.pageText.main
    },
    textBold: {
      fontWeight: 'bold'
    }
  };
});
var _default = styles;
exports.default = _default;