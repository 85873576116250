Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAppCutOffDate = exports.isAppObsolete = exports.isAppDeprecated = exports.getLatestAvailableVersionReadMoreUrl = exports.getLatestAvailableVersionUrl = exports.getLatestAvailableVersion = exports.hasNewerVersion = exports.hasNotifications = void 0;

var _constants = require("./constants");

var hasNotifications = function hasNotifications(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body'], null) !== null;
};

exports.hasNotifications = hasNotifications;

var hasNewerVersion = function hasNewerVersion(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body', 'latestAvailableVersion'], null) !== null;
};

exports.hasNewerVersion = hasNewerVersion;

var getLatestAvailableVersion = function getLatestAvailableVersion(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body', 'latestAvailableVersion']);
};

exports.getLatestAvailableVersion = getLatestAvailableVersion;

var getLatestAvailableVersionUrl = function getLatestAvailableVersionUrl(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body', 'url']);
};

exports.getLatestAvailableVersionUrl = getLatestAvailableVersionUrl;

var getLatestAvailableVersionReadMoreUrl = function getLatestAvailableVersionReadMoreUrl(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body', 'readMore']);
};

exports.getLatestAvailableVersionReadMoreUrl = getLatestAvailableVersionReadMoreUrl;

var isAppDeprecated = function isAppDeprecated(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body', 'currentVersionStatus'], 'available') === 'deprecated' && Date.now() >= state[_constants.NAME].getIn(['updateNotification', 'snoozeUntil'], 0);
};

exports.isAppDeprecated = isAppDeprecated;

var isAppObsolete = function isAppObsolete(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body', 'currentVersionStatus'], 'available') === 'obsolete';
};

exports.isAppObsolete = isAppObsolete;

var getAppCutOffDate = function getAppCutOffDate(state) {
  return state[_constants.NAME].getIn(['updateNotification', 'body', 'cutOffDate']);
};

exports.getAppCutOffDate = getAppCutOffDate;