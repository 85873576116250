var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAddons = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _toolkit = require("@reduxjs/toolkit");

var _firebaseUtils = require("../../utils/firebase-utils");

var _selectors = require("../event-info/selectors");

var _selectors2 = require("../user/selectors");

var _constants = require("./constants");

var getAddons = (0, _toolkit.createAsyncThunk)(_constants.GET_ADDONS, function () {
  var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(uid, _ref) {
    var getState, userId, eventId, addons;
    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            getState = _ref.getState;
            userId = (0, _selectors2.getFirebaseUserUid)(getState()) || uid;
            eventId = (0, _selectors.selectEventInfoId)(getState());

            if (userId) {
              _context.next = 5;
              break;
            }

            throw Error('No logged in user!');

          case 5:
            _context.next = 7;
            return (0, _firebaseUtils.collectionPromise)(_firebaseUtils.firebaseEntities.addons(userId).where('eventId', '==', eventId));

          case 7:
            addons = _context.sent;
            return _context.abrupt("return", addons);

          case 9:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function (_x, _x2) {
    return _ref2.apply(this, arguments);
  };
}());
exports.getAddons = getAddons;