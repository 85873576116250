var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var InitialState = _immutable.default.Map();

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.LOAD_DATA_COMPLETED:
      newState = _immutable.default.fromJS(action.data);
      break;

    case _actionTypes.LOAD_REFERRALS_LIST_COMPLETED:
      newState = _immutable.default.fromJS(action.data);
      break;

    default:
      break;
  }

  return newState;
}