var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadFollowersNumber = loadFollowersNumber;
exports.loadFollowingsNumber = loadFollowingsNumber;
exports.loadDjs = loadDjs;
exports.loadDjsCompleted = loadDjsCompleted;
exports.loadProfile = loadProfile;
exports.loadProfileCompleted = loadProfileCompleted;
exports.loadDjsMusicGenre = loadDjsMusicGenre;
exports.loadDjsMusicGenreCompleted = loadDjsMusicGenreCompleted;
exports.loadNumberOfFollowersCompleted = loadNumberOfFollowersCompleted;
exports.loadNumberOfFollowingCompleted = loadNumberOfFollowingCompleted;
exports.loadFollowers = loadFollowers;
exports.resetFollowers = resetFollowers;
exports.loadPartiallyFollowersCompleted = loadPartiallyFollowersCompleted;
exports.loadFollowing = loadFollowing;
exports.resetFollowing = resetFollowing;
exports.loadPartiallyFollowingCompleted = loadPartiallyFollowingCompleted;
exports.updateProfile = updateProfile;
exports.updateProfileCompleted = updateProfileCompleted;

var _actionTypes = require("./actionTypes");

var _user = _interopRequireDefault(require("../user"));

var _package = require("../../../package.json");

function loadFollowersNumber(userId) {
  return function (dispatch, getState, _ref) {
    var api = _ref.api;
    return api.head({
      action: "/soundmix_users/".concat(userId, "/followers")
    }).then(function (response) {
      var range = api.getContentRange(response.headers);
      dispatch(loadNumberOfFollowersCompleted(userId, range.total));
    });
  };
}

function loadFollowingsNumber(userId) {
  return function (dispatch, getState, _ref2) {
    var api = _ref2.api;
    return api.head({
      action: "/soundmix_users/".concat(userId, "/followings")
    }).then(function (response) {
      var range = api.getContentRange(response.headers);
      dispatch(loadNumberOfFollowingCompleted(userId, range.total));
    });
  };
}

function loadDjs() {
  return function (dispatch, getState, _ref3) {
    var api = _ref3.api;
    return api.get({
      action: '/soundmix_users?order=priority&roles[]=ROLE_DJ'
    }).then(function (_ref4) {
      var djs = _ref4.data;
      dispatch(loadDjsCompleted(djs));
    });
  };
}

function loadDjsCompleted(dj) {
  return {
    type: _actionTypes.LOAD_DJS_COMPLETED,
    dj: dj
  };
}

function loadProfile(userId) {
  return function (dispatch, getState, _ref5) {
    var api = _ref5.api;
    return api.get({
      action: "/soundmix_users/".concat(userId)
    }).then(function (_ref6) {
      var profile = _ref6.data;
      dispatch(loadProfileCompleted(profile));
      return profile;
    });
  };
}

function loadProfileCompleted(userProfile) {
  return {
    profile: userProfile,
    type: _actionTypes.LOAD_PROFILE_COMPLETED
  };
}

function loadDjsMusicGenre(hashtags) {
  hashtags = hashtags || [];
  return function (dispatch, getState, _ref7) {
    var api = _ref7.api;
    var url = "/soundmix_users?roles[]=ROLE_DJ&".concat(hashtags.map(function (t) {
      return "hashTags[]=".concat(t);
    }).join('&'));
    return api.get({
      action: url
    }).then(function (_ref8) {
      var djList = _ref8.data;
      dispatch(loadDjsMusicGenreCompleted(djList));
      return djList;
    });
  };
}

function loadDjsMusicGenreCompleted(djList) {
  return {
    djList: djList,
    type: _actionTypes.LOAD_DJS_MUSIC_GENRE_COMPLETED
  };
}

function loadNumberOfFollowersCompleted(userId, numFollowers) {
  return {
    userId: userId,
    numFollowers: numFollowers,
    type: _actionTypes.LOAD_NR_FOLLOWERS_COMPLETED
  };
}

function loadNumberOfFollowingCompleted(userId, numFollowing) {
  return {
    userId: userId,
    numFollowing: numFollowing,
    type: _actionTypes.LOAD_NR_FOLLOWING_COMPLETED
  };
}

function loadFollowers(userId) {
  return function (dispatch, getState, _ref9) {
    var api = _ref9.api;
    dispatch(resetFollowers(userId));

    var getPage = function getPage(page) {
      return api.get({
        action: "/soundmix_users/".concat(userId, "/followers"),
        params: {
          page: page
        }
      }).then(function (pagedResponse) {
        dispatch(loadPartiallyFollowersCompleted(userId, pagedResponse.data));
        var range = api.getContentRange(pagedResponse.headers);

        if (range.to < range.total - 1) {
          return getPage(page + 1);
        }
      });
    };

    return getPage(1);
  };
}

function resetFollowers(userId) {
  return {
    userId: userId,
    type: _actionTypes.RESET_FOLLOWERS
  };
}

function loadPartiallyFollowersCompleted(userId, partialFollowers) {
  return {
    userId: userId,
    type: _actionTypes.LOAD_PART_OF_FOLLOWERS,
    followers: partialFollowers
  };
}

function loadFollowing(userId) {
  return function (dispatch, getState, _ref10) {
    var api = _ref10.api;
    dispatch(resetFollowing(userId));

    var getPage = function getPage(page) {
      return api.get({
        action: "/soundmix_users/".concat(userId, "/followings"),
        params: {
          page: page
        }
      }).then(function (pagedResponse) {
        dispatch(loadPartiallyFollowingCompleted(userId, pagedResponse.data));
        var range = api.getContentRange(pagedResponse.headers);

        if (range.to < range.total - 1) {
          return getPage(page + 1);
        }
      });
    };

    return getPage(1);
  };
}

function resetFollowing(userId) {
  return {
    userId: userId,
    type: _actionTypes.RESET_FOLLOWING
  };
}

function loadPartiallyFollowingCompleted(userId, partialFollowing) {
  return {
    userId: userId,
    type: _actionTypes.LOAD_PART_OF_FOLLOWING,
    following: partialFollowing
  };
}

function updateProfile(profileData) {
  var userId = profileData.userId,
      acceptedTerms = profileData.acceptedTerms,
      displayName = profileData.displayName;
  return function (dispatch, getState, _ref11) {
    var api = _ref11.api;
    var paramsObj = profileData;
    dispatch(_user.default.actions.hasIncompleteProfile(false));
  };
}

function updateProfileCompleted(userId, userName, bio, twitterProfileLink, facebookProfileLink, soundcloudProfileLink, instagramProfileLink, mixcloudProfileLink) {
  return {
    userId: userId,
    userName: userName,
    bio: bio,
    twitterProfileLink: twitterProfileLink,
    facebookProfileLink: facebookProfileLink,
    soundcloudProfileLink: soundcloudProfileLink,
    instagramProfileLink: instagramProfileLink,
    mixcloudProfileLink: mixcloudProfileLink,
    type: _actionTypes.UPDATE_PROFILE_COMPLETED
  };
}