var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectLobbyPresentationVideo = exports.selectAccessedVideos = exports.selectRecordedVideosLength = exports.selectRecordedVideoById = exports.selectRecordedVideosIsLoaded = exports.selectRecordedVideosIsLoading = exports.selectRecordedVideosList = exports.simpleSelectors = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var _selectors = require("../tickets/selectors");

var _selectors2 = require("../event-info/selectors");

var _selectors3 = require("../team/selectors");

var _selectors4 = require("../speakers/selectors");

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _recordedVideosContainer = require("../../components/recorded-videos/recorded-videos-container");

var selectRecordedVideosState = function selectRecordedVideosState(state) {
  return state[_constants.NAME];
};

var simpleSelectors = _reducer.recordedVideosEntityAdapter.getSelectors(selectRecordedVideosState);

exports.simpleSelectors = simpleSelectors;
var selectRecordedVideosList = simpleSelectors.selectAll;
exports.selectRecordedVideosList = selectRecordedVideosList;
var selectRecordedVideosIsLoading = (0, _toolkit.createSelector)(selectRecordedVideosState, function (state) {
  return state.loading;
});
exports.selectRecordedVideosIsLoading = selectRecordedVideosIsLoading;
var selectRecordedVideosIsLoaded = (0, _toolkit.createSelector)(selectRecordedVideosState, function (state) {
  return state.loaded;
});
exports.selectRecordedVideosIsLoaded = selectRecordedVideosIsLoaded;

var selectRecordedVideoById = _reducer.recordedVideosEntityAdapter.getSelectors(selectRecordedVideosState).selectById;

exports.selectRecordedVideoById = selectRecordedVideoById;
var selectRecordedVideosLength = (0, _toolkit.createSelector)(selectRecordedVideosList, function (state) {
  return state.length;
});
exports.selectRecordedVideosLength = selectRecordedVideosLength;
var selectAccessedVideos = (0, _toolkit.createSelector)(_selectors.selectUserRights, selectRecordedVideosList, _selectors2.selectEventInfo, _selectors3.isStaffMember, _selectors4.isSpeaker, function (rights, videos, eventInfo, staffMember, isSpeaker) {
  var filteredVideos = [];
  if (!videos || videos.length === 0) return filteredVideos;
  if ((!rights || rights.length === 0) && !staffMember && !isSpeaker) return filteredVideos;
  var stageAccessIds = [];
  var dailyAccesses = [];
  rights.forEach(function (right) {
    right.selectedStages.forEach(function (s) {
      stageAccessIds.push(s.id);
    });
    right.selectedDays.forEach(function (s) {
      dailyAccesses.push((0, _momentTimezone.default)(s.name).tz(eventInfo.timezone, true).startOf('day'));
    });
  });
  videos.sort(function (a, b) {
    return a.type < b.type ? 1 : -1;
  }).forEach(function (video) {
    if (video.type && video.type === _recordedVideosContainer.RECORDING_TYPES.PRESENTATION) {
      var item = {
        id: video.id,
        name: video.name,
        date: video.createdDate.toDate(),
        noCategory: video.noCategory ? video.noCategory : '',
        displayOnLobby: video.displayOnLobby,
        loopVideo: video.loopVideo,
        type: _recordedVideosContainer.RECORDING_TYPES.PRESENTATION,
        url: video.downloadUrl
      };
      filteredVideos.push(item);
      return;
    }

    if (!video.type || [_recordedVideosContainer.RECORDING_TYPES.LIVE_RECORDED, _recordedVideosContainer.RECORDING_TYPES.PRE_RECORDED].includes(video.type)) {
      var _item = {
        id: video.id,
        name: video.session ? video.session.title : video.name,
        date: video.session ? video.session.startDateTime.seconds : video.createdDate.seconds,
        assetAccessId: video.assetAccessId,
        startDateTime: video.session ? video.session.startDateTime : null,
        endDateTime: video.session ? video.session.endDateTime : null,
        stageId: video.stageId,
        type: video.type,
        url: "https://stream.mux.com/".concat(video.assetAccessId, ".m3u8")
      };
      var today = (0, _momentTimezone.default)().tz(eventInfo.timezone).startOf('day');

      var videoDate = _momentTimezone.default.unix(_item.date).tz(eventInfo.timezone).startOf('day');

      if (today.diff(videoDate, 'days') <= 0) return;

      if (staffMember || isSpeaker) {
        filteredVideos.push(_item);
        return;
      }

      if (_item.stageId && !stageAccessIds.includes(_item.stageId)) return;
      var hasDailyAccess = dailyAccesses.some(function (s) {
        return s.diff(videoDate, 'days') === 0;
      });
      if (!hasDailyAccess) return;
      filteredVideos.push(_item);
    }
  });
  return filteredVideos;
});
exports.selectAccessedVideos = selectAccessedVideos;
var selectLobbyPresentationVideo = (0, _toolkit.createSelector)(selectRecordedVideosList, function (videos) {
  var lobbyVideo = videos.find(function (s) {
    return s.type === _recordedVideosContainer.RECORDING_TYPES.PRESENTATION && s.displayOnLobby;
  });
  return lobbyVideo;
});
exports.selectLobbyPresentationVideo = selectLobbyPresentationVideo;