var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Section = _interopRequireDefault(require("../../../../theme/components/section/Section"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _TextButton = _interopRequireDefault(require("../../../../theme/components/buttons/TextButton"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _myFilesPageStyles = _interopRequireDefault(require("../my-files-page-styles"));

var _fileCardStyles = _interopRequireDefault(require("./file-card-styles"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _hash = require("../../../../utils/hash");

var _selectors = require("../../../../core/user-profile/selectors");

var _selectors2 = require("../../../../core/user/selectors");

var FilesCardView = function FilesCardView(_ref) {
  var file = _ref.file;
  var userfb = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUser);
  var user = (0, _reactRedux.useSelector)(_selectors.selectUserProfile);
  var PageStyles = (0, _myFilesPageStyles.default)();
  var Styles = (0, _fileCardStyles.default)();

  var downloadFile = function downloadFile() {
    if (file.type === 1) {
      var w = window.open('', 'blank');
      var printHtml = "<html>\n        <head>\n            <style>\n                @media print {\n                    canvas {\n                        width: 100%;\n                        height: auto;\n                    }\n                }\n                @page {\n                    size: landscape;\n                    margin: 0;\n                }\n            </style>\n        </head>\n\n        <body>\n            <canvas id=\"myCanvas\"></canvas>\n            <script>\n            var canvas = document.getElementById(\"myCanvas\");\n            var context = canvas.getContext(\"2d\");\n            var imageObj = new Image();\n            imageObj.onload = function () {\n                canvas.height = imageObj.naturalHeight;\n                canvas.width = imageObj.naturalWidth;\n                context.drawImage(imageObj, 0, 0);\n                context.font = \"60pt Calibri\";\n                context.fillText(\"".concat((user === null || user === void 0 ? void 0 : user.displayName) || (user === null || user === void 0 ? void 0 : user.fullName), "\", ").concat(file.nameSectionCoord.x, ", ").concat(file.nameSectionCoord.y, ");\n                context.font = \"45pt Calibri\";\n                context.fillText(\"").concat((0, _hash.stringToHash)(userfb.uid), "\", ").concat(file.nrSectionCoord.x, ", ").concat(file.nrSectionCoord.y, ");\n                setTimeout(function () { document.execCommand(\"print\", false); }, 800);\n            };\n\n             imageObj.src = \"").concat(file.fileUrl, "\";\n            </script>\n        </body>\n        </html>\n      ");
      w.document.write(printHtml);
    }
  };

  return _react.default.createElement(_Section.default, {
    color: "containerBackground",
    shaped: true,
    shaping: "forms",
    style: [PageStyles.border, {
      maxWidth: 800
    }]
  }, _react.default.createElement(_Spacing.default, {
    direction: "row",
    wrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    style: Styles.container,
    mSpacing: ['l']
  }, _react.default.createElement(_Spacing.default, {
    flex: 1,
    direction: "column",
    alignItems: "flex-start"
  }, _react.default.createElement(_reactNative.View, {
    style: Styles.containerText
  }, _react.default.createElement(_core.Typography, {
    variant: "h2"
  }, file.name))), _react.default.createElement(_Spacing.default, null, _react.default.createElement(_TextButton.default, {
    iconRight: _react.default.createElement(_reactFeather.Download, {
      size: '0.875rem'
    }),
    onPress: downloadFile
  }, _react.default.createElement(_reactNative.View, {
    style: Styles.buttonText
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, _i18n.default.t('global.download')))))));
};

var _default = FilesCardView;
exports.default = _default;