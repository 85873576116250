var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actions = require("./actions");

var _constants = require("./constants");

var _createReducer;

var initialState = {
  loading: false,
  dialogs: _constants.dialogEntityAdapter.getInitialState()
};
var globalReducer = (0, _toolkit.createReducer)(initialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.startGlobalLoading, function (state) {
  state.loading = true;
}), (0, _defineProperty2.default)(_createReducer, _actions.stopGlobalLoading, function (state) {
  state.loading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.openDialog, function (state, _ref) {
  var payload = _ref.payload;

  _constants.dialogEntityAdapter.upsertOne(state.dialogs, payload);
}), (0, _defineProperty2.default)(_createReducer, _actions.closeDialog, function (state, _ref2) {
  var payload = _ref2.payload;

  _constants.dialogEntityAdapter.removeOne(state.dialogs, payload);
}), _createReducer));
var _default = globalReducer;
exports.default = _default;