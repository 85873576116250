var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _OutlinedButton = _interopRequireDefault(require("../../../../theme/components/buttons/OutlinedButton"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _reactNativePaper = require("react-native-paper");

var _reactGoogleCharts = require("react-google-charts");

var _reactRedux = require("react-redux");

var _actions = require("../../../../core/polls/actions");

var _selectors = require("../../../../core/polls/selectors");

var _pollsStyles = _interopRequireDefault(require("../polls-styles"));

var _selectors2 = require("../../../../core/user/selectors");

var sortedOptionKeys = function sortedOptionKeys(theOptionsObject) {
  var map = new Map(Object.entries(theOptionsObject));
  var orderedMap = new Map((0, _toConsumableArray2.default)(map).sort(function (_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 2),
        k = _ref3[0],
        v = _ref3[1];

    var _ref4 = (0, _slicedToArray2.default)(_ref2, 2),
        k2 = _ref4[0],
        v2 = _ref4[1];

    return v.createdAt > v2.createdAt ? 1 : -1;
  }));
  return Array.from(orderedMap.keys());
};

var SinglePollContainer = function SinglePollContainer(_ref5) {
  var selectedPollId = _ref5.selectedPollId,
      backToPollsList = _ref5.backToPollsList;
  var dispatch = (0, _reactRedux.useDispatch)();
  var Styles = (0, _pollsStyles.default)();
  var selectedPoll = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectPollById)(state, selectedPollId);
  });
  var firebaseUser = (0, _reactRedux.useSelector)(_selectors2.getFirebaseUser);
  var alreadyVoted = localStorage.getItem("".concat(selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.id).concat(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid));
  var optionKeys = selectedPoll.options ? sortedOptionKeys(selectedPoll.options) : [];
  var allVotes = 0;
  var data = optionKeys.map(function (option) {
    var votesOnOption = selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.options[option].votes;
    allVotes = allVotes + votesOnOption;
    return [option, votesOnOption];
  });
  data.unshift(['option', 'votes']);
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_OutlinedButton.default, {
    style: Styles.borderlessOutlineButton,
    color: "alternativeText",
    onPress: backToPollsList,
    spacing: 2,
    iconLeft: _react.default.createElement(_reactFeather.ArrowLeftCircle, null)
  }, _i18n.default.translate('polls.backToPollsList')), _react.default.createElement(_Spacing.default, {
    mSpacing: ['l', 0],
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    align: "center"
  }, selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.title)), _react.default.createElement(_Spacing.default, {
    pSpacing: "s",
    justifyContent: "center",
    shaping: "cards",
    alignItems: "stretch",
    direction: "column",
    color: "alternativeBackground"
  }, (selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.options) && optionKeys.map(function (option, index) {
    return _react.default.createElement(_Spacing.default, {
      key: "option-".concat(index + 1),
      direction: "row",
      shaping: "cards",
      color: alreadyVoted ? 'alternativeBackground' : 'containerBackground',
      mSpacing: ['xs', 0],
      justifyContent: "space-between"
    }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
      disabled: alreadyVoted ? true : false,
      borderless: true,
      onPress: function onPress() {
        localStorage.setItem("".concat(selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.id).concat(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid), true);
        dispatch((0, _actions.castVote)({
          pollId: selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.id,
          option: option
        }));
      },
      style: Styles.optionTitle
    }, _react.default.createElement(_core.Typography, {
      align: "center"
    }, index + 1, ". ", option)));
  })), alreadyVoted && _react.default.createElement(_Spacing.default, {
    justifyContent: "center",
    pSpacing: "xs"
  }, _react.default.createElement(_core.Typography, {
    variant: "overline",
    align: "center"
  }, '*', " ", _i18n.default.translate('polls.alreadyVoted'))), allVotes ? _react.default.createElement(_Spacing.default, {
    style: Styles.chartOuterContainer,
    justifyContent: "center"
  }, _react.default.createElement(_Spacing.default, {
    style: Styles.chartInnerContainer
  }, _react.default.createElement(_reactGoogleCharts.Chart, {
    chartTitle: selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.title,
    chartType: "PieChart",
    width: "100%",
    height: "250px",
    data: data,
    options: {
      is3D: 'true',
      title: selectedPoll === null || selectedPoll === void 0 ? void 0 : selectedPoll.title
    }
  }))) : _react.default.createElement(_Spacing.default, {
    justifyContent: "center",
    pSpacing: "l"
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    align: "center"
  }, _i18n.default.translate('polls.noVotesYet'))));
};

var _default = SinglePollContainer;
exports.default = _default;