var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoomAccessToken = getRoomAccessToken;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

function getRoomAccessToken(_x) {
  return _getRoomAccessToken.apply(this, arguments);
}

function _getRoomAccessToken() {
  _getRoomAccessToken = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee(_ref) {
    var room, authToken, res, _yield$res$json, data, status, error, message;

    return _regenerator.default.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            room = _ref.room, authToken = _ref.authToken;
            _context.next = 3;
            return fetch("".concat(process.env.NETWORKING_API_HOST || 'http://localhost:9596', "/accessToken"), {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                authToken: authToken,
                room: room
              })
            });

          case 3:
            res = _context.sent;
            _context.next = 6;
            return res.json();

          case 6:
            _yield$res$json = _context.sent;
            data = _yield$res$json.data;
            status = _yield$res$json.status;
            error = _yield$res$json.error;
            message = _yield$res$json.message;

            if (!(status !== 200)) {
              _context.next = 13;
              break;
            }

            throw {
              error: error,
              message: message
            };

          case 13:
            return _context.abrupt("return", data);

          case 14:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return _getRoomAccessToken.apply(this, arguments);
}