var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../theme/DesignSystem");

var BackstageCalendarNotice = function BackstageCalendarNotice(_ref) {
  var text = _ref.text;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  return _react.default.createElement(_Spacing.default, {
    direction: "row",
    flex: 1,
    pSpacing: "l",
    shaping: "cards",
    alignItems: "center",
    justifyContent: "space-between",
    style: {
      backgroundColor: colors.grayBackground
    }
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, text));
};

var _default = BackstageCalendarNotice;
exports.default = _default;