var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _actions = require("../../core/chat/actions");

var _selectors = require("../../core/chat/selectors");

var _TextButton = _interopRequireDefault(require("../../theme/components/buttons/TextButton"));

var _Spacing = _interopRequireDefault(require("../../theme/components/spacing/Spacing"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

function UnreadCounter(_ref) {
  var onScroll = _ref.onScroll,
      userDidScroll = _ref.userDidScroll;
  var unreadCount = (0, _reactRedux.useSelector)(_selectors.unreadMessagesSelector);
  var dispatch = (0, _reactRedux.useDispatch)();

  _react.default.useEffect(function () {
    if (!userDidScroll) {
      dispatch((0, _actions.resetUnreadCounter)());
    } else {
      dispatch((0, _actions.setAutoReadMode)(!userDidScroll));
    }
  }, [userDidScroll, dispatch]);

  return !!unreadCount && _react.default.createElement(_Spacing.default, {
    shaping: "buttons",
    color: "alternativeBackground"
  }, _react.default.createElement(_TextButton.default, {
    color: "alternativeText",
    variant: "description",
    spacing: "xs",
    iconLeft: _react.default.createElement(_reactFeather.ArrowDown, {
      size: '0.875rem'
    }),
    onPress: onScroll
  }, unreadCount > 9 ? '9+' : unreadCount, ' ', _i18n.default.translate(unreadCount > 1 ? 'chat.unreadMessages' : 'chat.unreadMessage')));
}

var _default = _react.default.memo(UnreadCounter);

exports.default = _default;