var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DeviceSettingsNotSupportedMessage;

var _react = _interopRequireDefault(require("react"));

var _bowser = _interopRequireDefault(require("bowser"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

function DeviceSettingsNotSupportedMessage() {
  var browser = _bowser.default.getParser(window.navigator.userAgent);

  return browser.getBrowserName() === 'Firefox' ? _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center",
    direction: "column",
    mSpacing: [15, 0, 0]
  }, _react.default.createElement(_core.Typography, {
    variant: "body1",
    color: "errorColor",
    align: "center"
  }, _i18n.default.t('audiovideoSettings.audioVideoSettingsFirefoxNotSupported'))) : null;
}