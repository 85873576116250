var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var _actionTypes2 = require("../user/actionTypes");

var _lodash = require("lodash");

var InitialState = _immutable.default.fromJS({
  gsAccessToken: null,
  gsDeviceId: null,
  gsPlayerId: null,
  properties: _immutable.default.Map()
});

InitialState = InitialState.set('leaderboard', []);

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.GS_INIT_COMPLETED:
      newState = newState.set('gsAccessToken', action.authToken);
      newState = newState.set('gsDeviceId', action.gsDeviceId);
      newState = newState.set('gsPlayerId', action.playerId);
      break;

    case _actionTypes.GS_ERROR:
      newState = newState.set('gsError', action.error);
      break;

    case _actionTypes2.USER_LOGOUT:
      newState = InitialState;
      break;

    case _actionTypes.IS_GS_PROPERTY_LOADING:
      newState = newState.set('isGsPropertyLoading', action.loading);
      break;

    case _actionTypes.GS_PROPERTY_COMPLETED:
      newState = newState.set('isGsPropertyLoading', false);
      newState = newState.setIn(['properties', action.propName], action.value);
      break;

    case _actionTypes.IS_GS_PROPERTY_SET_LOADING:
      newState = newState.set('isGsPropertySetLoading', action.loading);
      break;

    case _actionTypes.GS_PROPERTY_SET_COMPLETED:
      newState = newState.set('isGsPropertySetLoading', false);
      newState = newState.setIn(['properties', action.propName], _immutable.default.fromJS(action.propertySet));
      break;

    case _actionTypes.IS_PLAYER_LOADING:
      newState = newState.set('isPlayerDataLoading', action.loading);
      break;

    case _actionTypes.LOAD_PLAYER_COMPLETED:
      newState = newState.set('isPlayerDataLoading', false);
      newState = newState.set('beatsBalance', action.data.currencies.BEAT);

      if (action.data.scriptData) {
        newState = newState.set('apiUserId', action.data.scriptData.userId);
        newState = newState.set('totalBalance', _immutable.default.fromJS(action.data.scriptData.TotalBeats));

        if (action.data.scriptData.leaderboardData) {
          newState = newState.set('leaderboardPlayerData', _immutable.default.fromJS(action.data.scriptData.leaderboardData));
        } else {
          newState = newState.set('leaderboardPlayerData', _immutable.default.fromJS({}));
        }
      }

      break;

    case _actionTypes.IS_SPECIFIC_PLAYER_LOADING:
      newState = newState.set('isSpecificPlayerLoading', action.loading);
      break;

    case _actionTypes.LOAD_SPECIFIC_PLAYER_COMPLETED:
      newState = newState.set('isSpecificPlayerLoading', false);

      if (action.data.scriptData) {
        var _action$data$scriptDa = action.data.scriptData,
            playerId = _action$data$scriptDa.playerId,
            totalBeats = _action$data$scriptDa.totalBeats,
            beatsBalance = _action$data$scriptDa.beatsBalance,
            playerData = _action$data$scriptDa.playerData;
        newState = newState.setIn([playerId, 'beatsBalance'], beatsBalance);
        newState = newState.setIn([playerId, 'totalBeats'], totalBeats);
        newState = newState.setIn([playerId, 'playerData'], _immutable.default.fromJS(playerData));
      }

      break;

    case _actionTypes.IS_LEADERBOARD_ENTRY_LOADING:
      newState = newState.set('isLeaderboardEntryLoading', action.loading);
      newState = newState.set('leaderboardEntry', _immutable.default.fromJS({}));
      break;

    case _actionTypes.LOAD_LEADERBOARD_ENTRY_COMPLETED:
      newState = newState.set('leaderboardEntry', _immutable.default.fromJS(action.data));
      newState = newState.set('isLeaderboardEntryLoading', false);
      break;

    case _actionTypes.IS_LEADERBOARD_LOADING:
      newState = newState.set('isLeaderboardLoading', action.loading);
      break;

    case _actionTypes.LOAD_LEADERBOARD_COMPLETED:
      newState = newState.set('leaderboard', (0, _lodash.unionBy)(action.reset ? [] : state.get('leaderboard'), action.leaderboard, 'rank'));

      if (action.total) {
        newState = newState.set('leaderboardTotal', action.total);
      }

      newState = newState.set('isLastMonthLeaderboard', !!action.isFromLastMonth);
      newState = newState.set('isLeaderboardLoading', false);
      break;

    case _actionTypes.LOAD_AROUND_ME_COMPLETED:
      if (action.aroundMeList && action.aroundMeList.length > 0) {
        action.aroundMeList[0].isFirstAroundMe = true;
      }

      newState = newState.set('leaderboard', (0, _lodash.unionBy)(action.leaderboard, action.aroundMeList, 'rank'));

      if (action.total) {
        newState = newState.set('leaderboardTotal', action.total);
      }

      newState = newState.set('isLastMonthLeaderboard', !!action.isFromLastMonth);
      newState = newState.set('isLeaderboardLoading', false);
      break;

    default:
      break;
  }

  return newState;
}