var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _DynamicSection = _interopRequireDefault(require("../../../../theme/components/section/DynamicSection"));

var _Typography = _interopRequireDefault(require("../../../../theme/components/typography/Typography"));

var _liveSessionStyles = _interopRequireDefault(require("../live-session-styles"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _colorUtils = _interopRequireDefault(require("../../../../utils/color-utils"));

var _DefaultGifBackground = _interopRequireDefault(require("../../../protected-pages/live-page/components/DefaultGifBackground"));

var _DesignSystem = require("../../../../theme/DesignSystem");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _ColoredCircularProgress = _interopRequireDefault(require("../../../../theme/components/loaders/ColoredCircularProgress"));

var WaitingForStreamView = function WaitingForStreamView(_ref) {
  var _ref$role = _ref.role,
      role = _ref$role === void 0 ? 'backstage' : _ref$role;
  var styles = (0, _liveSessionStyles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  return _react.default.createElement(_Spacing.default, {
    mSpacing: "s",
    style: {
      overflow: 'hidden',
      borderRadius: spacing.s
    }
  }, _react.default.createElement(_DefaultGifBackground.default, null, _react.default.createElement(_reactNative.View, {
    style: styles.absoluteContainer
  }, _react.default.createElement(_DynamicSection.default, {
    shaped: true,
    shaping: "cards",
    lightColor: "#FFFFFF",
    darkColor: "#000000",
    parentBackground: (0, _colorUtils.default)('#FFFFFF', '#FFFFFF', '#000000'),
    style: styles.innerContainer
  }, function (innerTextColor) {
    return _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "center"
    }, _react.default.createElement(_Typography.default, {
      variant: "paragraph",
      color: innerTextColor,
      textAlign: "center",
      style: styles.textInfo
    }, _i18n.default.t(role === 'backstage' ? 'liveNow.waitingForStreamStart' : 'liveNow.waitingForParticipants')), _react.default.createElement(_ColoredCircularProgress.default, {
      size: 30
    }));
  }))));
};

var _default = WaitingForStreamView;
exports.default = _default;