var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _CustomIcon = _interopRequireDefault(require("../../../assets/icons/CustomIcon"));

var _messageBlockStyles = _interopRequireDefault(require("./message-block-styles"));

var _reactNative = require("react-native");

var MessageBlock = function MessageBlock(_ref) {
  var children = _ref.children,
      _ref$level = _ref.level,
      level = _ref$level === void 0 ? 'info' : _ref$level;
  return _react.default.createElement(_reactNative.View, {
    style: [_messageBlockStyles.default.container, {
      backgroundColor: level === 'info' && '#0C9DF5' || level === 'warning' && '#FF0000'
    }]
  }, _react.default.createElement(_CustomIcon.default, {
    size: 24,
    color: "white",
    name: level === 'info' && 'info-outline' || level === 'warning' && 'alert-triangle'
  }), _react.default.createElement(_reactNative.Text, {
    style: _messageBlockStyles.default.text
  }, children));
};

var _default = MessageBlock;
exports.default = _default;