var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasRemainedParticipants;

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _CircleGroup = _interopRequireDefault(require("./CircleGroup"));

function CanvasRemainedParticipants(_ref) {
  var count = _ref.count,
      height = _ref.height,
      width = _ref.width;
  return _react.default.createElement(_reactKonva.Group, {
    width: width,
    height: height
  }, _react.default.createElement(_reactKonva.Rect, {
    width: width,
    height: height,
    fill: "black"
  }), _react.default.createElement(_CircleGroup.default, {
    x: width / 4,
    y: height / 4,
    height: height / 2,
    width: width / 2
  }, _react.default.createElement(_reactKonva.Text, {
    width: width / 2,
    height: height / 2,
    align: "center",
    verticalAlign: "middle",
    fontSize: height / 4,
    fill: "black",
    text: "+".concat(count)
  })));
}