var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _absoluteSpinner = _interopRequireDefault(require("../../../components/common/absolute-spinner"));

var _logger = _interopRequireDefault(require("../../../core/services/logger"));

var _user = _interopRequireDefault(require("../../../core/user"));

var _Redirect = _interopRequireDefault(require("../Redirect"));

var _routesConsts = require("../routes-consts");

var AuthGuard = function AuthGuard(_ref) {
  var children = _ref.children;
  var isLoggedIn = (0, _reactRedux.useSelector)(_user.default.selectors.isFirebaseUserLoggedIn);
  var isLoading = (0, _reactRedux.useSelector)(_user.default.selectors.isLoading);

  if (isLoading) {
    return _react.default.createElement(_absoluteSpinner.default, null);
  }

  if (isLoggedIn) {
    return children;
  }

  _logger.default.debug('[AuthGuard] Redirect to HOME!');

  return _react.default.createElement(_Redirect.default, {
    to: _routesConsts.Routes.HOME
  });
};

var _default = AuthGuard;
exports.default = _default;