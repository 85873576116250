Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectPrivacyPolicy = exports.selectTermsAndConditions = exports.selectLegalInformationsIsLoaded = exports.selectLegalInformationsIsLoading = exports.selectLegalInformations = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectLegalInformationsState = function selectLegalInformationsState(state) {
  return state[_constants.NAME];
};

var selectLegalInformations = _reducer.legalInformationsEntityAdapter.getSelectors(selectLegalInformationsState).selectAll;

exports.selectLegalInformations = selectLegalInformations;
var selectLegalInformationsIsLoading = (0, _toolkit.createSelector)(selectLegalInformationsState, function (state) {
  return state.loading;
});
exports.selectLegalInformationsIsLoading = selectLegalInformationsIsLoading;
var selectLegalInformationsIsLoaded = (0, _toolkit.createSelector)(selectLegalInformationsState, function (state) {
  return state.loaded;
});
exports.selectLegalInformationsIsLoaded = selectLegalInformationsIsLoaded;
var selectTermsAndConditions = (0, _toolkit.createSelector)(selectLegalInformations, function (legalInformations) {
  return legalInformations.find(function (s) {
    return s.type === 'terms-and-conditions';
  });
});
exports.selectTermsAndConditions = selectTermsAndConditions;
var selectPrivacyPolicy = (0, _toolkit.createSelector)(selectLegalInformations, function (legalInformations) {
  return legalInformations.find(function (s) {
    return s.type === 'privacy-policy';
  });
});
exports.selectPrivacyPolicy = selectPrivacyPolicy;