var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _scrollListener = _interopRequireDefault(require("../../common/header/scroll-listener"));

var LandingPageScroll = function LandingPageScroll(_ref) {
  var children = _ref.children;

  var ref = _react.default.createRef();

  _react.default.useEffect(function () {
    _scrollListener.default.setScrollTo(ref.current.scrollTo);
  }, []);

  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%'
    },
    onLayout: function onLayout(_ref2) {
      var _ref2$nativeEvent$lay = _ref2.nativeEvent.layout,
          y = _ref2$nativeEvent$lay.y,
          height = _ref2$nativeEvent$lay.height;
      return _scrollListener.default.onContainerLayout(y, height / 4);
    },
    scrollEventThrottle: 16,
    onScroll: function onScroll(_ref3) {
      var _ref3$nativeEvent = _ref3.nativeEvent,
          y = _ref3$nativeEvent.contentOffset.y,
          fullHeight = _ref3$nativeEvent.contentSize.height,
          height = _ref3$nativeEvent.layoutMeasurement.height;

      _scrollListener.default.onScroll(y + height / 4, y + height === fullHeight);
    },
    ref: ref
  }, children);
};

var _default = LandingPageScroll;
exports.default = _default;