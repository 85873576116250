var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ConfigureTicketStep;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _eventRegisterProvider = require("../event-register-provider");

var _checkoutItemsList = _interopRequireDefault(require("../event-registration-components/common/checkout-items-list"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _core = require("@material-ui/core");

var useTicketListViewStyles = (0, _core.makeStyles)(function (theme) {
  return {
    bold: {
      fontWeight: 'bold'
    },
    container: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      borderRadius: '10px',
      flex: 'none',
      order: 1,
      alignSelf: 'stretch',
      flexGrow: 0
    }, theme.breakpoints.up('sm'), {
      width: '100%',
      flexDirection: 'row'
    }),
    ticketInfoContainerWithDescription: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '50%'
    }, theme.breakpoints.down('sm'), {
      width: '100%'
    }),
    ticketInfoContainerWithoutDescription: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '50%'
    }, theme.breakpoints.down('sm'), {
      width: '100%'
    }),
    title: {
      display: 'flex',
      alignItems: 'center'
    },
    owned: {
      backgroundColor: 'lightgreen',
      padding: 5,
      borderRadius: 4,
      marginLeft: 15,
      textTransform: 'uppercase'
    },
    descriptionPriceContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    description: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '70%',
      textTransform: 'lowercase'
    }, theme.breakpoints.up('sm'), {
      width: '100%'
    }),
    input: (0, _defineProperty2.default)({
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }, theme.breakpoints.up('sm'), {
      justifyContent: 'flex-end',
      gap: 20,
      marginRight: 50
    }),
    price: {
      width: 150,
      textAlign: 'end',
      fontWeight: 'bold'
    }
  };
});

function ConfigureTicketStep() {
  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      tickets = _useEventRegisterCont.availableEventTickets;

  return _react.default.createElement(_checkoutItemsList.default, {
    checkoutItems: tickets,
    isTogglable: false
  }, _react.default.createElement(TicketContainer, null));
}

function TicketContainer(_ref) {
  var ticket = _ref.checkoutItem;

  var _useEventRegisterCont2 = (0, _eventRegisterProvider.useEventRegisterContext)(),
      selectedTicketId = _useEventRegisterCont2.selectedTicketId,
      handleTicketQuantity = _useEventRegisterCont2.handleTicketQuantity,
      ticketQuantity = _useEventRegisterCont2.ticketQuantity,
      handleChange = _useEventRegisterCont2.onSelectedTicketChange;

  var classes = useTicketListViewStyles();
  var theme = (0, _core.useTheme)();
  var maxTicketQuantity = ticket.quantity || 30;
  var ticketPrice = ticket.parsedPriceLabel;
  var match = (0, _core.useMediaQuery)(theme.breakpoints.down('sm'));

  var renderTicketPrice = function renderTicketPrice() {
    return _react.default.createElement(_core.Typography, {
      className: classes.price
    }, ticketPrice);
  };

  return _react.default.createElement(_core.Box, {
    className: classes.container,
    onClick: function onClick() {
      return handleChange(ticket.id);
    }
  }, _react.default.createElement(_core.Box, {
    className: ticket.description ? classes.ticketInfoContainerWithDescription : classes.ticketInfoContainerWithoutDescription
  }, _react.default.createElement(_core.Box, {
    className: classes.title,
    mb: 1
  }, _react.default.createElement(_core.Typography, {
    className: classes.bold
  }, ticket.name), ticket.alreadyOwned && _react.default.createElement(_core.Box, {
    className: classes.owned
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, _i18n.default.translate('configureTicket.owned')))), _react.default.createElement(_core.Box, {
    className: classes.descriptionPriceContainer
  }, _react.default.createElement(_core.Typography, {
    variant: "caption",
    className: classes.description
  }, ticket.description), match && renderTicketPrice())), _react.default.createElement(_core.Box, {
    className: classes.input
  }, selectedTicketId === ticket.id && _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Typography, {
    variant: "overline"
  }, _i18n.default.translate('configureTicket.quantity')), _react.default.createElement(_core.TextField, {
    id: "outlined-number",
    type: "number",
    InputProps: {
      inputProps: {
        min: 0,
        max: maxTicketQuantity
      },
      style: {
        textAlign: 'center'
      }
    },
    InputLabelProps: {
      shrink: true
    },
    variant: "outlined",
    value: ticketQuantity,
    onChange: function onChange(event) {
      var quantity = parseInt(event.target.value, 10);
      if (quantity > maxTicketQuantity) quantity = maxTicketQuantity;
      if (quantity < 1) quantity = 1;
      handleTicketQuantity(quantity);
    },
    defaultValue: 1
  }))), !match && renderTicketPrice());
}