Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.promoCodeEntityAdapter = exports.eventTicketEntityAdapter = exports.CREATE_FREE_UNASSIGNED_TICKETS = exports.FINISH_EVENT_REGISTRATION = exports.FINISH_CHECKOUT_PAID_TICKET = exports.START_CHECKOUT_PAID_TICKET = exports.SET_PROMO_CODE = exports.REMOVE_CHECKOUT_INTENT = exports.FINISH_CHECKOUT = exports.START_CHECKOUT = exports.RESET_SELECTED_ADDONS_VARIATIONS = exports.SET_SELECTED_ADDONS_VARIATIONS = exports.SET_CHECKOUT_AUTH_NEEDED = exports.GET_PROMO_CODES = exports.GET_EVENT_TICKETS = exports.CHECKOUT_INTENT_NAME = exports.NAME = void 0;

var _toolkit = require("@reduxjs/toolkit");

var NAME = 'checkout';
exports.NAME = NAME;
var CHECKOUT_INTENT_NAME = 'checkoutIntent';
exports.CHECKOUT_INTENT_NAME = CHECKOUT_INTENT_NAME;
var GET_EVENT_TICKETS = 'checkout/GET_EVENT_TICKETS';
exports.GET_EVENT_TICKETS = GET_EVENT_TICKETS;
var GET_PROMO_CODES = 'checkout/GET_PROMO_CODES';
exports.GET_PROMO_CODES = GET_PROMO_CODES;
var SET_CHECKOUT_AUTH_NEEDED = 'checkout/SET_CHECKOUT_AUTH_NEEDED';
exports.SET_CHECKOUT_AUTH_NEEDED = SET_CHECKOUT_AUTH_NEEDED;
var SET_SELECTED_ADDONS_VARIATIONS = 'checkout/SET_SELECTED_ADDONS_VARIATIONS';
exports.SET_SELECTED_ADDONS_VARIATIONS = SET_SELECTED_ADDONS_VARIATIONS;
var RESET_SELECTED_ADDONS_VARIATIONS = 'checkout/RESET_SELECTED_ADDONS_VARIATIONS';
exports.RESET_SELECTED_ADDONS_VARIATIONS = RESET_SELECTED_ADDONS_VARIATIONS;
var START_CHECKOUT = 'checkout/START_CHECKOUT';
exports.START_CHECKOUT = START_CHECKOUT;
var FINISH_CHECKOUT = 'checkout/FINISH_CHECKOUT';
exports.FINISH_CHECKOUT = FINISH_CHECKOUT;
var REMOVE_CHECKOUT_INTENT = 'checkoutIntent/REMOVE_CHECKOUT_INTENT';
exports.REMOVE_CHECKOUT_INTENT = REMOVE_CHECKOUT_INTENT;
var SET_PROMO_CODE = 'checkoutIntent/SET_PROMO_CODE';
exports.SET_PROMO_CODE = SET_PROMO_CODE;
var START_CHECKOUT_PAID_TICKET = 'checkout/START_CHECKOUT_PAID_TICKET';
exports.START_CHECKOUT_PAID_TICKET = START_CHECKOUT_PAID_TICKET;
var FINISH_CHECKOUT_PAID_TICKET = 'finish/CONTIUNE_CHECKOUT_PAID_TICKET';
exports.FINISH_CHECKOUT_PAID_TICKET = FINISH_CHECKOUT_PAID_TICKET;
var FINISH_EVENT_REGISTRATION = 'checkout/FINISH_EVENT_REGISTRATION';
exports.FINISH_EVENT_REGISTRATION = FINISH_EVENT_REGISTRATION;
var CREATE_FREE_UNASSIGNED_TICKETS = 'checkout/CREATE_FREE_UNASSIGNED_TICKETS';
exports.CREATE_FREE_UNASSIGNED_TICKETS = CREATE_FREE_UNASSIGNED_TICKETS;
var eventTicketEntityAdapter = (0, _toolkit.createEntityAdapter)({
  selectId: function selectId(entity) {
    return entity.id;
  },
  sortComparer: function sortComparer(a, b) {
    return a.sort - b.sort;
  }
});
exports.eventTicketEntityAdapter = eventTicketEntityAdapter;
var promoCodeEntityAdapter = (0, _toolkit.createEntityAdapter)({
  selectId: function selectId(entity) {
    return entity.id;
  }
});
exports.promoCodeEntityAdapter = promoCodeEntityAdapter;