var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../core/letter/selectors");

var _letterView = _interopRequireDefault(require("./letter-view"));

var LetterContainer = function LetterContainer() {
  var letters = (0, _reactRedux.useSelector)(_selectors.selectLetters);
  return _react.default.createElement(_react.default.Fragment, null, Object.keys(letters).map(function (key, index) {
    return _react.default.createElement(_letterView.default, (0, _extends2.default)({}, letters[key], {
      key: index
    }));
  }));
};

var _default = LetterContainer;
exports.default = _default;