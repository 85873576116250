var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHighestRole = getHighestRole;
exports.formatRole = formatRole;

var _roleConstants = require("../constants/role-constants");

var _startCase = _interopRequireDefault(require("lodash/startCase"));

function getHighestRole() {
  var roles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  for (var role in _roleConstants.USER_ROLES_HIERARCHY) {
    if (roles[role]) return formatRole(role);
  }

  return _roleConstants.USER_ROLES.AUDIENCE;
}

function formatRole(role) {
  return (0, _startCase.default)(role);
}