Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _spacing = require("../../../../design-system/spacing");

var _default = _reactNative.StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: _spacing.Spacing.loose,
    paddingBottom: _spacing.Spacing.loose,
    paddingRight: _spacing.Spacing.extraLoose,
    paddingLeft: _spacing.Spacing.extraLoose
  },
  text: {
    fontSize: 18,
    color: 'white',
    marginLeft: _spacing.Spacing.base
  }
});

exports.default = _default;