Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNativeMediaQueries = require("react-native-media-queries");

var base = {
  container: {
    alignItems: 'center',
    justifyContent: 'center'
  }
};
var Styles = (0, _reactNativeMediaQueries.createStyles)(base);
var _default = Styles;
exports.default = _default;