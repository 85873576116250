var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _actionTypes = require("../user/actionTypes");

var _actions = require("./actions");

var _constants = require("./constants");

var _createReducer;

var InitialState = {
  registrationLoading: false,
  registrationError: null,
  registrationProgress: 0,
  resetPasswordLoading: false,
  resetPasswordError: null,
  loginLoading: false,
  loginError: null,
  loginPhoneLoading: false,
  loginPhoneError: null,
  showMailSentAuthMessage: false,
  resetPasswordActionLoading: false,
  resetPasswordActionLoaded: false,
  resetPasswordActionError: null,
  validResetCode: false,
  resetConfirmError: null,
  resetActionSucceeded: false
};
var reducer = (0, _toolkit.createReducer)(InitialState, (_createReducer = {}, (0, _defineProperty2.default)(_createReducer, _actions.userRegistration.pending, function (state) {
  state.registrationLoading = true;
  state.registrationError = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.userRegistration.fulfilled, function (state) {
  state.registrationLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.userRegistration.rejected, function (state, _ref) {
  var error = _ref.error;
  state.registrationError = (error === null || error === void 0 ? void 0 : error.code) || 'errors.somethingWentWrong';
  state.registrationLoading = false;
  state.registrationProgress = 0;
}), (0, _defineProperty2.default)(_createReducer, _actions.setRegistrationProgress.type, function (state, _ref2) {
  var payload = _ref2.payload;
  state.registrationProgress = payload;
}), (0, _defineProperty2.default)(_createReducer, _constants.REMOVE_LOGIN_ERROR, function (state) {
  state.loginError = null;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_LOGIN_IN_PROGRESS, function (state) {
  state.loginLoading = true;
  state.loginError = null;
  state.showMailSentAuthMessage = false;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_LOGIN_MAIL_SENT, function (state) {
  state.loginLoading = false;
  state.loginError = null;
  state.showMailSentAuthMessage = true;
}), (0, _defineProperty2.default)(_createReducer, _constants.REMOVE_LOGIN_MAIL_MESSAGE, function (state) {
  state.showMailSentAuthMessage = false;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_LOGIN_COMPLETED, function (state) {
  state.loginLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_LOGIN_FAILED, function (state, _ref3) {
  var error = _ref3.error;
  state.loginError = (error === null || error === void 0 ? void 0 : error.code) || 'errors.somethingWentWrong';
  state.loginLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_USER_DOES_NOT_EXIST_ERROR, function (state, _ref4) {
  var error = _ref4.error;
  state.loginError = error || 'errors.somethingWentWrong';
  state.loginLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.resetPassword.pending, function (state) {
  state.resetPasswordLoading = true;
  state.resetPasswordError = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.resetPassword.fulfilled, function (state) {
  state.resetPasswordLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.resetPassword.rejected, function (state, _ref5) {
  var error = _ref5.error;
  state.resetPasswordError = (error === null || error === void 0 ? void 0 : error.code) || 'errors.somethingWentWrong';
  state.resetPasswordLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _constants.REMOVE_RESET_PASSWORD_ERROR, function (state) {
  state.resetPasswordError = null;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_PHONE_AUTH_PROGRESS, function (state) {
  state.loginPhoneLoading = true;
  state.loginPhoneError = null;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_PHONE_AUTH_COMPLETED, function (state) {
  state.loginPhoneLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actionTypes.FIREBASE_PHONE_AUTH_FAILED, function (state, action) {
  state.loginPhoneError = action.error;
  state.loginPhoneLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.verifyPasswordResetCode.pending, function (state) {
  state.resetPasswordActionLoading = true;
  state.resetPasswordActionError = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.verifyPasswordResetCode.fulfilled, function (state, _ref6) {
  var payload = _ref6.payload;
  state.resetPasswordActionLoading = false;
  state.validResetCode = payload;
}), (0, _defineProperty2.default)(_createReducer, _actions.verifyPasswordResetCode.rejected, function (state, _ref7) {
  var error = _ref7.error;
  state.resetPasswordActionError = (error === null || error === void 0 ? void 0 : error.code) || 'errors.somethingWentWrong';
  state.resetPasswordActionLoading = false;
}), (0, _defineProperty2.default)(_createReducer, _actions.launchPasswordResetAction.pending, function (state) {
  state.resetPasswordActionLoading = true;
  state.resetConfirmError = null;
}), (0, _defineProperty2.default)(_createReducer, _actions.launchPasswordResetAction.fulfilled, function (state, _ref8) {
  var payload = _ref8.payload;
  state.resetPasswordActionLoading = false;
  state.resetActionSucceeded = payload.success;
  state.resetConfirmError = payload.error;
}), (0, _defineProperty2.default)(_createReducer, _actions.launchPasswordResetAction.rejected, function (state, _ref9) {
  var _payload$error;

  var payload = _ref9.payload;
  state.resetConfirmError = ((_payload$error = payload.error) === null || _payload$error === void 0 ? void 0 : _payload$error.code) || 'errors.somethingWentWrong';
  state.resetPasswordActionLoading = false;
}), _createReducer));
var _default = reducer;
exports.default = _default;