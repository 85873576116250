var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = KickOutLiveParticipant;
exports.useStyles = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _featureNamesConstants = require("../../../constants/feature-names-constants");

var _selectors = require("../../../core/user-rooms/selectors");

var _actions = require("../../../core/user-rooms/actions");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _DesignSystem = require("../../../theme/DesignSystem");

var _useUserBackstageFeatures = _interopRequireDefault(require("../../../utils/hooks/useUserBackstageFeatures"));

var _KickOutButton = _interopRequireDefault(require("./KickOutButton"));

var _toaster = _interopRequireDefault(require("../toaster/toaster"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _roleConstants = require("../../../constants/role-constants");

var _ConfirmationDialog = _interopRequireDefault(require("../../../theme/components/dialog/ConfirmationDialog"));

var useStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    kickOutButton: {
      position: 'absolute',
      zIndex: 10,
      bottom: 5,
      right: 5
    },
    button: {
      marginLeft: theme.spacing.s
    }
  };
}).buildHook();
exports.useStyles = useStyles;
var ALLOWED_FEATURES = [_featureNamesConstants.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS, _featureNamesConstants.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_SESSION, _featureNamesConstants.SHOW_KICK_OUT_SPEAKER_FROM_LIVE_UI_COMPONENTS_BY_STAGE];

function KickOutLiveParticipant(_ref) {
  var id = _ref.id,
      role = _ref.role,
      name = _ref.name;
  var dispatch = (0, _reactRedux.useDispatch)();

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      isLoading = _React$useState2[0],
      setIsLoading = _React$useState2[1];

  var _React$useState3 = _react.default.useState(false),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      isToasterVisible = _React$useState4[0],
      setIsToasterVisible = _React$useState4[1];

  var styles = useStyles();
  var backstageId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.BACKSTAGE_ID);
  var requestsEnabled = (0, _useUserBackstageFeatures.default)(backstageId, ALLOWED_FEATURES);
  var userIsInLiveRoom = (0, _reactRedux.useSelector)((0, _selectors.userIsInLiveParticipantsSelector)(backstageId));

  var handleKickOut = function () {
    var _ref2 = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      var resultAction;
      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              setIsLoading(true);
              _context.next = 3;
              return dispatch((0, _actions.kickSpeakerFromLive)({
                roomId: backstageId,
                speakerId: id
              }));

            case 3:
              resultAction = _context.sent;

              if (_actions.kickSpeakerFromLive.rejected.match(resultAction)) {
                setIsToasterVisible(true);
              }

              setIsLoading(false);

            case 6:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function handleKickOut() {
      return _ref2.apply(this, arguments);
    };
  }();

  var handleToasterClose = function handleToasterClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setIsToasterVisible(false);
  };

  return backstageId && requestsEnabled && userIsInLiveRoom && role === _roleConstants.USER_ROLES.SPEAKER ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_ConfirmationDialog.default, {
    title: _i18n.default.translate('backstage.kickUserFromLive', {
      name: name
    }),
    yes: _i18n.default.translate('backstage.kick'),
    no: _i18n.default.translate('backstage.cancel'),
    content: _i18n.default.translate('backstage.kickUserInfo', {
      name: name
    }),
    onConfirm: handleKickOut
  }, function (open) {
    return _react.default.createElement(_KickOutButton.default, {
      isLoading: isLoading,
      onClick: open,
      disabled: isLoading,
      style: styles.kickOutButton
    });
  }), _react.default.createElement(_toaster.default, {
    visible: isToasterVisible,
    onClose: handleToasterClose,
    duration: 5000,
    text: _i18n.default.translate('backstage.error.kickOutSpeakerFailed')
  })) : null;
}