var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EDGE = exports.SAFARI = exports.CHROME = exports.FIREFOX = exports.UnknownOSResponse = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var style = {
  fontFamily: 'FontAwesome',
  fontSize: 40,
  paddingHorizontal: 20
};

var UnknownOSResponse = _react.default.createElement(_Spacing.default, {
  pSpacing: ['m', 0]
}, _react.default.createElement(_core.Typography, {
  align: "center"
}, _i18n.default.translate('networking.unknownOS')));

exports.UnknownOSResponse = UnknownOSResponse;

var FIREFOX = _react.default.createElement(_Spacing.default, {
  direction: "column"
}, _react.default.createElement(_reactNative.Text, {
  style: style
}, "\uF269"), _react.default.createElement(_core.Typography, {
  variant: "caption"
}, "Firefox"));

exports.FIREFOX = FIREFOX;

var CHROME = _react.default.createElement(_Spacing.default, {
  direction: "column"
}, _react.default.createElement(_reactNative.Text, {
  style: style
}, "\uF268"), _react.default.createElement(_core.Typography, {
  variant: "caption"
}, "Chrome"));

exports.CHROME = CHROME;

var SAFARI = _react.default.createElement(_Spacing.default, {
  direction: "column"
}, _react.default.createElement(_reactNative.Text, {
  style: style
}, "\uF267"), _react.default.createElement(_core.Typography, {
  variant: "caption"
}, "Safari"));

exports.SAFARI = SAFARI;

var EDGE = _react.default.createElement(_Spacing.default, {
  direction: "column"
}, _react.default.createElement(_reactNative.Text, {
  style: style
}, "\uF282"), _react.default.createElement(_core.Typography, {
  variant: "caption"
}, "Edge"));

exports.EDGE = EDGE;