Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useBoothPageStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginLeft: theme.spacing.leftMenu,
      flex: 1
    },
    videoContainer: {
      alignItems: 'center',
      paddingBottom: '56.25%',
      height: 0,
      overflow: 'hidden',
      position: 'relative'
    },
    videoContent: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    videoReplacementImage: {
      width: '100%',
      height: '100%'
    },
    sponsorLogo: {
      marginRight: theme.spacing.m,
      minWidth: 200,
      minHeight: 100
    },
    videoLayout: {
      marginHorizontal: theme.spacing.ml,
      marginVertical: theme.spacing.s
    },
    logoHeadingContainer: {
      marginTop: theme.spacing.s
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: {
      marginLeft: 0
    },
    videoLayout: {
      marginHorizontal: theme.spacing.s
    },
    videoContainer: {
      order: 2,
      margin: theme.spacing.s
    },
    logoHeadingContainer: {
      order: 1,
      marginBottom: 0,
      marginTop: 0,
      marginHorizontal: theme.spacing.s
    },
    menuContainer: {
      alignItems: 'stretch',
      flexDirection: 'column',
      order: 3
    },
    about: {
      order: 4,
      margin: theme.spacing.s
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.smd;
}, function () {
  return {
    container: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1
    },
    videoLayout: {
      marginHorizontal: 0
    }
  };
}).buildHook();
var _default = useBoothPageStyles;
exports.default = _default;