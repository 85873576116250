Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NETWORKING_AREA = exports.BREAK = exports.NETWORKING = exports.LECTURE = void 0;
var LECTURE = 'LECTURE';
exports.LECTURE = LECTURE;
var NETWORKING = 'NETWORKING';
exports.NETWORKING = NETWORKING;
var BREAK = 'BREAK';
exports.BREAK = BREAK;
var NETWORKING_AREA = 'Networking area';
exports.NETWORKING_AREA = NETWORKING_AREA;