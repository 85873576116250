var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasParticipant;

var _react = _interopRequireDefault(require("react"));

var _stringFormatter = require("../../../../utils/string-formatter");

var _CanvasAvatar = _interopRequireDefault(require("./CanvasAvatar"));

var _CanvasIcon = _interopRequireDefault(require("./CanvasIcon"));

var _CanvasVideo = _interopRequireDefault(require("./CanvasVideo"));

var _CircleGroup = _interopRequireDefault(require("./CircleGroup"));

var _CanvasNameStrip = _interopRequireDefault(require("./CanvasNameStrip"));

var _CanvasRoleStrip = _interopRequireDefault(require("./CanvasRoleStrip"));

var _CanvasReconnecting = _interopRequireDefault(require("./CanvasReconnecting"));

function CanvasParticipant(_ref) {
  var name = _ref.name,
      role = _ref.role,
      picture = _ref.picture,
      _ref$grid = _ref.grid,
      height = _ref$grid.height,
      width = _ref$grid.width,
      hasAudio = _ref.hasAudio,
      hasParticipant = _ref.hasParticipant,
      videoTrack = _ref.videoTrack,
      reconnecting = _ref.reconnecting;
  var avatarWidth = width * 0.25;
  var avatarHeight = avatarWidth;
  var avatarX = width / 2 - avatarWidth / 2;
  var avatarY = height / 2 - avatarHeight / 2;
  var iconSize = width * 0.05;
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_CanvasAvatar.default, {
    defaultText: (0, _stringFormatter.getInitials)(name),
    width: avatarWidth,
    height: avatarHeight,
    x: avatarX,
    y: avatarY,
    src: picture
  }), _react.default.createElement(_CanvasVideo.default, {
    width: width,
    height: height,
    track: videoTrack
  }), _react.default.createElement(_CanvasNameStrip.default, {
    name: name,
    verticalAlign: "bottom",
    horizontalAlign: "left",
    containerHeight: height,
    containerWidth: width
  }), role && _react.default.createElement(_CanvasRoleStrip.default, {
    role: role,
    verticalAlign: "bottom",
    horizontalAlign: "right",
    containerHeight: height,
    containerWidth: width
  }), !hasAudio && _react.default.createElement(_CircleGroup.default, {
    fill: "#00000080",
    height: iconSize * 1.5,
    width: iconSize * 1.5,
    x: width - iconSize * 1.5 - iconSize / 2,
    y: iconSize / 2
  }, _react.default.createElement(_CanvasIcon.default, {
    x: iconSize / 2,
    y: iconSize / 2,
    reversed: true,
    size: iconSize * 0.6,
    icon: _CanvasIcon.default.icons.MIC_OFF,
    color: "#FFF"
  })), !hasParticipant && _react.default.createElement(_CircleGroup.default, {
    fill: "#00000080",
    height: iconSize * 1.5,
    width: iconSize * 1.5,
    x: iconSize / 2,
    y: iconSize / 2
  }, _react.default.createElement(_CanvasIcon.default, {
    x: iconSize,
    y: iconSize / 2,
    size: iconSize * 0.6,
    icon: _CanvasIcon.default.icons.NO_USER,
    color: "#ef5350"
  })), reconnecting && _react.default.createElement(_CanvasReconnecting.default, {
    width: width,
    height: height
  }));
}