var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    title: (0, _defineProperty2.default)({
      marginBottom: 10,
      fontSize: '1.25rem'
    }, theme.breakpoints.up('md'), {
      fontSize: '1.5rem'
    }),
    subtitle: {
      opacity: 0.6
    }
  };
});
var _default = styles;
exports.default = _default;