Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)({
  avatar: {
    '& > div': {
      width: 120,
      height: 120,
      borderRadius: 32
    }
  },
  social: {
    cursor: 'pointer'
  }
});
var _default = styles;
exports.default = _default;