Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useCalendarButtonStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  buttonContainer: {
    width: 72,
    height: 78
  }
}).addThemeBasedStyle(function (theme) {
  return {
    buttonContainer: {
      margin: theme.spacing.xs,
      borderRadius: 4
    }
  };
}).buildHook();
var _default = useCalendarButtonStyles;
exports.default = _default;