Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var useStepsStyles = (0, _core.makeStyles)(function (theme) {
  return {
    stepsContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center'
    },
    step: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      opacity: 0.4,
      padding: '16px 32px'
    },
    activeStep: {
      opacity: 1
    },
    titleContainer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 16
    },
    indexContainer: {
      display: 'flex',
      width: 50,
      height: 50,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: theme.palette.containerBackground.main
    }
  };
});
var _default = useStepsStyles;
exports.default = _default;