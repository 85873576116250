Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var usePageStyle = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    page: {
      backgroundColor: theme.colors.pageBackground,
      flexDirection: 'column',
      flex: 1,
      zIndex: 1
    }
  };
}).buildHook();
var _default = usePageStyle;
exports.default = _default;