var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var _appraisal = _interopRequireDefault(require("../appraisal"));

var InitialState = _immutable.default.OrderedMap();

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.LOAD_FESTIVALS_COMPLETED:
      newState = newState.clear();

      for (var i in action.festival) {
        newState = newState.set(action.festival[i].id, _immutable.default.fromJS(action.festival[i]));
        newState = newState.setIn([action.festival[i].id, 'startDate'], new Date(action.festival[i].startDate));
        var festivalId = 35;
        var ticketId = 3;
        var ticketCode = 'ec-premium-apple';

        if (process.env.ENVIRONMENT === 'beta' || process.env.ENVIRONMENT === 'alpha') {
          festivalId = 7;
          ticketId = 9;
          ticketCode = 'EC_2019_PREMIUM';
        }

        if (action.festival[i].id === festivalId) {
          newState = newState.setIn([action.festival[i].id, 'accessTicket', 'id'], ticketId);
          newState = newState.setIn([action.festival[i].id, 'accessTicket', 'code'], ticketCode);
        }

        newState = newState.setIn([action.festival[i].id, 'endDate'], new Date(action.festival[i].endDate));
      }

      break;

    case _actionTypes.LOAD_FESTIVAL_COMPLETED:
      newState = newState.set(action.festival.id, _immutable.default.fromJS(action.festival));
      newState = newState.setIn([action.festival.id, 'startDate'], new Date(action.festival.startDate));
      newState = newState.setIn([action.festival.id, 'endDate'], new Date(action.festival.endDate));
      break;

    case _actionTypes.LOAD_FESTIVAL_FOLLOWERS_COMPLETED:
      newState = newState.setIn([action.festivalId, 'nrFollowers'], action.nrFollowers);
      break;

    case _appraisal.default.constants.FOLLOW_FESTIVAL_COMPLETED:
      newState = newState.updateIn([action.festivalId, 'nrFollowers'], function (value) {
        if (value !== undefined) {
          return value + 1;
        }
      });
      break;

    case _appraisal.default.constants.UNFOLLOW_FESTIVAL_COMPLETED:
      newState = newState.updateIn([action.festivalId, 'nrFollowers'], function (value) {
        if (value !== undefined) {
          return value - 1;
        }
      });
      break;

    default:
      break;
  }

  return newState;
}