var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasAvatar;

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _CircleGroup = _interopRequireDefault(require("./CircleGroup"));

var _CanvasUrlImage = _interopRequireDefault(require("./CanvasUrlImage"));

function CanvasAvatar(_ref) {
  var height = _ref.height,
      src = _ref.src,
      width = _ref.width,
      _ref$x = _ref.x,
      x = _ref$x === void 0 ? 0 : _ref$x,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 0 : _ref$y,
      defaultText = _ref.defaultText;
  return _react.default.createElement(_CircleGroup.default, {
    height: height,
    width: width,
    x: x,
    y: y
  }, _react.default.createElement(_reactKonva.Text, {
    width: width,
    height: height,
    fontSize: height * 0.33,
    wrap: "none",
    ellipsis: true,
    align: "center",
    verticalAlign: "middle",
    text: defaultText,
    fill: "#000"
  }), _react.default.createElement(_CanvasUrlImage.default, {
    height: height,
    objectFit: "center-middle",
    width: width,
    src: src,
    x: 0,
    y: 0
  }));
}