var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AutoConnect;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _actions = require("../../core/chat/actions");

function AutoConnect(_ref) {
  var id = _ref.id,
      children = _ref.children;
  var dispatch = (0, _reactRedux.useDispatch)();

  _react.default.useEffect(function () {
    id && dispatch((0, _actions.sendRoomChange)(id));
    return function () {
      id && dispatch((0, _actions.sendRoomLeave)());
    };
  }, [id]);

  return _react.default.createElement(_react.default.Fragment, null, children);
}