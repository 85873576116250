var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactFeather = require("react-feather");

var _reactRedux = require("react-redux");

var _actions = require("../../../../core/polls/actions");

var _selectors = require("../../../../core/polls/selectors");

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _OutlinedButton = _interopRequireDefault(require("../../../../theme/components/buttons/OutlinedButton"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _reactNativePaper = require("react-native-paper");

var _selectors2 = require("../../../../core/team/selectors");

var _actions2 = require("../../../../core/global/actions");

var _Dialog = _interopRequireDefault(require("../../../../theme/components/dialog/Dialog"));

var _absoluteSpinner = _interopRequireDefault(require("../../absolute-spinner"));

var _pollsStyles = _interopRequireDefault(require("../polls-styles"));

var PollListContainer = function PollListContainer(_ref) {
  var onNewPoll = _ref.onNewPoll,
      onSinglePoll = _ref.onSinglePoll,
      onEditPoll = _ref.onEditPoll;
  var dispatch = (0, _reactRedux.useDispatch)();
  var pollList = (0, _reactRedux.useSelector)(_selectors.selectPollsList).sort(function (a, b) {
    return a.createdAt > b.createdAt ? 1 : -1;
  });
  var pollsLoading = (0, _reactRedux.useSelector)(_selectors.selectPollsIsLoading);
  var staffMember = (0, _reactRedux.useSelector)(_selectors2.isStaffMember);
  var Styles = (0, _pollsStyles.default)();

  if (pollsLoading) {
    return _react.default.createElement(_Spacing.default, {
      mSpacing: "xl"
    }, _react.default.createElement(_absoluteSpinner.default, {
      size: "large"
    }));
  }

  return _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 0],
    direction: "column"
  }, _react.default.createElement(_core.Typography, {
    variant: "h2",
    align: "center"
  }, staffMember ? _i18n.default.translate('polls.yourPolls') : pollList.length ? _i18n.default.translate('polls.respondToPolls') : _i18n.default.translate('polls.noPollsYet'))), staffMember && _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 's', 0],
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_ContainedButton.default, {
    color: "alternativeText",
    onPress: onNewPoll,
    spacing: 2,
    iconLeft: _react.default.createElement(_reactFeather.PlusCircle, null)
  }, _i18n.default.translate('polls.addNewPoll'))), pollList.sort().map(function (poll) {
    return _react.default.createElement(_Spacing.default, {
      key: poll.id,
      direction: "row",
      shaping: "cards",
      color: "alternativeBackground",
      mSpacing: ['xs', 0],
      justifyContent: "space-between"
    }, _react.default.createElement(_Spacing.default, {
      flex: 5,
      pSpacing: "m",
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_reactNativePaper.TouchableRipple, {
      borderless: true,
      onPress: function onPress() {
        return onSinglePoll(poll);
      }
    }, _react.default.createElement(_core.Typography, null, poll.title))), staffMember && _react.default.createElement(_Spacing.default, {
      flex: 1,
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_OutlinedButton.default, {
      style: Styles.borderlessOutlineButton,
      color: "alternativeText",
      iconLeft: _react.default.createElement(_reactFeather.Edit, null),
      onPress: function onPress() {
        return onEditPoll(poll);
      }
    })), staffMember && _react.default.createElement(_Spacing.default, {
      flex: 1,
      direction: "column",
      alignItems: "stretch"
    }, _react.default.createElement(_OutlinedButton.default, {
      style: Styles.borderlessOutlineButton,
      color: "alternativeText",
      iconLeft: _react.default.createElement(_reactFeather.Trash2, null),
      onPress: function onPress() {
        return dispatch((0, _actions2.openDialog)({
          id: "delete-".concat(poll.id),
          content: _react.default.createElement(_Dialog.default, {
            onBackdropClick: function onBackdropClick() {
              return dispatch((0, _actions2.closeDialog)("delete-".concat(poll.id)));
            }
          }, _react.default.createElement(_Spacing.default, {
            shaping: "cards",
            color: "pageBackground",
            direction: "column",
            alignItems: "stretch",
            pSpacing: "xxl"
          }, _react.default.createElement(_Spacing.default, {
            direction: "row",
            mSpacing: ['xl', 0]
          }, _react.default.createElement(_core.Typography, null, _i18n.default.translate('polls.deleteConfirmText'))), _react.default.createElement(_Spacing.default, {
            direction: "row",
            alignItems: "flex-end",
            justifyContent: "space-evenly"
          }, _react.default.createElement(_ContainedButton.default, {
            onPress: function onPress() {
              return (0, _reactRedux.batch)(function () {
                dispatch((0, _actions.deletePoll)({
                  pollId: poll.id
                }));
                dispatch((0, _actions2.closeDialog)("delete-".concat(poll.id)));
              });
            }
          }, _i18n.default.translate('polls.confirmDelete')), _react.default.createElement(_OutlinedButton.default, {
            onPress: function onPress() {
              return dispatch((0, _actions2.closeDialog)("delete-".concat(poll.id)));
            }
          }, _i18n.default.translate('polls.cancelDelete'))))),
          index: 0
        }));
      }
    })));
  }));
};

var _default = PollListContainer;
exports.default = _default;