Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useQuickAccessButtonStyles = new _DesignSystem.MakeStyle().addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    button: {
      marginLeft: theme.spacing.s,
      marginRight: theme.spacing.s,
      marginTop: theme.spacing.s,
      marginBottom: theme.spacing.s
    }
  };
}).buildHook();
var _default = useQuickAccessButtonStyles;
exports.default = _default;