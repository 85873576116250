var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNavigationHooks = require("react-navigation-hooks");

var RenderGuard = function RenderGuard(_ref) {
  var children = _ref.children;
  var isFocused = (0, _reactNavigationHooks.useIsFocused)();

  if (isFocused) {
    return children;
  } else {
    return null;
  }
};

var _default = RenderGuard;
exports.default = _default;