Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    paddingTop: 20
  },
  emailContainer: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 20
  },
  logo: {
    width: 175,
    height: 45
  },
  signIn: {
    width: '100%',
    backgroundColor: '#fff',
    alignSelf: 'center'
  },
  forgotPassword: {
    fontSize: 18,
    color: '#fff'
  },
  forgotPasswordButton: {
    width: '100%',
    marginTop: 20,
    backgroundColor: '#fff',
    alignSelf: 'center'
  },
  textInput: {
    fontSize: 18,
    height: 48,
    borderRadius: 4
  }
});

exports.default = _default;