Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendEmailTo = exports.openExternalUrl = void 0;

var _reactNative = require("react-native");

var openExternalUrl = function openExternalUrl(url) {
  return _reactNative.Platform.OS === 'web' ? window.open(url.includes('http') || url.includes('https') ? url : "https://".concat(url), '_blank') : _reactNative.Linking.openURL(url.includes('http') || url.includes('https') ? url : "https://".concat(url));
};

exports.openExternalUrl = openExternalUrl;

var sendEmailTo = function sendEmailTo(emailAddress) {
  if (_reactNative.Platform.OS === 'web') {
    var a = document.createElement('a');
    a.href = "mailto:".concat(emailAddress);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
  } else {
    _reactNative.Linking.openURL("mailto://".concat(emailAddress));
  }
};

exports.sendEmailTo = sendEmailTo;