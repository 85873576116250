Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handle = handle;
exports.wait = wait;

function handle(promise) {
  return promise.then(function (data) {
    return [data, undefined];
  }).catch(function (error) {
    return [undefined, error];
  });
}

function wait(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}