var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InvalidCodeView;

var _react = _interopRequireDefault(require("react"));

var _eventLogo = _interopRequireDefault(require("../../../common/event-logo"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _Section = _interopRequireDefault(require("../../../../theme/components/section/Section"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _authStyles = _interopRequireDefault(require("../../auth-styles"));

var _LinkButton = _interopRequireDefault(require("../../../../theme/components/buttons/LinkButton"));

function InvalidCodeView(_ref) {
  var goToResetPassword = _ref.goToResetPassword;
  var Styles = (0, _authStyles.default)();
  return _react.default.createElement(_Spacing.default, {
    alignItems: "stretch",
    style: Styles.container,
    mSpacing: [0, 0, 'xxl', 0],
    direction: "column"
  }, _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_eventLogo.default, null)), _react.default.createElement(_Section.default, {
    shaped: true,
    shaping: "forms",
    color: "containerBackground"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 'xxl'],
    style: Styles.authBox,
    direction: "column",
    alignItems: "stretch",
    justifyContent: "center"
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'l', 0],
    direction: "column",
    alignItems: "center"
  }, _react.default.createElement(_core.Typography, {
    variant: "largeText"
  }, _i18n.default.translate('resetPassword.invalidCode'))), _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'l', 0]
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('resetPassword.invalidCodeText'))), _react.default.createElement(_Spacing.default, {
    mSpacing: ['xs', 0, 'l', 0],
    direction: "column",
    alignItems: "stretch",
    wrap: "wrap"
  }, _react.default.createElement(_LinkButton.default, {
    onPress: goToResetPassword
  }, function (style, color) {
    return _react.default.createElement(_core.Typography, {
      style: style,
      color: color,
      variant: "caption",
      align: "center"
    }, _i18n.default.translate('login.forgotPasswordRetry'));
  })))));
}