var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _selectors = require("../../../core/legal-informations/selectors");

var _selectors2 = require("../../../core/event-info/selectors");

var _reactRedux = require("react-redux");

var _actions = require("../../../core/legal-informations/actions");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _DefaultHTMLrenderer = _interopRequireDefault(require("../../common/DefaultHTMLrenderer"));

var PrivacyPolicyView = function PrivacyPolicyView() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectLegalInformationsIsLoading);
  var isLoaded = (0, _reactRedux.useSelector)(_selectors.selectLegalInformationsIsLoaded);
  var privacyPolicy = (0, _reactRedux.useSelector)(_selectors.selectPrivacyPolicy);
  var isLoadedEvent = (0, _reactRedux.useSelector)(_selectors2.selectEventInfoLoaded);

  _react.default.useEffect(function () {
    if (isLoadedEvent) dispatch((0, _actions.getLegalInformations)());
  }, [dispatch, isLoadedEvent]);

  return _react.default.createElement(_react.default.Fragment, null, isLoading && _react.default.createElement(_Spacing.default, {
    mSpacing: ['xl', 'auto']
  }, _react.default.createElement(_reactNative.ActivityIndicator, {
    size: "large"
  })), isLoaded && privacyPolicy && _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "flex-start",
    flex: 1
  }, _react.default.createElement(_Spacing.default, {
    mSpacing: ['m', 0],
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_core.Typography, {
    align: "center",
    variant: "h2"
  }, _i18n.default.t('footer.privacyPolicy'))), _react.default.createElement(_core.Typography, null, _react.default.createElement(_DefaultHTMLrenderer.default, {
    source: privacyPolicy.text
  }))));
};

var _default = PrivacyPolicyView;
exports.default = _default;