Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    paddingTop: 20
  },
  phoneNumberContainer: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 20
  },
  recaptchaContainer: {
    alignItems: 'center',
    padding: 20
  },
  verificationContainer: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 20
  },
  logo: {
    width: 175,
    height: 45
  },
  paragraph: {
    color: '#fff',
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center',
    marginVertical: 30
  },
  button: {
    height: 50,
    backgroundColor: '#fff',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4
  },
  buttonText: {
    color: '#000',
    fontSize: 22,
    fontWeight: '300',
    lineHeight: 26
  },
  picker: {
    height: 0,
    opacity: 0
  },
  submit: {
    width: '100%',
    backgroundColor: '#fff',
    alignSelf: 'center'
  },
  resend: {
    width: '100%',
    maxWidth: 400,
    marginTop: 10,
    fontSize: 16
  },
  resendMessage: {
    color: '#fff',
    textAlign: 'center',
    lineHeight: 26,
    fontSize: 15,
    fontWeight: '600'
  },
  resendLink: {
    color: '#fff',
    textAlign: 'center',
    lineHeight: 26,
    fontSize: 14
  },
  text: {
    color: '#000',
    textAlign: 'center',
    fontSize: 18
  },
  cellRoot: {
    width: 32,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: '#fff',
    borderBottomWidth: 1
  },
  cellText: {
    color: '#fff',
    fontSize: 30,
    textAlign: 'center'
  },
  focusCell: {
    borderBottomColor: '#ccc',
    borderBottomWidth: 2
  },
  textInput: {
    color: '#000',
    height: 48,
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: 4,
    fontSize: 22,
    fontWeight: '300',
    lineHeight: 26
  }
});

exports.default = _default;