Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useBackstageViewStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    dialog: {
      maxWidth: '40vh'
    },
    indicator: {
      marginRight: 15,
      opacity: 0.6
    },
    description: {
      opacity: 0.85
    },
    colBar: {
      flexGrow: 0,
      maxWidth: '33.33%',
      flexBasis: '33.33%',
      alignItems: 'center',
      position: 'initial',
      zIndex: 1
    },
    spacer: {
      marginLeft: 8
    },
    spacerEnd: {
      marginRight: 16,
      position: 'initial'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.bg;
}, {
  container: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, {
  stateBar: {
    position: 'absolute',
    top: 75,
    left: 0,
    zIndex: 1,
    maxWidth: '100%',
    flexBasis: '100%'
  },
  colOptions: {
    maxWidth: '50%',
    flexBasis: '50%'
  },
  colActions: {
    maxWidth: '50%',
    flexBasis: '50%',
    justifyContent: 'flex-start'
  },
  spacer: {
    marginLeft: 4
  },
  spacerEnd: {
    marginRight: 8
  }
}).buildHook();
var _default = useBackstageViewStyles;
exports.default = _default;