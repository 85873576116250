var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeCount = void 0;

var _moment = _interopRequireDefault(require("moment"));

var TimeCount = function TimeCount(date) {
  var ms = (0, _moment.default)().diff((0, _moment.default)(date, 'DD/MM/YYYY HH:mm:ss'));

  var duration = _moment.default.duration(ms);

  var days = Math.floor(duration.asDays());
  var hours = duration.hours();
  var minutes = duration.minutes();
  var seconds = duration.seconds();
  var timeCount = '';
  hours = hours + 24 * days;

  if (hours === 0 && minutes === 0) {
    timeCount = seconds + 's';
  }

  if (hours === 0 && minutes !== 0) {
    timeCount = minutes + 'm ' + seconds + 's';
  }

  if (hours !== 0 && minutes !== 0) {
    timeCount = hours + 'h ' + minutes + 'm ' + seconds + 's';
  }

  if (hours !== 0 && minutes === 0) {
    timeCount = hours + 'h ' + seconds + 's';
  }

  return timeCount;
};

exports.TimeCount = TimeCount;