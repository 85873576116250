var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SelectedTicket;

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _styles = _interopRequireDefault(require("./styles"));

function SelectedTicket(_ref) {
  var name = _ref.name,
      type = _ref.type,
      quantity = _ref.quantity,
      price = _ref.price,
      newPrice = _ref.newPrice;
  var classes = (0, _styles.default)();

  if (type === 'Paid') {
    return _react.default.createElement(_Spacing.default, {
      direction: "column",
      alignItems: "center",
      justifyContent: "center"
    }, _react.default.createElement(_core.Typography, {
      variant: "h2"
    }, "".concat(quantity, " x ").concat(_i18n.default.translate('checkout.ticket'), " \"").concat(name, "\"")), _react.default.createElement(_Spacing.default, {
      mSpacing: ['m', 0, 0, 0],
      alignItems: "center",
      justifyContent: "center"
    }, _react.default.createElement(_Spacing.default, null, _react.default.createElement(_core.Typography, {
      className: newPrice ? classes.textLineThrough : classes.textNormal,
      variant: newPrice ? 'h4' : 'h3'
    }, price)), newPrice && _react.default.createElement(_Spacing.default, {
      mSpacing: [0, 0, 0, 's']
    }, _react.default.createElement(_core.Typography, {
      variant: "h3"
    }, newPrice))));
  }

  if (type === 'Free') {
    return _react.default.createElement(_core.Typography, {
      align: "center",
      variant: "h2"
    }, "\"".concat(name, "\""));
  }

  return null;
}