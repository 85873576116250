var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _app = require("@firebase/app");

require("firebase/auth");

require("firebase/firestore");

require("firebase/storage");

require("firebase/functions");

require("firebase/database");

var firebaseConfig = {
  apiKey: process.env.FIREBASE_WEB_APP_apiKey,
  authDomain: process.env.FIREBASE_WEB_APP_authDomain,
  databaseURL: process.env.FIREBASE_WEB_APP_databaseURL,
  projectId: process.env.FIREBASE_WEB_APP_projectId,
  storageBucket: process.env.FIREBASE_WEB_APP_storageBucket,
  messagingSenderId: process.env.FIREBASE_WEB_APP_messagingSenderId,
  appId: process.env.FIREBASE_WEB_APP_appId
};

var app = _app.firebase.initializeApp(firebaseConfig);

var functions = app.functions('europe-west3');

_app.firebase.firestore().settings({
  experimentalAutoDetectLongPolling: true
});

var Firebase = {
  config: function config() {
    return {
      fetch: function () {
        var _fetch = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
          return _regenerator.default.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _context.next = 2;
                  return Promise.resolve({
                    disablePaymentRegionFestival: true
                  });

                case 2:
                  return _context.abrupt("return", _context.sent);

                case 3:
                case "end":
                  return _context.stop();
              }
            }
          }, _callee);
        }));

        function fetch() {
          return _fetch.apply(this, arguments);
        }

        return fetch;
      }(),
      activateFetched: function activateFetched() {},
      getKeysByPrefix: function getKeysByPrefix() {
        return ['disablePaymentRegionFestival'];
      },
      getValues: function getValues() {
        return {
          disablePaymentRegionFestival: {
            val: function val() {
              return true;
            }
          }
        };
      },
      enableDeveloperMode: function enableDeveloperMode() {},
      logEvent: function logEvent() {}
    };
  },
  analytics: function analytics() {
    return {
      setUserId: function setUserId() {},
      setUserProperty: function setUserProperty() {},
      logEvent: function logEvent() {}
    };
  },
  messaging: function messaging() {
    return {
      hasPermission: function () {
        var _hasPermission = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee2() {
          return _regenerator.default.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                case "end":
                  return _context2.stop();
              }
            }
          }, _callee2);
        }));

        function hasPermission() {
          return _hasPermission.apply(this, arguments);
        }

        return hasPermission;
      }(),
      requestPermission: function () {
        var _requestPermission = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee3() {
          return _regenerator.default.wrap(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                case "end":
                  return _context3.stop();
              }
            }
          }, _callee3);
        }));

        function requestPermission() {
          return _requestPermission.apply(this, arguments);
        }

        return requestPermission;
      }(),
      getToken: function () {
        var _getToken = (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee4() {
          return _regenerator.default.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                case "end":
                  return _context4.stop();
              }
            }
          }, _callee4);
        }));

        function getToken() {
          return _getToken.apply(this, arguments);
        }

        return getToken;
      }()
    };
  },
  auth: _app.firebase.auth,
  firestore: _app.firebase.firestore,
  database: _app.firebase.database,
  storage: _app.firebase.storage,
  functions: functions
};
var _default = Firebase;
exports.default = _default;