Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    textWhite: {
      color: theme.palette.headerText.main
    }
  };
});
var _default = styles;
exports.default = _default;