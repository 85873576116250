Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useMyTicketsPageStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    border: {
      borderColor: theme.colors.borderColor,
      borderWidth: 1,
      borderStyle: 'solid'
    },
    container: {
      marginLeft: theme.spacing.leftMenu
    },
    addonContainer: {
      borderTopWidth: 1,
      borderTopColor: theme.colors.borderColor,
      borderStyle: 'dashed'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: {
      marginLeft: 0,
      paddingTop: theme.spacing.s,
      paddingBottom: theme.spacing.s,
      paddingLeft: theme.spacing.m,
      paddingRight: theme.spacing.m
    }
  };
}).buildHook();
var _default = useMyTicketsPageStyles;
exports.default = _default;