var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _loginBackstageContainer.default;
  }
});

var _loginBackstageContainer = _interopRequireDefault(require("./login-backstage-container"));