var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _react = _interopRequireDefault(require("react"));

var _Clap = _interopRequireDefault(require("../../../../theme/components/icons/Clap"));

var _ThemeGrid = _interopRequireDefault(require("./ThemeGrid"));

var _styles = _interopRequireDefault(require("./styles"));

function BackstageWelcomeMessage(_ref) {
  var action = _ref.action,
      color = _ref.color;
  var classes = (0, _styles.default)();
  return _react.default.createElement(_core.Grid, {
    style: {
      flex: 1
    },
    direction: "column",
    alignItems: "center",
    justify: "center",
    wrap: "nowrap",
    container: true
  }, _react.default.createElement(_ThemeGrid.default, {
    item: true,
    container: true,
    color: color,
    alignItems: "center",
    justify: "center"
  }, _react.default.createElement(_core.Box, {
    p: 2,
    className: classes.messageContainer
  }, _react.default.createElement(_core.Typography, {
    align: "center",
    variant: "h5",
    gutterBottom: true
  }, _react.default.createElement(_Clap.default, {
    width: 20,
    height: 20,
    style: {
      marginRight: 20
    }
  }), _i18n.default.translate('backstage.welcomeToTheBackstage')), _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('backstage.welcomeToTheBackstageText'))), _react.default.createElement(_core.Grid, {
    style: {
      width: 'max-content'
    },
    item: true,
    container: true,
    direction: "column",
    alignItems: "stretch"
  }, _react.default.createElement(_core.Box, {
    m: 2
  }), action)));
}

var _default = BackstageWelcomeMessage;
exports.default = _default;