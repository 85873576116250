var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _core = require("@material-ui/core");

var _eventRegistrationUtils = require("../../event-registration-utils");

var useContactInformationStepStyle = (0, _core.makeStyles)(function (theme) {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    messageBlockContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 40
    },
    navLinks: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      marginBottom: 20
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      flexDirection: 'row'
    }),
    loggedInDataContainer: {
      width: '98%',
      display: 'flex',
      flexDirection: 'column',
      height: '150px'
    },
    emailWarning: {
      color: 'green',
      marginBottom: 20
    },
    userInfo: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'column',
      flexFlow: 'nowrap',
      width: '98%',
      justifyContent: 'flex-start'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      flexDirection: 'row',
      flexFlow: 'row wrap',
      justifyContent: 'space-between'
    }),
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 16
    },
    inputWidth: (0, _defineProperty2.default)({
      width: '100%'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      width: '45%'
    }),
    customInputWidth: {
      width: '100%'
    },
    labelContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: 8
    },
    errorContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 8
    },
    errorColor: {
      color: theme.palette.error.main
    },
    additionalInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: '98%'
    },
    termsConditionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    termsConditions: {
      display: 'flex',
      width: '100%',
      color: theme.palette.alternativeText.main
    }
  };
});
var _default = useContactInformationStepStyle;
exports.default = _default;