var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSessionDetailsForCalendar = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _timezoneUtils = require("./timezone-utils");

var _stringFormatter = require("./string-formatter");

var _dateFormatter = require("./date-formatter");

var getSessionDetailsForCalendar = function getSessionDetailsForCalendar(liveSession, dateFormat) {
  var now = (0, _momentTimezone.default)();
  var startDateTime = new Date(liveSession.start);
  var endDateTime = new Date(liveSession.end);
  var isLive = (0, _momentTimezone.default)(startDateTime) <= now && (0, _momentTimezone.default)(endDateTime) >= now;
  var startHour = (0, _dateFormatter.getTimeBasedOnDateFormat)(startDateTime, dateFormat);
  var endHour = (0, _dateFormatter.getTimeBasedOnDateFormat)(endDateTime, dateFormat);
  var zoneAbbreviation = (0, _timezoneUtils.getZoneAbbreviation)();
  var speakers = liveSession.speakers || liveSession.selectedSpeakers;
  var speakerUrlsAndIds = speakers === null || speakers === void 0 ? void 0 : speakers.map(function (speaker) {
    return {
      id: speaker.id,
      photo: speaker.photo || "https://via.placeholder.com/64/000000/FFFFFF/?text=".concat((0, _stringFormatter.getInitials)(speaker.name))
    };
  });
  var speakerNames = speakers === null || speakers === void 0 ? void 0 : speakers.map(function (speaker) {
    return speaker.name;
  }).reduce(function (accum, name, index) {
    return index ? "".concat(accum, ", ").concat(name) : name;
  }, '');
  return {
    isLive: isLive,
    startDateTime: startDateTime,
    endDateTime: endDateTime,
    startHour: startHour,
    endHour: endHour,
    zoneAbbreviation: zoneAbbreviation,
    speakerUrlsAndIds: speakerUrlsAndIds,
    speakerNames: speakerNames
  };
};

exports.getSessionDetailsForCalendar = getSessionDetailsForCalendar;