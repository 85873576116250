Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getCountries;

var _countryDataList = require("country-data-list");

function getCountries() {
  var allCountryInfo = _countryDataList.countries.all;
  var countryList = [];
  allCountryInfo.forEach(function (countryInfo) {
    var singleCountry = {};
    singleCountry['id'] = countryInfo.name + ' ' + countryInfo.alpha2;
    singleCountry['value'] = countryInfo.name + ' ' + countryInfo.alpha2;
    countryList.push(singleCountry);
  });
  return countryList;
}