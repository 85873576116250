var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NetworkIndicator;

var _react = _interopRequireDefault(require("react"));

var _SignalWifi0Bar = _interopRequireDefault(require("@material-ui/icons/SignalWifi0Bar"));

var _SignalWifi1Bar = _interopRequireDefault(require("@material-ui/icons/SignalWifi1Bar"));

var _SignalWifi2Bar = _interopRequireDefault(require("@material-ui/icons/SignalWifi2Bar"));

var _SignalWifi3Bar = _interopRequireDefault(require("@material-ui/icons/SignalWifi3Bar"));

var _SignalWifi4Bar = _interopRequireDefault(require("@material-ui/icons/SignalWifi4Bar"));

var _SignalWifiOff = _interopRequireDefault(require("@material-ui/icons/SignalWifiOff"));

var _useUplinkNetworkQuality = _interopRequireDefault(require("../../../utils/hooks/useUplinkNetworkQuality"));

var _useDownlinkNetworkQuality = _interopRequireDefault(require("../../../utils/hooks/useDownlinkNetworkQuality"));

function NetworkIndicator(_ref) {
  var color = _ref.color,
      size = _ref.size;
  var upQuality = (0, _useUplinkNetworkQuality.default)();
  var downQuality = (0, _useDownlinkNetworkQuality.default)();

  var quality = _react.default.useMemo(function () {
    return Math.min(upQuality, downQuality);
  }, [upQuality, downQuality]);

  switch (quality) {
    case 0:
    case 1:
      {
        return _react.default.createElement(_SignalWifi4Bar.default, {
          fontSize: size,
          color: color
        });
      }

    case 2:
      {
        return _react.default.createElement(_SignalWifi3Bar.default, {
          fontSize: size,
          color: color
        });
      }

    case 3:
      {
        return _react.default.createElement(_SignalWifi2Bar.default, {
          fontSize: size,
          color: color
        });
      }

    case 4:
      {
        return _react.default.createElement(_SignalWifi1Bar.default, {
          fontSize: size,
          color: color
        });
      }

    case 5:
      {
        return _react.default.createElement(_SignalWifi0Bar.default, {
          fontSize: size,
          color: color
        });
      }

    case 6:
      {
        return _react.default.createElement(_SignalWifiOff.default, {
          fontSize: size,
          color: color
        });
      }
  }
}