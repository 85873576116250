var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _live = _interopRequireDefault(require("../live"));

var _chat = _interopRequireDefault(require("../chat"));

var _selectors = require("./selectors");

var _actions = require("./actions");

var _actionTypes = require("./actionTypes");

function createMiddleware() {
  var autoReadMode = false;
  return function (store) {
    return function (next) {
      return function (action) {
        var oldMessages;

        if (action.type === _chat.default.constants.RECEIVE_MESSAGES && !autoReadMode) {
          oldMessages = _chat.default.selectors.getMessages(store.getState());
        }

        var result = next(action);

        switch (action.type) {
          case _actionTypes.SET_AUTO_READ_MODE:
            autoReadMode = action.enabled;

            if (autoReadMode) {
              var messages = _chat.default.selectors.getMessages(store.getState());

              if (messages && messages.size > 0) {
                store.dispatch((0, _actions.readNewMessagesCompleted)(_live.default.selectors.getLiveId(store.getState()), messages.last().get('key')));
              }
            }

            break;

          case _chat.default.constants.RECEIVE_MESSAGES:
            if (autoReadMode) {
              var _messages = _chat.default.selectors.getMessages(store.getState());

              if (_messages && _messages.size > 0) {
                store.dispatch((0, _actions.readNewMessagesCompleted)(_live.default.selectors.getLiveId(store.getState()), _messages.last().get('key')));
              }
            } else {
              var state = store.getState();

              var liveId = _live.default.selectors.getLiveId(state);

              var _messages2 = _chat.default.selectors.getMessages(state);

              var lastReadMessageKey = (0, _selectors.getLastReadMessageKey)(state, liveId);

              if (_messages2.findKey(function (message) {
                return message.get('key') === lastReadMessageKey;
              }) === undefined && oldMessages) {
                  var oldIndex = oldMessages.findKey(function (message) {
                    return message.get('key') === lastReadMessageKey;
                  });

                  var _loop = function _loop() {
                    var prevKey = oldMessages.getIn([oldIndex - 1, 'key']);

                    var entry = _messages2.find(function (message) {
                      return message.get('key') === prevKey;
                    });

                    if (entry) {
                      store.dispatch((0, _actions.readNewMessagesCompleted)(liveId, prevKey));
                      return "break";
                    }

                    oldIndex--;
                  };

                  while (oldIndex > 0) {
                    var _ret = _loop();

                    if (_ret === "break") break;
                  }
                }
            }

            break;

          case _chat.default.constants.SEND_MESSAGE:
            store.dispatch((0, _actions.readNewMessagesCompleted)(_live.default.selectors.getLiveId(store.getState()), action.message.sig));
            break;

          default:
            break;
        }

        return result;
      };
    };
  };
}