var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var _appraisal = _interopRequireDefault(require("../appraisal"));

var InitialState = _immutable.default.Map();

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.LOAD_DJS_COMPLETED:
      for (var i in action.dj) {
        if (action.dj[i].id) {
          newState = newState.setIn([action.dj[i].id.toString(), 'profile'], _immutable.default.fromJS(action.dj[i]));
        }
      }

      break;

    case _actionTypes.LOAD_PROFILE_COMPLETED:
      newState = newState.setIn([action.profile.id.toString(), 'profile'], _immutable.default.fromJS(action.profile));
      break;

    case _actionTypes.LOAD_NR_FOLLOWERS_COMPLETED:
      newState = newState.setIn([action.userId.toString(), 'numFollowers'], action.numFollowers);
      break;

    case _actionTypes.LOAD_NR_FOLLOWING_COMPLETED:
      newState = newState.setIn([action.userId.toString(), 'numFollowing'], action.numFollowing);
      break;

    case _actionTypes.RESET_FOLLOWERS:
      newState = newState.setIn([action.userId.toString(), 'followers'], _immutable.default.List());
      break;

    case _actionTypes.RESET_FOLLOWING:
      newState = newState.setIn([action.userId.toString(), 'following'], _immutable.default.List());
      break;

    case _actionTypes.LOAD_PART_OF_FOLLOWERS:
      newState = newState.updateIn([action.userId.toString(), 'followers'], function (list) {
        if (!list) {
          list = _immutable.default.List();
        }

        return list.concat(_immutable.default.fromJS(action.followers));
      });
      break;

    case _actionTypes.LOAD_PART_OF_FOLLOWING:
      newState = newState.updateIn([action.userId.toString(), 'following'], function (list) {
        if (!list) {
          list = _immutable.default.List();
        }

        return list.concat(_immutable.default.fromJS(action.following));
      });
      break;

    case _appraisal.default.constants.FOLLOW_COMPLETED:
      newState = newState.updateIn([action.userId.toString(), 'numFollowers'], function (value) {
        if (value !== undefined) {
          return value + 1;
        }
      });
      break;

    case _appraisal.default.constants.UNFOLLOW_COMPLETED:
      newState = newState.updateIn([action.userId.toString(), 'numFollowers'], function (value) {
        if (value !== undefined) {
          return value - 1;
        }
      });
      break;

    case _appraisal.default.constants.NEW_FOLLOWER:
      newState = newState.updateIn([action.userId.toString(), 'numFollowers'], function (value) {
        if (value !== undefined) {
          return value + 1;
        }
      });
      break;

    case _appraisal.default.constants.LOST_FOLLOWER:
      newState = newState.updateIn([action.userId.toString(), 'numFollowers'], function (value) {
        if (value !== undefined) {
          return value - 1;
        }
      });
      break;

    case _actionTypes.UPDATE_PROFILE_COMPLETED:
      newState = newState.setIn([action.userId.toString(), 'profile', 'displayName'], action.userName);
      newState = newState.setIn([action.userId.toString(), 'profile', 'bio'], action.bio);
      newState = newState.setIn([action.userId.toString(), 'profile', 'twitterProfileLink'], action.twitterProfileLink);
      newState = newState.setIn([action.userId.toString(), 'profile', 'facebookProfileLink'], action.facebookProfileLink);
      newState = newState.setIn([action.userId.toString(), 'profile', 'soundcloudProfileLink'], action.soundcloudProfileLink);
      newState = newState.setIn([action.userId.toString(), 'profile', 'instagramProfileLink'], action.instagramProfileLink);

      if (action.mixcloudProfileLink) {
        newState = newState.setIn([action.userId.toString(), 'profile', 'mixcloudProfileLink'], action.mixcloudProfileLink);
      }

      break;

    case _actionTypes.LOAD_DJS_MUSIC_GENRE_COMPLETED:
      for (var _i in action.djList) {
        newState = newState.setIn([action.djList[_i].id.toString(), 'profile'], _immutable.default.fromJS(action.djList[_i]));
      }

      break;

    default:
      break;
  }

  return newState;
}