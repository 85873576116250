Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../../theme/DesignSystem");

var useHamburgerMenuFooterStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    copyright: {
      marginTop: theme.spacing.s
    },
    footerPrivacy: {
      marginLeft: theme.spacing.s
    },
    termsLink: {
      color: theme.colors.alternativeText,
      textDecorationLine: 'none'
    }
  };
}).buildHook();
var _default = useHamburgerMenuFooterStyles;
exports.default = _default;