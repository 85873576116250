var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocalAudio;

var _react = _interopRequireDefault(require("react"));

var _AudioVideoProvider = require("./AudioVideoProvider");

function LocalAudio(_ref) {
  var children = _ref.children;

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      audioEnabled = _useAudioVideoContext.audioEnabled;

  if (!audioEnabled) return _react.default.createElement(_react.default.Fragment, null, children);
  return null;
}