var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNativeVectorIcons = require("react-native-vector-icons");

var _selection = _interopRequireDefault(require("./selection.json"));

var _default = (0, _reactNativeVectorIcons.createIconSetFromIcoMoon)(_selection.default);

exports.default = _default;