var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _regenerator = _interopRequireDefault(require("/home/runner/work/soundmix-mobile-app/soundmix-mobile-app/node_modules/@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _actions = require("../../core/chat-reactions/actions");

var _selectors = require("../../core/chat-reactions/selectors");

var _selectors2 = require("../../core/chat/selectors");

function ClapCounter(_ref) {
  var children = _ref.children;
  var dispatch = (0, _reactRedux.useDispatch)();
  var chatRoom = (0, _reactRedux.useSelector)(_selectors2.currentRoomSelector);

  _react.default.useEffect(function () {
    var unsubscribe = function unsubscribe() {};

    (0, _asyncToGenerator2.default)(_regenerator.default.mark(function _callee() {
      var _yield$dispatch, payload;

      return _regenerator.default.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return dispatch((0, _actions.subscribeChatReactionsChangeById)(chatRoom));

            case 2:
              _yield$dispatch = _context.sent;
              payload = _yield$dispatch.payload;
              unsubscribe = payload;

            case 5:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }))();
    return function () {
      unsubscribe();
    };
  }, []);

  var reaction = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.chatReactionByIdSelector)(state, chatRoom);
  });
  if (!reaction) return children(0);
  return children(reaction.clapsCount);
}

var _default = _react.default.memo(ClapCounter);

exports.default = _default;