Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getReferrals = exports.getDeepLink = exports.getReferrerName = exports.getReferrerPicture = exports.getReferrerId = void 0;

var _constants = require("./constants");

var getReferrerId = function getReferrerId(state) {
  return state[_constants.NAME].get('userId');
};

exports.getReferrerId = getReferrerId;

var getReferrerPicture = function getReferrerPicture(state) {
  return state[_constants.NAME].get('imageUrl');
};

exports.getReferrerPicture = getReferrerPicture;

var getReferrerName = function getReferrerName(state) {
  return state[_constants.NAME].get('displayName');
};

exports.getReferrerName = getReferrerName;

var getDeepLink = function getDeepLink(state) {
  return state[_constants.NAME].get('deepLink');
};

exports.getDeepLink = getDeepLink;

var getReferrals = function getReferrals(state) {
  return state[_constants.NAME].get('referralsList');
};

exports.getReferrals = getReferrals;