Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectPartnerById = exports.selectPartnersLength = exports.selectPartnersLoaded = exports.selectPartnersLoading = exports.selectPartnersList = exports.selectPartnersState = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectPartnersState = function selectPartnersState(state) {
  return state[_constants.NAME];
};

exports.selectPartnersState = selectPartnersState;

var selectPartnersList = _reducer.partnerEntityAdapter.getSelectors(selectPartnersState).selectAll;

exports.selectPartnersList = selectPartnersList;
var selectPartnersLoading = (0, _toolkit.createSelector)(selectPartnersState, function (state) {
  return state.loading;
});
exports.selectPartnersLoading = selectPartnersLoading;
var selectPartnersLoaded = (0, _toolkit.createSelector)(selectPartnersState, function (state) {
  return state.loaded;
});
exports.selectPartnersLoaded = selectPartnersLoaded;
var selectPartnersLength = (0, _toolkit.createSelector)(selectPartnersList, function (state) {
  return state.length;
});
exports.selectPartnersLength = selectPartnersLength;

var selectPartnerById = _reducer.partnerEntityAdapter.getSelectors(selectPartnersState).selectById;

exports.selectPartnerById = selectPartnerById;