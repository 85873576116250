Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.settingsEntityAdapter = exports.PEOPLE_ID = exports.LABELS_ID = exports.QA_ID = exports.CHAT_ID = exports.GET_EVENT_SETTINGS = exports.NAME = void 0;

var _toolkit = require("@reduxjs/toolkit");

var NAME = 'eventSettings';
exports.NAME = NAME;
var GET_EVENT_SETTINGS = "".concat(NAME, "/GET_EVENT_SETTINGS");
exports.GET_EVENT_SETTINGS = GET_EVENT_SETTINGS;
var CHAT_ID = 'chat';
exports.CHAT_ID = CHAT_ID;
var QA_ID = 'qa';
exports.QA_ID = QA_ID;
var LABELS_ID = '--labels--';
exports.LABELS_ID = LABELS_ID;
var PEOPLE_ID = 'people';
exports.PEOPLE_ID = PEOPLE_ID;
var settingsEntityAdapter = (0, _toolkit.createEntityAdapter)();
exports.settingsEntityAdapter = settingsEntityAdapter;