Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sectionByTypeSelector = exports.selectSectionById = exports.selectSectionsIsLoaded = exports.selectSectionsIsLoading = exports.selectSections = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _consts = require("../../utils/consts");

var _constants = require("./constants");

var _reducer = require("./reducer");

var selectSectionsState = function selectSectionsState(state) {
  return state[_constants.NAME];
};

var selectSections = _reducer.sectionsEntityAdapter.getSelectors(selectSectionsState).selectAll;

exports.selectSections = selectSections;
var selectSectionsIsLoading = (0, _toolkit.createSelector)(selectSectionsState, function (state) {
  return state.loading;
});
exports.selectSectionsIsLoading = selectSectionsIsLoading;
var selectSectionsIsLoaded = (0, _toolkit.createSelector)(selectSectionsState, function (state) {
  return state.loaded;
});
exports.selectSectionsIsLoaded = selectSectionsIsLoaded;

var selectSectionById = _reducer.sectionsEntityAdapter.getSelectors(selectSectionsState).selectById;

exports.selectSectionById = selectSectionById;
var sectionByTypeSelector = (0, _toolkit.createSelector)(selectSections, function (sections) {
  return sections.find(function (_ref) {
    var type = _ref.type;
    return type === _consts.SECTION_TYPES.SPONSORS;
  });
});
exports.sectionByTypeSelector = sectionByTypeSelector;