Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dialogEntityAdapter = exports.CLOSE_DIALOG = exports.OPEN_DIALOG = exports.STOP_LOADING = exports.START_LOADING = exports.NAME = void 0;

var _toolkit = require("@reduxjs/toolkit");

var NAME = 'global';
exports.NAME = NAME;
var START_LOADING = "".concat(NAME, "/START_LOADING");
exports.START_LOADING = START_LOADING;
var STOP_LOADING = "".concat(NAME, "/STOP_LOADING");
exports.STOP_LOADING = STOP_LOADING;
var OPEN_DIALOG = "".concat(NAME, "/OPEN_DIALOG");
exports.OPEN_DIALOG = OPEN_DIALOG;
var CLOSE_DIALOG = "".concat(NAME, "/CLOSE_DIALOG");
exports.CLOSE_DIALOG = CLOSE_DIALOG;
var dialogEntityAdapter = (0, _toolkit.createEntityAdapter)({
  sortComparer: function sortComparer(a, b) {
    return b.index - a.index;
  }
});
exports.dialogEntityAdapter = dialogEntityAdapter;