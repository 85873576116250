Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SECTION_TYPES = void 0;
var SECTION_TYPES = {
  ABOUT: 'about',
  SPEAKERS: 'speakers',
  SCHEDULE: 'schedule',
  BENEFITS: 'benefits',
  SPONSORS: 'sponsors',
  BOOTH: 'booth',
  TESTIMONIALS: 'testimonials',
  CHECKLIST: 'checklist',
  LETTER: 'letter',
  CUSTOM_SECTIONS: 'customSections'
};
exports.SECTION_TYPES = SECTION_TYPES;