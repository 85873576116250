var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StackItemView;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _stackStyles = _interopRequireDefault(require("./stack-styles"));

function StackItemView(props) {
  var containerStyles = [_stackStyles.default.defaultItem, props.style];

  if (props.fill) {
    containerStyles.push(_stackStyles.default.fillItem);
  }

  return _react.default.createElement(_reactNative.View, {
    style: containerStyles
  }, props.children);
}