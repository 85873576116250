var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _DesignSystem = require("../../../../theme/DesignSystem");

var _DefaultGifBackground = _interopRequireDefault(require("../../../protected-pages/live-page/components/DefaultGifBackground"));

var _liveSessionStyles = _interopRequireDefault(require("../live-session-styles"));

var _nextSessionCountdown = _interopRequireDefault(require("./next-session-countdown"));

var NextSessionView = function NextSessionView(_ref) {
  var session = _ref.session;
  var generalStyles = (0, _liveSessionStyles.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      spacing = _useTheme.spacing;

  return _react.default.createElement(_Spacing.default, {
    mSpacing: "s",
    style: {
      overflow: 'hidden',
      borderRadius: spacing.s
    }
  }, _react.default.createElement(_DefaultGifBackground.default, null, _react.default.createElement(_reactNative.View, {
    style: generalStyles.absoluteContainer
  }, _react.default.createElement(_nextSessionCountdown.default, {
    session: session,
    textColor: "#FFFFFF"
  }))));
};

var _default = NextSessionView;
exports.default = _default;