Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useLivePageStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginLeft: theme.spacing.leftMenu
    },
    speakerContainer: {
      marginVertical: theme.spacing.ml,
      marginHorizontal: theme.spacing.ml
    },
    partnersContainer: {
      marginVertical: theme.spacing.xl,
      marginHorizontal: theme.spacing.ml
    },
    liveVideoContainer: {
      paddingTop: 1,
      borderRadius: 10,
      overflow: 'hidden'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function () {
  return {
    container: {
      marginLeft: 0
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.smd;
}, function (theme) {
  return {
    speakerContainer: {
      flex: 0,
      marginLeft: theme.spacing.s,
      marginTop: theme.spacing.s,
      marginBottom: theme.spacing.s,
      marginRight: theme.spacing.s
    },
    title: {
      fontSize: '1.2rem'
    },
    subtitle: {
      fontSize: '1rem'
    },
    partnersContainer: {
      marginHorizontal: theme.spacing.s
    }
  };
}).buildHook();
var _default = useLivePageStyles;
exports.default = _default;