Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var usePollStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    scrollView: {
      flex: 1,
      alignItems: 'center'
    },
    pollsContainer: {
      width: 307
    },
    borderlessOutlineButton: {
      borderWidth: 0,
      borderColor: 'transparent',
      backgroundColor: 'transparent'
    },
    optionTitle: {
      flex: 5,
      padding: theme.spacing.s
    },
    addNewOptionIcon: {
      padding: theme.spacing.s,
      paddingTop: 0,
      borderWidth: 0
    },
    chartOuterContainer: {
      overflow: 'hidden'
    },
    chartInnerContainer: {
      marginHorizontal: -1 * theme.spacing.l
    },
    optionContainer: {
      backgroundColor: theme.colors.alternativeBackground
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.smd;
}, function () {
  return {
    pollsContainer: {
      width: 'auto'
    }
  };
}).buildHook();
var _default = usePollStyles;
exports.default = _default;