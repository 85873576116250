Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useFileCardStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    buttonText: {
      display: 'flex',
      alignItems: 'center'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (theme) {
  return theme.breakpoints.sm;
}, function (theme) {
  return {
    container: {
      flexDirection: 'column'
    },
    containerText: {
      textAlign: 'center'
    },
    price: {
      marginTop: theme.spacing.m
    }
  };
}).buildHook();
var _default = useFileCardStyles;
exports.default = _default;