var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _checkoutSectionStyles = _interopRequireDefault(require("./checkout-section-styles"));

var _reactNativePaperV = require("react-native-paper-v3");

var _DesignSystem = require("../../../theme/DesignSystem");

var _Section = _interopRequireDefault(require("../../../theme/components/section/Section"));

var _reactNavigationHooks = require("react-navigation-hooks");

var CheckoutSection = function CheckoutSection(_ref) {
  var children = _ref.children;
  var Styles = (0, _checkoutSectionStyles.default)();
  var largeWidth = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.UP, 1153);
  var smallWidth = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
    var breakpoints = _ref2.breakpoints;
    return breakpoints.smd;
  });
  var smallHeight = (0, _DesignSystem.useMediaQuery)(_DesignSystem.MediaConstants.direction.HEIGHT, _DesignSystem.MediaConstants.operator.DOWN, function (_ref3) {
    var breakpoints = _ref3.breakpoints;
    return breakpoints.xsm;
  });
  var isFocused = (0, _reactNavigationHooks.useIsFocused)();

  if (!smallWidth && (!largeWidth || smallHeight)) {
    return _react.default.createElement(_Section.default, {
      color: "containerBackground",
      style: Styles.inline
    }, children);
  }

  return _react.default.createElement(_reactNativePaperV.Portal, null, isFocused && _react.default.createElement(_Section.default, {
    color: "containerBackground",
    shaped: true,
    shaping: "forms",
    elevation: 16,
    style: Styles.fixed
  }, children) || null);
};

var _default = CheckoutSection;
exports.default = _default;