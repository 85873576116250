var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSideMenuHeight = exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));

var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));

var _rxjs = require("rxjs");

var _react = _interopRequireDefault(require("react"));

var _operators = require("rxjs/operators");

var HeightListener = function () {
  function HeightListener() {
    (0, _classCallCheck2.default)(this, HeightListener);
    this.height$ = null;
    this.height$ = new _rxjs.BehaviorSubject('50vh');
  }

  (0, _createClass2.default)(HeightListener, [{
    key: "nextHeight",
    value: function nextHeight(height) {
      this.height$.next(height);
    }
  }], [{
    key: "getInstance",
    value: function getInstance() {
      if (!HeightListener.instance) {
        HeightListener.instance = new HeightListener();
      }

      return HeightListener.instance;
    }
  }]);
  return HeightListener;
}();

HeightListener.instance = null;
var instance = HeightListener.getInstance();
var _default = instance;
exports.default = _default;

var useSideMenuHeight = function useSideMenuHeight() {
  var _React$useState = _react.default.useState('50vh'),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      height = _React$useState2[0],
      setHeight = _React$useState2[1];

  _react.default.useEffect(function () {
    var sub = HeightListener.getInstance().height$.asObservable().pipe((0, _operators.distinctUntilChanged)()).subscribe(function (_height) {
      setHeight(_height);
    });
    return function () {
      sub.unsubscribe();
    };
  }, [setHeight]);

  return height;
};

exports.useSideMenuHeight = useSideMenuHeight;