var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _absoluteSpinner = _interopRequireDefault(require("../../../components/common/absolute-spinner"));

var _selectors = require("../../../core/tickets/selectors");

var _Redirect = _interopRequireDefault(require("../Redirect"));

var _routesConsts = require("../routes-consts");

var RecordedSessionGuard = function RecordedSessionGuard(_ref) {
  var children = _ref.children;
  var accessRecordedSessions = (0, _reactRedux.useSelector)(_selectors.selectAccessRecordedSessions);
  var isLoading = (0, _reactRedux.useSelector)(_selectors.selectRightsIsLoading);
  if (isLoading) return _react.default.createElement(_absoluteSpinner.default, null);
  if (accessRecordedSessions) return children;
  return _react.default.createElement(_Redirect.default, {
    to: _routesConsts.Routes.RECORDED_VIDEOS
  });
};

var _default = RecordedSessionGuard;
exports.default = _default;