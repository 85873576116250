Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BLOCK_USER_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.BLOCK_USER_COMPLETED;
  }
});
Object.defineProperty(exports, "BLOCK_USER_LOADING", {
  enumerable: true,
  get: function get() {
    return _constants.BLOCK_USER_LOADING;
  }
});
Object.defineProperty(exports, "FIREBASE_AUTH_STATE_CHANGED", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_AUTH_STATE_CHANGED;
  }
});
Object.defineProperty(exports, "FIREBASE_AUTH_STATE_LISTENING", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_AUTH_STATE_LISTENING;
  }
});
Object.defineProperty(exports, "FIREBASE_LOGIN_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_LOGIN_COMPLETED;
  }
});
Object.defineProperty(exports, "FIREBASE_LOGIN_FAILED", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_LOGIN_FAILED;
  }
});
Object.defineProperty(exports, "FIREBASE_LOGIN_MAIL_SENT", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_LOGIN_MAIL_SENT;
  }
});
Object.defineProperty(exports, "FIREBASE_LOGIN_IN_PROGRESS", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_LOGIN_IN_PROGRESS;
  }
});
Object.defineProperty(exports, "FIREBASE_PHONE_AUTH_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_PHONE_AUTH_COMPLETED;
  }
});
Object.defineProperty(exports, "FIREBASE_PHONE_AUTH_FAILED", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_PHONE_AUTH_FAILED;
  }
});
Object.defineProperty(exports, "FIREBASE_PHONE_AUTH_PROGRESS", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_PHONE_AUTH_PROGRESS;
  }
});
Object.defineProperty(exports, "FIREBASE_USER_PROFILE_UPDATE", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_USER_PROFILE_UPDATE;
  }
});
Object.defineProperty(exports, "LOAD_BLOCKED_USERS_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_BLOCKED_USERS_COMPLETED;
  }
});
Object.defineProperty(exports, "LOAD_BLOCKED_USERS_LOADING", {
  enumerable: true,
  get: function get() {
    return _constants.LOAD_BLOCKED_USERS_LOADING;
  }
});
Object.defineProperty(exports, "LOGIN_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.LOGIN_COMPLETED;
  }
});
Object.defineProperty(exports, "PAYMENT_TICKET_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.PAYMENT_TICKET_COMPLETED;
  }
});
Object.defineProperty(exports, "STOP_AUTH_STATE_LOADING", {
  enumerable: true,
  get: function get() {
    return _constants.STOP_AUTH_STATE_LOADING;
  }
});
Object.defineProperty(exports, "UNBLOCK_USER_COMPLETED", {
  enumerable: true,
  get: function get() {
    return _constants.UNBLOCK_USER_COMPLETED;
  }
});
Object.defineProperty(exports, "UNBLOCK_USER_LOADING", {
  enumerable: true,
  get: function get() {
    return _constants.UNBLOCK_USER_LOADING;
  }
});
Object.defineProperty(exports, "USER_INCOMPLETE_PROFILE", {
  enumerable: true,
  get: function get() {
    return _constants.USER_INCOMPLETE_PROFILE;
  }
});
Object.defineProperty(exports, "USER_LOGOUT", {
  enumerable: true,
  get: function get() {
    return _constants.USER_LOGOUT;
  }
});
Object.defineProperty(exports, "USER_UNAUTHENTICATED", {
  enumerable: true,
  get: function get() {
    return _constants.USER_UNAUTHENTICATED;
  }
});
Object.defineProperty(exports, "START_USER_LOGOUT", {
  enumerable: true,
  get: function get() {
    return _constants.START_USER_LOGOUT;
  }
});
Object.defineProperty(exports, "ASSIGNED_USER_ROLES", {
  enumerable: true,
  get: function get() {
    return _constants.ASSIGNED_USER_ROLES;
  }
});
Object.defineProperty(exports, "FIREBASE_USER_DOES_NOT_EXIST_ERROR", {
  enumerable: true,
  get: function get() {
    return _constants.FIREBASE_USER_DOES_NOT_EXIST_ERROR;
  }
});
exports.SKIP_LOGIN = exports.LOGIN_IN_PROGRESS = void 0;

var _constants = require("./constants");

var LOGIN_IN_PROGRESS = 'user/LOGIN_IN_PROGRESS';
exports.LOGIN_IN_PROGRESS = LOGIN_IN_PROGRESS;
var SKIP_LOGIN = 'user/SKIP_LOGIN';
exports.SKIP_LOGIN = SKIP_LOGIN;