var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasElapsedTime;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _dateFormatter = require("../../../../utils/date-formatter");

var NOW = new Date();

function CanvasElapsedTime(_ref) {
  var _ref$current;

  var children = _ref.children,
      _ref$startDate = _ref.startDate,
      startDate = _ref$startDate === void 0 ? NOW : _ref$startDate,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 12 : _ref$y,
      _ref$x = _ref.x,
      x = _ref$x === void 0 ? 12 : _ref$x;

  var ref = _react.default.useRef(null);

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      setRender = _React$useState2[1];

  _react.default.useEffect(function () {
    var interval = setInterval(function () {
      setRender(function (old) {
        return !old;
      });
    }, 1000);
    return function () {
      clearInterval(interval);
    };
  }, [setRender]);

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactKonva.Label, {
    ref: ref,
    x: x,
    y: y
  }, _react.default.createElement(_reactKonva.Tag, {
    fill: "black",
    shadowColor: 'black',
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowOpacity: 0.4
  }), _react.default.createElement(_reactKonva.Text, {
    padding: 4,
    height: 20,
    fontSize: 14,
    fill: 'white',
    text: (0, _dateFormatter.getElapsedSeconds)(startDate) || '0s',
    align: "center"
  })), children && children(((_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.width()) + x));
}