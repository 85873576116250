var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RemoteOrLocalVideo;

var _react = _interopRequireDefault(require("react"));

var _LocalVideo = _interopRequireDefault(require("./LocalVideo"));

var _RemoteVideo = _interopRequireDefault(require("./RemoteVideo"));

function RemoteOrLocalVideo(_ref) {
  var children = _ref.children,
      isLocal = _ref.isLocal,
      width = _ref.width,
      height = _ref.height,
      videoTrack = _ref.videoTrack,
      absoluteFill = _ref.absoluteFill;
  if (isLocal) return _react.default.createElement(_LocalVideo.default, {
    children: children,
    width: width,
    height: height,
    playingAnotherPlace: true
  });
  return _react.default.createElement(_RemoteVideo.default, {
    children: children,
    width: width,
    height: height,
    videoTrack: videoTrack,
    absoluteFill: absoluteFill
  });
}