var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _pageStyles = _interopRequireDefault(require("./page-styles"));

function Page(_ref) {
  var children = _ref.children;
  var styles = (0, _pageStyles.default)();
  return _react.default.createElement(_reactNative.View, {
    style: styles.page
  }, children);
}

var _default = Page;
exports.default = _default;