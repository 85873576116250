var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Page404Container;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _useHeader = _interopRequireDefault(require("../../utils/hooks/useHeader"));

var _page404Styles = _interopRequireDefault(require("./page404-styles"));

var _i18n = _interopRequireDefault(require("../../utils/i18n"));

function Page404Container() {
  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });
  return _react.default.createElement(_reactNative.View, {
    style: _page404Styles.default.container
  }, _react.default.createElement(_reactNative.Text, {
    style: _page404Styles.default.error
  }, _i18n.default.t('errors.error404')), _react.default.createElement(_reactNative.Text, {
    style: _page404Styles.default.errorMessage
  }, _i18n.default.t('errors.eventNotExists')));
}