var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Layout;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactUse = require("react-use");

var _DesignSystem = require("../../../theme/DesignSystem");

var _LayoutStrategies = require("./LayoutStrategies");

var _agoraConstants = require("../../../constants/agora-constants");

function Layout(_ref) {
  var children = _ref.children,
      items = _ref.items,
      _ref$aspectRatio = _ref.aspectRatio,
      aspectRatio = _ref$aspectRatio === void 0 ? _agoraConstants.ASPECT_RATIO : _ref$aspectRatio,
      _ref$hasScreenShare = _ref.hasScreenShare,
      hasScreenShare = _ref$hasScreenShare === void 0 ? false : _ref$hasScreenShare;

  var _React$useState = _react.default.useState(new _LayoutStrategies.GridLayoutStrategy()),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      strategy = _React$useState2[0],
      setStrategy = _React$useState2[1];

  var _React$useState3 = _react.default.useState([]),
      _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
      grids = _React$useState4[0],
      setGrids = _React$useState4[1];

  var _useMeasure = (0, _reactUse.useMeasure)(),
      _useMeasure2 = (0, _slicedToArray2.default)(_useMeasure, 2),
      ref = _useMeasure2[0],
      containerWidth = _useMeasure2[1].width;

  var _React$useState5 = _react.default.useState(function () {
    return containerWidth / aspectRatio || 0;
  }),
      _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
      containerHeight = _React$useState6[0],
      setContainerHeight = _React$useState6[1];

  var br = (0, _DesignSystem.useCurrentBreakpoint)();

  _react.default.useEffect(function () {
    if (hasScreenShare) {
      setStrategy(new _LayoutStrategies.ScreenShareWithParticipantsLayoutStrategy());
    } else {
      setStrategy(new _LayoutStrategies.GridLayoutStrategy());
    }
  }, [hasScreenShare, setStrategy]);

  _react.default.useEffect(function () {
    strategy.setBreakpoint(br);

    var _strategy$calculate = strategy.calculate(items, containerWidth),
        result = _strategy$calculate.grids,
        newContainerHeight = _strategy$calculate.containerHeight;

    setGrids(result);
    if (containerHeight !== newContainerHeight) setContainerHeight(newContainerHeight);
  }, [containerWidth, items, strategy, setGrids, setContainerHeight, br]);

  return _react.default.createElement("div", {
    style: {
      height: containerHeight || 0,
      position: 'relative',
      width: '100%'
    },
    ref: ref
  }, children({
    grids: grids,
    changeStrategy: function changeStrategy(strategy) {
      return setStrategy(strategy);
    },
    currentLayout: strategy.TYPE
  }));
}