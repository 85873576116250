Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createObserver;

function createObserver() {
  var log = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
  var listeners = [];
  return {
    subscribe: function subscribe(listener) {
      listeners.push(listener);
      log("A subscribe occurred!");
      return function () {
        log("An unsubscribe occurred!");
        listeners.filter(function (l) {
          return l !== listener;
        });
      };
    },
    publish: function publish(event) {
      log("An emit occurred! Event: ".concat(JSON.stringify(event), ", origin: ").concat(event.origin));
      listeners.forEach(function (l) {
        return l(event);
      });
    }
  };
}