var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RemoteOrLocalAudio;

var _react = _interopRequireDefault(require("react"));

var _LocalAudio = _interopRequireDefault(require("./LocalAudio"));

var _RemoteAudio = _interopRequireDefault(require("./RemoteAudio"));

function RemoteOrLocalAudio(_ref) {
  var children = _ref.children,
      isLocal = _ref.isLocal,
      audioTrack = _ref.audioTrack;
  if (isLocal) return _react.default.createElement(_LocalAudio.default, {
    children: children
  });
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_RemoteAudio.default, {
    audioTrack: audioTrack
  }), !!children ? children : null);
}