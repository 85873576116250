var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _footer = _interopRequireDefault(require("../../common/footer"));

var _expos = _interopRequireDefault(require("../../expos"));

var _expoPageStyles = _interopRequireDefault(require("./expo-page-styles"));

var ExposPageContainer = function ExposPageContainer() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var Styles = (0, _expoPageStyles.default)();
  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: {
      minHeight: '100%',
      flexGrow: 1
    }
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "stretch",
    flex: 1,
    pSpacing: ['xl', 'xxl'],
    style: Styles.container
  }, _react.default.createElement(_expos.default, null)), _react.default.createElement(_footer.default, null));
};

var _default = ExposPageContainer;
exports.default = _default;