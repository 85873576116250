Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SELECTED_STAGE_CHANGED = exports.STAGE_STATUS_CHANGED = exports.LISTEN_STAGE_CHANGES_BY_ID = exports.GET_EVENT_STAGES = exports.NAME = void 0;
var NAME = 'eventStages';
exports.NAME = NAME;
var GET_EVENT_STAGES = 'eventStages/GET_STAGES';
exports.GET_EVENT_STAGES = GET_EVENT_STAGES;
var LISTEN_STAGE_CHANGES_BY_ID = 'eventStages/LISTEN_STAGE_CHANGES_BY_ID';
exports.LISTEN_STAGE_CHANGES_BY_ID = LISTEN_STAGE_CHANGES_BY_ID;
var STAGE_STATUS_CHANGED = 'eventStages/STAGE_STATUS_CHANGED';
exports.STAGE_STATUS_CHANGED = STAGE_STATUS_CHANGED;
var SELECTED_STAGE_CHANGED = 'eventStages/SELECTED_STAGE_CHANGED';
exports.SELECTED_STAGE_CHANGED = SELECTED_STAGE_CHANGED;