Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  slimButtonIcon: {
    fontSize: 12
  },
  largeButtonIcon: {
    fontSize: 20,
    width: 20,
    height: 20,
    marginBottom: 2
  },
  largeButton: {
    lineHeight: 32,
    letterSpacing: 0,
    fontSize: 16,
    marginBottom: 10
  }
});

exports.default = _default;