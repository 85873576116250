Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _DesignSystem = require("../../../theme/DesignSystem");

var useSpeakerStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  container: {
    width: '100%',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexBasis: 'auto'
  },
  avatar: {
    borderRadius: _reactNative.Platform.OS === 'web' ? '50%' : 68,
    width: 135,
    height: 135
  }
}).addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginBottom: theme.spacing.xl
    },
    avatar: {
      marginBottom: theme.spacing.l
    }
  };
}).buildHook();
var _default = useSpeakerStyles;
exports.default = _default;