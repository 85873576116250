Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectBenefitsLength = exports.selectBenefitsIsLoaded = exports.selectBenefitsIsLoading = exports.selectBenefitsList = void 0;

var _reducer = require("./reducer");

var _constants = require("./constants");

var _toolkit = require("@reduxjs/toolkit");

var selectBenefitsState = function selectBenefitsState(state) {
  return state[_constants.NAME];
};

var selectBenefitsList = _reducer.benefitsEntityAdapter.getSelectors(selectBenefitsState).selectAll;

exports.selectBenefitsList = selectBenefitsList;
var selectBenefitsIsLoading = (0, _toolkit.createSelector)(selectBenefitsState, function (state) {
  return state.loading;
});
exports.selectBenefitsIsLoading = selectBenefitsIsLoading;
var selectBenefitsIsLoaded = (0, _toolkit.createSelector)(selectBenefitsState, function (state) {
  return state.loaded;
});
exports.selectBenefitsIsLoaded = selectBenefitsIsLoaded;
var selectBenefitsLength = (0, _toolkit.createSelector)(selectBenefitsList, function (state) {
  return state.length;
});
exports.selectBenefitsLength = selectBenefitsLength;