var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var _constants = require("./constants");

var InitialState = _immutable.default.fromJS({
  readyState: 'open',
  numUsers: 0,
  numTotalUsers: 0,
  users: _immutable.default.List(),
  liveId: null
});

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.JOIN:
      newState = newState.set('readyState', _constants.CONNECTION_STATUS.CONNECTING);
      newState = newState.set('liveId', action.liveId);
      break;

    case _actionTypes.JOIN_COMPLETED:
      newState = newState.set('readyState', _constants.CONNECTION_STATUS.CONNECTED);
      newState = newState.set('liveId', action.liveId);
      break;

    case _actionTypes.REFRESH_LISTENERS:
      newState = newState.set('numUsers', action.numUsers);
      newState = newState.set('numTotalUsers', action.numTotalUsers);
      break;

    case _actionTypes.LOAD_USERS_COMPLETED:
      newState = newState.set('users', _immutable.default.fromJS(action.users));
      break;

    case _actionTypes.LEAVE:
      newState = InitialState;
      break;

    default:
      break;
  }

  return newState;
}