Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../theme/DesignSystem");

var useChatStyles = new _DesignSystem.MakeStyle().addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.sm;
}, function () {
  return {
    position: {
      display: 'none'
    }
  };
}).buildHook();
var _default = useChatStyles;
exports.default = _default;