var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Avatars;
exports.AVATAR_SIZE = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _avatarsStyles = _interopRequireDefault(require("./avatars-styles"));

var AVATAR_SIZE = {
  SMALL: 32,
  MEDIUM: 50,
  BIG: 64
};
exports.AVATAR_SIZE = AVATAR_SIZE;

function Avatars(_ref) {
  var urls = _ref.urls,
      _onPress = _ref.onPress,
      style = _ref.style,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? AVATAR_SIZE.SMALL : _ref$size,
      _ref$isCentered = _ref.isCentered,
      isCentered = _ref$isCentered === void 0 ? true : _ref$isCentered;

  var _React$useState = _react.default.useState(null),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      selected = _React$useState2[0],
      setSelected = _React$useState2[1];

  var Styles = (0, _avatarsStyles.default)();
  var avatars = urls === null || urls === void 0 ? void 0 : urls.map(function (_ref2, index) {
    var id = _ref2.id,
        photo = _ref2.photo;
    return _react.default.createElement(_reactNative.TouchableHighlight, {
      key: id,
      style: [Styles.avatarContainer, {
        marginLeft: -5,
        zIndex: selected === index ? urls.length + 1 : index + 1,
        height: size
      }],
      underlayColor: "#FFFFFF",
      onMouseEnter: function onMouseEnter() {
        return setSelected(index);
      },
      onMouseLeave: function onMouseLeave() {
        return setSelected(null);
      },
      onPress: function onPress() {
        return _onPress && _onPress(id);
      }
    }, _react.default.createElement(_reactNative.Image, {
      style: [Styles.avatar, {
        height: size,
        width: size
      }],
      source: {
        uri: photo
      }
    }));
  });
  return _react.default.createElement(_reactNative.View, {
    style: [Styles.container, {
      flexDirection: 'row',
      paddingLeft: 5,
      flexWrap: 'wrap',
      justifyContent: isCentered ? 'center' : 'flex-start'
    }, style]
  }, avatars);
}