var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/event-info/selectors");

var _selectors2 = require("../../../core/event-stages/selectors");

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _liveNowPageStyles = _interopRequireDefault(require("./live-now-page-styles"));

var _liveNowSingle = _interopRequireDefault(require("./live-now-single"));

var _styles = _interopRequireDefault(require("./styles"));

var LiveNowList = function LiveNowList() {
  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });
  var stages = (0, _reactRedux.useSelector)(_selectors2.selectEventStagesWithSessions, _reactRedux.shallowEqual);
  var eventInfo = (0, _reactRedux.useSelector)(_selectors.selectEventInfo);
  var Styles = (0, _liveNowPageStyles.default)();
  var classes = (0, _styles.default)();
  var now = new Date();
  var startDate = new Date(eventInfo.startDate);
  var endDate = new Date(eventInfo.endDate);
  var liveNows = stages.map(function (stage) {
    return stage.sessions.length === 0 ? null : _react.default.createElement(_liveNowSingle.default, {
      stage: stage,
      key: stage.id
    });
  });
  return _react.default.createElement(_react.default.Fragment, null, liveNows.filter(function (s) {
    return s !== null;
  }).length ? _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_Spacing.default, {
    mSpacing: [0, 0, 'xl', 0],
    style: Styles.header
  }, now < startDate && _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.textUppercase
  }, _i18n.default.t('liveNow.upcoming')), now >= startDate && now <= endDate && _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.textUppercase
  }, _i18n.default.t('leftMenu.liveNow')), now > startDate && now > endDate && _react.default.createElement(_core.Typography, {
    variant: "h2",
    className: classes.textUppercase
  }, _i18n.default.t('liveNow.past'))), _react.default.createElement(_Spacing.default, {
    style: Styles.innerContainer
  }, liveNows)) : _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.t('liveNow.noLiveVideo')));
};

var _default = LiveNowList;
exports.default = _default;