var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useIsDateBetween;
exports.STATUS = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _useTimeout = _interopRequireDefault(require("./useTimeout"));

var STATUS = {
  BEFORE: 'BEFORE',
  DURING: 'DURING',
  AFTER: 'AFTER',
  LOADING: 'LOADING'
};
exports.STATUS = STATUS;

function useIsDateBetween(startDate, endDate) {
  var _React$useState = _react.default.useState(STATUS.LOADING),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      status = _React$useState2[0],
      setStatus = _React$useState2[1];

  var calculateState = _react.default.useCallback(function () {
    var now = new Date();

    if (startDate > now) {
      setStatus(STATUS.BEFORE);
    }

    if (endDate < now) {
      setStatus(STATUS.AFTER);
    }

    setStatus(STATUS.DURING);
  }, [setStatus, startDate, endDate]);

  _react.default.useEffect(function () {
    calculateState();
  }, [calculateState]);

  var startDateTimeout = _react.default.useMemo(function () {
    if (startDate > new Date()) {
      return startDate - new Date();
    }

    return 0;
  }, [startDate]);

  var endDateTimeout = _react.default.useMemo(function () {
    if (endDate > new Date()) {
      return endDate - new Date();
    }

    return 0;
  }, [endDate]);

  (0, _useTimeout.default)(calculateState, startDateTimeout);
  (0, _useTimeout.default)(calculateState, endDateTimeout);
  return status;
}