var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _reactStripeJs = require("@stripe/react-stripe-js");

var _formik = require("formik");

var _react = _interopRequireDefault(require("react"));

var _DesignSystem = require("../../../../theme/DesignSystem");

var _countries = _interopRequireDefault(require("../../../../utils/countries"));

var _checkoutStepFormDetailsStyle = _interopRequireDefault(require("./checkout-step-form-details-style"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _reactFeather = require("react-feather");

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

var _baseSelect = _interopRequireDefault(require("../../../../theme/components/inputs/baseSelect.web"));

var _checkoutStepUtils = require("./checkout-step-utils");

var useStyles = (0, _core.makeStyles)(function (theme) {
  return {
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      marginBottom: 16,
      flex: 1
    },
    cardElementContainer: {
      borderColor: theme.palette.alternativeText.main,
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: 2,
      padding: 15,
      marginTop: 16
    },
    countries: {
      display: 'flex',
      flexDirection: 'column'
    },
    twoColumns: {
      display: 'flex',
      flexDirection: 'row'
    }
  };
});

function CheckoutStepFormDetails() {
  var styles = (0, _checkoutStepFormDetailsStyle.default)();

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors,
      typography = _useTheme.typography;

  var classes = useStyles();

  var _useFormikContext = (0, _formik.useFormikContext)(),
      setErrors = _useFormikContext.setErrors,
      errors = _useFormikContext.errors,
      touched = _useFormikContext.touched,
      handleChange = _useFormikContext.handleChange,
      values = _useFormikContext.values,
      setFieldTouched = _useFormikContext.setFieldTouched;

  var countries = (0, _countries.default)();
  var countriesForBillingAddress = countries.map(function (_ref) {
    var id = _ref.id,
        value = _ref.value;
    return {
      id: id,
      value: value
    };
  });
  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_checkoutStepUtils.InputLabel, {
    label: "billing.cardDetails"
  }), _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    type: "text",
    color: "alternativeText",
    value: values.cardholderName,
    onChange: handleChange,
    name: "cardholderName",
    returnKeyType: 'next',
    error: !!errors.cardholderName && touched.cardholderName,
    placeholder: "John Doe",
    leftIcon: _react.default.createElement(_reactFeather.User, null),
    dense: true
  }), !!errors.cardholderName && touched.cardholderName && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.cardholderName
  }), _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_core.Box, {
    className: classes.cardElementContainer
  }, _react.default.createElement(_reactStripeJs.CardElement, {
    onReady: function onReady() {
      setErrors({
        card: null
      });
    },
    onChange: function onChange(e) {
      if (e.complete) {
        setErrors({
          card: ''
        });
      } else {
        setErrors({
          card: _i18n.default.t('billing.error.invalid_number')
        });
      }
    },
    options: {
      placeholder: _i18n.default.translate('billing.create.cardNumberPlaceholder'),
      style: {
        base: {
          color: colors.alternativeText,
          fontSize: typography.fontSize,
          '::placeholder': {
            color: colors.alternativeText
          }
        }
      },
      hidePostalCode: true
    }
  })), !!errors.card && errors.card !== null && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.card
  }))), _react.default.createElement(_checkoutStepUtils.InputLabel, {
    label: "billing.billingInformation"
  }), _react.default.createElement(_core.Box, {
    className: classes.countries
  }, _react.default.createElement(_baseSelect.default, {
    sendEntireEvent: true,
    rightIcon: _react.default.createElement(_reactFeather.ChevronDown, null),
    leftIcon: _react.default.createElement(_reactFeather.Globe, null),
    options: countriesForBillingAddress,
    value: values === null || values === void 0 ? void 0 : values.country,
    onChange: handleChange,
    name: "country",
    hasEmptyOption: false,
    color: "alternativeText",
    dense: true
  }), !!errors.country && touched.country && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.country
  })), _react.default.createElement(_core.Box, {
    style: {
      margin: '16px 0px'
    }
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    type: "text",
    color: "alternativeText",
    value: values.addressLine1,
    onChange: handleChange,
    name: "addressLine1",
    returnKeyType: 'next',
    error: !!errors.addressLine1 && touched.addressLine1,
    placeholder: _i18n.default.t('billing.create.addressLine1Placeholder'),
    leftIcon: _react.default.createElement(_reactFeather.Home, null),
    dense: true
  }), !!errors.addressLine1 && touched.addressLine1 && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.addressLine1
  })), _react.default.createElement(_core.Box, {
    className: classes.twoColumns
  }, _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    type: "text",
    color: "alternativeText",
    value: values.postalCode,
    onChange: handleChange,
    name: "postalCode",
    returnKeyType: 'next',
    error: !!errors.postalCode && touched.postalCode,
    placeholder: _i18n.default.t('billing.create.postalCodePlaceholder'),
    style: {
      marginRight: 8
    },
    className: styles.billingAdress,
    dense: true
  }), !!errors.postalCode && touched.postalCode && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.postalCode
  })), _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    type: "text",
    color: "alternativeText",
    value: values.city,
    onChange: handleChange,
    name: "city",
    returnKeyType: 'next',
    error: !!errors.city && touched.city,
    placeholder: _i18n.default.t('billing.create.cityPlaceholder'),
    className: styles.billingAdress,
    style: {
      marginRight: 8
    },
    dense: true
  }), !!errors.city && touched.city && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.city
  }))), _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    type: "email",
    color: "alternativeText",
    value: values.email,
    onChange: handleChange,
    name: "email",
    returnKeyType: 'next',
    error: !!errors.email && touched.email,
    onBlur: function onBlur() {
      return setFieldTouched('email');
    },
    placeholder: 'john@doe.com',
    leftIcon: _react.default.createElement(_reactFeather.Mail, null),
    disabled: true,
    dense: true
  }), !!errors.email && touched.email && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.email
  })), _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    type: "text",
    color: "alternativeText",
    value: values.companyName,
    onChange: handleChange,
    name: "companyName",
    returnKeyType: 'next',
    placeholder: _i18n.default.t('billing.create.companyNamePlaceholder'),
    leftIcon: _react.default.createElement(_reactFeather.Briefcase, null),
    dense: true
  })), _react.default.createElement(_core.Box, {
    className: classes.inputContainer
  }, _react.default.createElement(_Input.default, {
    shaped: true,
    type: "text",
    color: "alternativeText",
    value: values.taxIdNumber,
    onChange: handleChange,
    name: "taxIdNumber",
    returnKeyType: 'next',
    placeholder: _i18n.default.t('billing.create.taxIdNumberPlaceholder'),
    dense: true
  }), !!errors.taxIdNumber && touched.taxIdNumber && _react.default.createElement(_checkoutStepUtils.ErrorBlock, {
    errorMessage: errors.taxIdNumber
  })));
}

var _default = CheckoutStepFormDetails;
exports.default = _default;