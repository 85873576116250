Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _default = _reactNative.StyleSheet.create({
  container: {
    width: '100vw',
    height: '100vw',
    paddingTop: '10%'
  },
  error: {
    color: '#999',
    fontSize: 70,
    fontWeight: 'bold'
  },
  errorMessage: {
    fontSize: 30
  }
});

exports.default = _default;