Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _styles = require("@material-ui/core/styles");

var styles = (0, _styles.makeStyles)(function (theme) {
  return {
    background: {
      backgroundColor: theme.palette.containerBackground.main,
      minHeight: 0
    },
    container: {
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.containerBackground.main,
      minHeight: 0
    },
    '@media(max-width: 768px)': {
      landingPage: {
        display: 'inline',
        width: '100vw',
        borderWidth: 0,
        borderRadius: 0
      }
    },
    minHeightContainer: {
      minHeight: 0
    },
    ticketIcon: {
      '& > div': {
        width: 22,
        height: 22,
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
      }
    }
  };
});
var _default = styles;
exports.default = _default;