Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useChatUserProfileStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 32
  },
  social: {
    cursor: 'pointer'
  }
}).buildHook();
var _default = useChatUserProfileStyles;
exports.default = _default;