var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _TooltipBubble = _interopRequireDefault(require("./TooltipBubble.styles"));

var TooltipBubble = function TooltipBubble(_ref) {
  var children = _ref.children,
      arrowDirection = _ref.arrowDirection;
  var Styles = (0, _TooltipBubble.default)();
  var arrowStyles = '';

  switch (arrowDirection) {
    case 'bottom-end':
    case 'bottom-start':
    case 'bottom':
      arrowStyles = [Styles.triangle, Styles.triangleBottom, Styles[arrowDirection]];
      break;

    case 'left-end':
    case 'left-start':
    case 'left':
      arrowStyles = [Styles.triangle, Styles.triangleLeft, Styles[arrowDirection]];
      break;

    case 'right-end':
    case 'right-start':
    case 'right':
      arrowStyles = [Styles.triangle, Styles.triangleRight, Styles[arrowDirection]];
      break;

    case 'top-end':
    case 'top-start':
    case 'top':
      arrowStyles = [Styles.triangle, Styles.triangleTop, Styles[arrowDirection]];
      break;

    default:
      break;
  }

  return _react.default.createElement(_Spacing.default, {
    shaping: "cards",
    color: "#FFF",
    style: Styles.root
  }, _react.default.createElement(_Spacing.default, {
    style: arrowStyles
  }), children);
};

var _default = TooltipBubble;
exports.default = _default;