var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSpecificPlayerData = exports.getSpecificPlayerBeatsBalance = exports.getSpecificPlayerTotalBeats = exports.isSpecificPlayerDataLoading = exports.isLeaderboardLoading = exports.isLastMonthLeaderboard = exports.getLeaderboardTotal = exports.getLeaderboard = exports.getLeaderboardEntryBeats = exports.getLeaderboardEntryRank = exports.getLeaderboardEntryData = exports.isLeaderboardEntryLoading = exports.getCurrentLeaderboardBeats = exports.getLeaderboardData = exports.getLeaderboardRank = exports.getSavedDjLeaderboardRank = exports.getDjPlayerId = exports.getApiUserId = exports.geTotaltBeatsBalance = exports.getBeatsBalance = exports.getNewLiveBeatsMessage = exports.getNewLiveBeatsValue = exports.getNewDjReferralBeatsMessage = exports.getNewReferralBeatsMessage = exports.getNewDjReferralBeatsValue = exports.getNewReferralBeatsValue = exports.getMyBeatsInfoScreenProps = exports.isGsPropertySetLoading = exports.getLeaderboardUnit = exports.getLeaderboarLearnMore = exports.getTotalPrize = exports.getGSError = exports.getGSDeviceId = exports.getGSPlayerId = exports.getGSAccessToken = void 0;

var _constants = require("./constants");

var _profile = _interopRequireDefault(require("../profile"));

var getGSAccessToken = function getGSAccessToken(state) {
  return state[_constants.NAME].get('gsAccessToken');
};

exports.getGSAccessToken = getGSAccessToken;

var getGSPlayerId = function getGSPlayerId(state) {
  return state[_constants.NAME].get('gsPlayerId');
};

exports.getGSPlayerId = getGSPlayerId;

var getGSDeviceId = function getGSDeviceId(state) {
  return state[_constants.NAME].get('gsDeviceId');
};

exports.getGSDeviceId = getGSDeviceId;

var getGSError = function getGSError(state) {
  return state[_constants.NAME].get('gsError');
};

exports.getGSError = getGSError;

var getTotalPrize = function getTotalPrize(state) {
  return state[_constants.NAME].getIn(['properties', _constants.TOTAL_PRIZE]) || state[_constants.NAME].getIn(['properties', _constants.LEADERBOARD_SCREEN_PROPS, 'totalPrize', 'value']);
};

exports.getTotalPrize = getTotalPrize;

var getLeaderboarLearnMore = function getLeaderboarLearnMore(state) {
  return state[_constants.NAME].getIn(['properties', _constants.LEADERBOARD_SCREEN_PROPS, 'learnMoreScreen']);
};

exports.getLeaderboarLearnMore = getLeaderboarLearnMore;

var getLeaderboardUnit = function getLeaderboardUnit(state) {
  return state[_constants.NAME].getIn(['properties', _constants.LEADERBOARD_SCREEN_PROPS, 'leaderboardUnit', 'value']);
};

exports.getLeaderboardUnit = getLeaderboardUnit;

var isGsPropertySetLoading = function isGsPropertySetLoading(state) {
  return state[_constants.NAME].get('isGsPropertySetLoading') || false;
};

exports.isGsPropertySetLoading = isGsPropertySetLoading;

var getMyBeatsInfoScreenProps = function getMyBeatsInfoScreenProps(state) {
  return state[_constants.NAME].getIn(['properties', _constants.MY_BEATS_SCREEN_PROPERTIES, 'infoScreenProps']);
};

exports.getMyBeatsInfoScreenProps = getMyBeatsInfoScreenProps;

var getNewReferralBeatsValue = function getNewReferralBeatsValue(state) {
  return state[_constants.NAME].getIn(['properties', _constants.REFERRAL_BEATS]) || state[_constants.NAME].getIn(['properties', _constants.MY_BEATS_SCREEN_PROPERTIES, 'referralBeats', 'value']);
};

exports.getNewReferralBeatsValue = getNewReferralBeatsValue;

var getNewDjReferralBeatsValue = function getNewDjReferralBeatsValue(state) {
  return state[_constants.NAME].getIn(['properties', _constants.REFERRAL_BEATS_NEW_DJ]) || state[_constants.NAME].getIn(['properties', _constants.MY_BEATS_SCREEN_PROPERTIES, 'djReferralBeats', 'value']);
};

exports.getNewDjReferralBeatsValue = getNewDjReferralBeatsValue;

var getNewReferralBeatsMessage = function getNewReferralBeatsMessage(state) {
  return state[_constants.NAME].getIn(['properties', _constants.MY_BEATS_SCREEN_PROPERTIES, 'referralMessage', 'value']);
};

exports.getNewReferralBeatsMessage = getNewReferralBeatsMessage;

var getNewDjReferralBeatsMessage = function getNewDjReferralBeatsMessage(state) {
  return state[_constants.NAME].getIn(['properties', _constants.MY_BEATS_SCREEN_PROPERTIES, 'djReferralMessage', 'value']);
};

exports.getNewDjReferralBeatsMessage = getNewDjReferralBeatsMessage;

var getNewLiveBeatsValue = function getNewLiveBeatsValue(state) {
  return state[_constants.NAME].getIn(['properties', _constants.MY_BEATS_SCREEN_PROPERTIES, 'liveReferralBeats', 'value']);
};

exports.getNewLiveBeatsValue = getNewLiveBeatsValue;

var getNewLiveBeatsMessage = function getNewLiveBeatsMessage(state) {
  return state[_constants.NAME].getIn(['properties', _constants.MY_BEATS_SCREEN_PROPERTIES, 'liveReferralMessage', 'value']);
};

exports.getNewLiveBeatsMessage = getNewLiveBeatsMessage;

var getBeatsBalance = function getBeatsBalance(state) {
  return state[_constants.NAME].get('beatsBalance');
};

exports.getBeatsBalance = getBeatsBalance;

var geTotaltBeatsBalance = function geTotaltBeatsBalance(state) {
  return state[_constants.NAME].get('totalBalance');
};

exports.geTotaltBeatsBalance = geTotaltBeatsBalance;

var getApiUserId = function getApiUserId(state) {
  return state[_constants.NAME].get('apiUserId');
};

exports.getApiUserId = getApiUserId;

var getDjPlayerId = function getDjPlayerId(state, djId) {
  return _profile.default.selectors.getUserPlayerId(state, djId);
};

exports.getDjPlayerId = getDjPlayerId;

var getSavedDjLeaderboardRank = function getSavedDjLeaderboardRank(state, djId) {
  var filtered = state[_constants.NAME].get('leaderboard').find(function (player) {
    return player.userId === getDjPlayerId(state, djId);
  });

  var rank = filtered && filtered.rank;
  return rank || 0;
};

exports.getSavedDjLeaderboardRank = getSavedDjLeaderboardRank;

var getLeaderboardRank = function getLeaderboardRank(state, djId) {
  return state[_constants.NAME].getIn(['leaderboardPlayerData', 'rank']);
};

exports.getLeaderboardRank = getLeaderboardRank;

var getLeaderboardData = function getLeaderboardData(state) {
  return state[_constants.NAME].get('leaderboardPlayerData');
};

exports.getLeaderboardData = getLeaderboardData;

var getCurrentLeaderboardBeats = function getCurrentLeaderboardBeats(state) {
  return state[_constants.NAME].getIn(['leaderboardPlayerData', 'SUM-value']);
};

exports.getCurrentLeaderboardBeats = getCurrentLeaderboardBeats;

var isLeaderboardEntryLoading = function isLeaderboardEntryLoading(state) {
  return state[_constants.NAME].get('isLeaderboardEntryLoading');
};

exports.isLeaderboardEntryLoading = isLeaderboardEntryLoading;

var getLeaderboardEntryData = function getLeaderboardEntryData(state) {
  return state[_constants.NAME].get('leaderboardEntry');
};

exports.getLeaderboardEntryData = getLeaderboardEntryData;

var getLeaderboardEntryRank = function getLeaderboardEntryRank(state) {
  return state[_constants.NAME].getIn(['leaderboardEntry', 'rank']);
};

exports.getLeaderboardEntryRank = getLeaderboardEntryRank;

var getLeaderboardEntryBeats = function getLeaderboardEntryBeats(state) {
  return state[_constants.NAME].getIn(['leaderboardEntry', 'SUM-value']);
};

exports.getLeaderboardEntryBeats = getLeaderboardEntryBeats;

var getLeaderboard = function getLeaderboard(state) {
  return state[_constants.NAME].get('leaderboard');
};

exports.getLeaderboard = getLeaderboard;

var getLeaderboardTotal = function getLeaderboardTotal(state) {
  return state[_constants.NAME].get('leaderboardTotal');
};

exports.getLeaderboardTotal = getLeaderboardTotal;

var isLastMonthLeaderboard = function isLastMonthLeaderboard(state) {
  return state[_constants.NAME].get('isLastMonthLeaderboard');
};

exports.isLastMonthLeaderboard = isLastMonthLeaderboard;

var isLeaderboardLoading = function isLeaderboardLoading(state) {
  return state[_constants.NAME].get('isLeaderboardLoading');
};

exports.isLeaderboardLoading = isLeaderboardLoading;

var isSpecificPlayerDataLoading = function isSpecificPlayerDataLoading(state) {
  return state[_constants.NAME].get('isSpecificPlayerLoading');
};

exports.isSpecificPlayerDataLoading = isSpecificPlayerDataLoading;

var getSpecificPlayerTotalBeats = function getSpecificPlayerTotalBeats(state, playerId) {
  return state[_constants.NAME].getIn([playerId, 'totalBeats']);
};

exports.getSpecificPlayerTotalBeats = getSpecificPlayerTotalBeats;

var getSpecificPlayerBeatsBalance = function getSpecificPlayerBeatsBalance(state, playerId) {
  return state[_constants.NAME].getIn([playerId, 'beatsBalance']);
};

exports.getSpecificPlayerBeatsBalance = getSpecificPlayerBeatsBalance;

var getSpecificPlayerData = function getSpecificPlayerData(state, playerId) {
  return state[_constants.NAME].getIn([playerId, 'playerData']);
};

exports.getSpecificPlayerData = getSpecificPlayerData;