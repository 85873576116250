var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactRedux = require("react-redux");

var _reactNavigationHooks = require("react-navigation-hooks");

var _backButton = _interopRequireDefault(require("../../auth/back-button"));

var _eventLogo = _interopRequireDefault(require("../../common/event-logo"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _selectors = require("../../../core/testimonials/selectors");

var _testimonialView = _interopRequireDefault(require("../testimonial/testimonial-view"));

var _core = require("@material-ui/core");

var _styles = _interopRequireDefault(require("./styles"));

var TestimonialPage = function TestimonialPage() {
  var classes = (0, _styles.default)();
  var testimonialId = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.TESTIMONIAL_ID);
  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: false
  });
  var testimonial = (0, _reactRedux.useSelector)(function (state) {
    return (0, _selectors.selectTestimonialById)(state, testimonialId);
  });
  return _react.default.createElement(_core.Box, {
    p: 1,
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    className: classes.pageBg
  }, _react.default.createElement(_core.Box, {
    mt: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    className: classes.container
  }, _react.default.createElement(_backButton.default, null), _react.default.createElement(_eventLogo.default, null), testimonial ? _react.default.createElement(_testimonialView.default, (0, _extends2.default)({
    noClip: true
  }, testimonial)) : _react.default.createElement(_core.Paper, {
    elevation: 0
  }, _react.default.createElement(_core.Box, {
    m: 3
  }, _react.default.createElement(_reactNative.ActivityIndicator, {
    size: "large"
  })))));
};

var _default = TestimonialPage;
exports.default = _default;