var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _TextButton = _interopRequireDefault(require("../../../theme/components/buttons/TextButton"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _reactFeather = require("react-feather");

var _core = require("@material-ui/core");

var HomeButton = function HomeButton() {
  var navigation = (0, _useNavigationExtended.default)();
  return _react.default.createElement(_TextButton.default, {
    iconLeft: _react.default.createElement(_reactFeather.ArrowLeft, {
      size: '0.875rem'
    }),
    onPress: function onPress() {
      return navigation.navigate(_routesConsts.Routes.HOME);
    }
  }, _react.default.createElement(_core.Box, {
    display: "flex",
    alignItems: "center"
  }, _react.default.createElement(_core.Typography, {
    variant: "caption"
  }, _i18n.default.t('global.backToPage'))));
};

var _default = HomeButton;
exports.default = _default;