var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _routesConsts = require("../../../../navigation/client/routes-consts");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _core = require("@material-ui/core");

var _useHeader = _interopRequireDefault(require("../../../../utils/hooks/useHeader"));

var _useLiveSessionState = require("../../../../utils/hooks/useLiveSessionState");

var _useNavigationExtended = _interopRequireDefault(require("../../../../utils/hooks/useNavigationExtended"));

var _avatars = _interopRequireDefault(require("../../../common/avatars"));

var _sessionTitleStyles = _interopRequireDefault(require("./session-title-styles"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _ContainedButton = _interopRequireDefault(require("../../../../theme/components/buttons/ContainedButton"));

var _sessionUtils = require("../../../../utils/session-utils");

var _styles = _interopRequireDefault(require("./styles"));

var SessionTitleContainer = function SessionTitleContainer(_ref) {
  var stage = _ref.stage,
      dateFormat = _ref.dateFormat;
  var navigation = (0, _useNavigationExtended.default)();
  var live = (0, _useLiveSessionState.useLiveSessionState)(stage);
  var Styles = (0, _sessionTitleStyles.default)();
  var classes = (0, _styles.default)();

  var _React$useState = _react.default.useState(false),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      counter = _React$useState2[0],
      setCounter = _React$useState2[1];

  (0, _useHeader.default)({
    visible: true,
    withScroll: false,
    withLeftMenu: true
  });

  _react.default.useEffect(function () {
    var now = new Date();
    var timer = setInterval(function () {
      return setCounter(function (oldState) {
        return !oldState;
      });
    }, 1000 * (60 - now.getSeconds()));
    return function () {
      return clearInterval(timer);
    };
  }, [counter]);

  var speakersSection = null;

  if (live) {
    var currentSession = live.currentSession,
        nextSession = live.nextSession;
    var session = currentSession || nextSession;

    if (session) {
      var _getSessionDetailsFor = (0, _sessionUtils.getSessionDetailsForCalendar)(session, dateFormat),
          startHour = _getSessionDetailsFor.startHour,
          endHour = _getSessionDetailsFor.endHour,
          zoneAbbreviation = _getSessionDetailsFor.zoneAbbreviation,
          speakerUrlsAndIds = _getSessionDetailsFor.speakerUrlsAndIds;

      var navigateToSpeaker = function navigateToSpeaker(id) {
        navigation.navigate(_routesConsts.Routes.SPEAKER_PAGE, {
          speakerId: id
        });
      };

      speakersSection = _react.default.createElement(_Spacing.default, {
        direction: "column",
        alignItems: "stretch"
      }, nextSession && nextSession.isForbidden ? _react.default.createElement(_Spacing.default, {
        direction: "row",
        mSpacing: [0, 's'],
        pSpacing: "s",
        shaping: "cards",
        alignItems: "center",
        justifyContent: "space-between",
        style: Styles.noAccessText
      }, _react.default.createElement(_core.Typography, {
        variant: "caption"
      }, _i18n.default.t('liveNow.noAccessNext')), _react.default.createElement(_ContainedButton.default, {
        onPress: function onPress() {
          return navigation.navigate(_routesConsts.Routes.MY_TICKETS);
        }
      }, _i18n.default.t('liveNow.upgradeTicket'))) : null, _react.default.createElement(_Spacing.default, {
        direction: "row",
        wrap: "wrap",
        alignItems: "flex-start",
        style: Styles.speakerContainer,
        justifyContent: "flex-start"
      }, _react.default.createElement(_Spacing.default, {
        direction: "column",
        flex: 1,
        alignItems: "flex-start"
      }, _react.default.createElement(_core.Typography, {
        className: classes.title,
        variant: "h2"
      }, session.title), _react.default.createElement(_Spacing.default, {
        flex: 1
      }, _react.default.createElement(_core.Typography, {
        className: classes.subtitle
      }, startHour, " - ", endHour, " (", zoneAbbreviation, ")")), _react.default.createElement(_Spacing.default, {
        flex: 1,
        mSpacing: ['s', 0]
      }, _react.default.createElement(_core.Typography, null, session.speakers.reduce(function (accum, _ref2, i) {
        var name = _ref2.name;
        return i === 0 ? "".concat(name) : "".concat(accum, ", ").concat(name);
      }, ''))), !!speakerUrlsAndIds.length && _react.default.createElement(_avatars.default, {
        onPress: navigateToSpeaker,
        urls: speakerUrlsAndIds
      }))));
    }
  }

  return speakersSection;
};

var _default = SessionTitleContainer;
exports.default = _default;