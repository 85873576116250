var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ContactInformationStep;

var _react = _interopRequireDefault(require("react"));

var _messageBlock = _interopRequireDefault(require("../../../common/message-block"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _core = require("@material-ui/core");

var _formik = require("formik");

var _termsAndConditions = _interopRequireDefault(require("../common/terms-and-conditions"));

var _contactInformationStepStyle = _interopRequireDefault(require("./contact-information-step-style"));

var _eventRegisterProvider = require("../../event-register-provider");

var _handleFormValuesChange = _interopRequireDefault(require("../common/handle-form-values-change"));

var _contactInformationSection = _interopRequireDefault(require("./contact-information-section"));

var _additionalInformationSection = _interopRequireDefault(require("./additional-information-section"));

var _navLinks = _interopRequireDefault(require("./nav-links"));

var _Typography = _interopRequireDefault(require("../../../../theme/components/typography/Typography"));

var _reactFeather = require("react-feather");

var _Input = _interopRequireDefault(require("../../../../theme/components/inputs/Input"));

function ContactInformationStep(_ref) {
  var registrationFeedback = _ref.registrationFeedback;

  var _useEventRegisterCont = (0, _eventRegisterProvider.useEventRegisterContext)(),
      isLoggedIn = _useEventRegisterCont.isLoggedIn,
      userProfile = _useEventRegisterCont.userProfile,
      eventSlug = _useEventRegisterCont.eventSlug,
      ticketQuantity = _useEventRegisterCont.ticketQuantity;

  var classes = (0, _contactInformationStepStyle.default)();

  var renderContent = function renderContent() {
    if (isLoggedIn) {
      return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_core.Box, {
        className: classes.loggedInDataContainer
      }, _react.default.createElement(_Input.default, {
        shaped: true,
        type: "text",
        color: "alternativeText",
        value: (userProfile === null || userProfile === void 0 ? void 0 : userProfile.displayName) || (userProfile === null || userProfile === void 0 ? void 0 : userProfile.fullName),
        name: "email",
        placeholder: 'John Doe',
        leftIcon: _react.default.createElement(_reactFeather.User, null),
        disabled: true,
        dense: true
      }), _react.default.createElement(_Input.default, {
        shaped: true,
        type: "email",
        color: "alternativeText",
        value: userProfile === null || userProfile === void 0 ? void 0 : userProfile.email,
        name: "email",
        placeholder: 'john@doe.com',
        leftIcon: _react.default.createElement(_reactFeather.Mail, null),
        disabled: true,
        dense: true
      })), ticketQuantity === 1 && _react.default.createElement(_additionalInformationSection.default, null));
    }

    return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_navLinks.default, null), _react.default.createElement(_contactInformationSection.default, null), ticketQuantity === 1 && _react.default.createElement(_additionalInformationSection.default, null));
  };

  return _react.default.createElement(_formik.Form, null, _react.default.createElement(_core.Box, {
    className: classes.container
  }, registrationFeedback && _react.default.createElement(_core.Box, {
    className: classes.messageBlockContainer
  }, _react.default.createElement(_messageBlock.default, {
    level: "warning"
  }, _i18n.default.translate("errors.".concat(registrationFeedback), {
    defaultValue: registrationFeedback
  }))), renderContent(), _react.default.createElement(_termsAndConditions.default, {
    eventSlug: eventSlug,
    beforeText: 'contactInformation.termsAndConditions'
  }), _react.default.createElement(_handleFormValuesChange.default, null)));
}