var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = usePrevious;

var _react = _interopRequireDefault(require("react"));

function usePrevious(value) {
  var ref = _react.default.useRef();

  _react.default.useEffect(function () {
    ref.current = value;
  });

  return ref.current;
}