Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var networkingTableLiveViewStyle = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    speakerContainer: {
      marginVertical: theme.spacing.s,
      marginHorizontal: theme.spacing.s,
      padding: theme.spacing.m
    },
    speakerButtons: {
      marginLeft: 'auto'
    },
    button: {
      marginLeft: theme.spacing.s
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function () {
  return {
    speakerContainer: {},
    title: {
      fontSize: '1.2rem'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.xsm;
}, function (theme) {
  return {
    speakerContainer: {
      flexDirection: 'column'
    },
    speakerButtons: {
      marginLeft: 0,
      flexDirection: 'column'
    },
    button: {
      marginLeft: 0,
      marginTop: theme.spacing.s
    }
  };
}).buildHook();
var _default = networkingTableLiveViewStyle;
exports.default = _default;