var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasViewersCount;

var _react = _interopRequireDefault(require("react"));

var _reactKonva = require("react-konva");

var _numberUtils = require("../../../../utils/number-utils");

var _CanvasIcon = _interopRequireDefault(require("./CanvasIcon"));

function CanvasViewersCount(_ref) {
  var _ref$current;

  var children = _ref.children,
      _ref$count = _ref.count,
      count = _ref$count === void 0 ? 1000 : _ref$count,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 12 : _ref$y,
      _ref$x = _ref.x,
      x = _ref$x === void 0 ? 12 : _ref$x;

  var ref = _react.default.useRef(null);

  return _react.default.createElement(_react.default.Fragment, null, _react.default.createElement(_reactKonva.Label, {
    ref: ref,
    x: x,
    y: y
  }, _react.default.createElement(_reactKonva.Tag, {
    fill: "black",
    shadowColor: 'black',
    shadowBlur: 5,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowOpacity: 0.4
  }), _react.default.createElement(_reactKonva.Text, {
    padding: 4,
    height: 20,
    fontSize: 14,
    fill: 'white',
    text: "    ".concat((0, _numberUtils.numberFormatter)(count))
  }), _react.default.createElement(_CanvasIcon.default, {
    icon: _CanvasIcon.default.icons.EYE,
    size: 12,
    x: 16,
    y: 3,
    color: "white"
  })), children && children(((_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.width()) + x));
}