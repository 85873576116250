var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleInactivity;

var _core = require("@material-ui/core");

var _react = _interopRequireDefault(require("react"));

var _reactUse = require("react-use");

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var _Timeout = _interopRequireDefault(require("../Timeout"));

function HandleInactivity() {
  var _useNavigationExtende = (0, _useNavigationExtended.default)(),
      navigate = _useNavigationExtende.navigate;

  var idle = (0, _reactUse.useIdle)(3e4);
  return _react.default.createElement(_core.Dialog, {
    keepMounted: false,
    open: idle,
    style: {
      zIndex: 1800
    }
  }, _react.default.createElement(_core.DialogTitle, null, _i18n.default.translate('networking.youreInactive')), _react.default.createElement(_core.DialogContent, null, _react.default.createElement(_core.DialogContentText, null, _react.default.createElement(_Timeout.default, {
    ms: 1e4,
    onTimeout: function onTimeout() {
      return navigate(_routesConsts.Routes.NETWORKING_PAGE);
    }
  }, function (diff) {
    return _i18n.default.translate('networking.inactivityWarning', {
      seconds: diff
    });
  }))));
}