var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

var _immutable = _interopRequireDefault(require("immutable"));

var _actionTypes = require("./actionTypes");

var InitialState = _immutable.default.OrderedMap();

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : InitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState = state;

  switch (action.type) {
    case _actionTypes.ARE_EVENTS_LOADING:
      newState = newState.set('loading', _immutable.default.fromJS(action.loading));
      break;

    case _actionTypes.LOAD_EVENTS_COMPLETED:
      newState = newState.set('loading', _immutable.default.fromJS(false));
      newState = newState.set('events', action.events);
      break;

    case _actionTypes.LOAD_SCHEDULED_EVENTS_COMPLETED:
      for (var i in action.events) {
        newState = newState.set(action.events[i].id, _immutable.default.fromJS(action.events[i]));
        newState = newState.setIn([action.events[i].id, 'startDate'], new Date(action.events[i].startDate));
      }

      break;

    case _actionTypes.LOAD_LIVE_NOW_EVENTS_COMPLETED:
      for (var _i in action.events) {
        newState = newState.set(action.events[_i].id, _immutable.default.fromJS(action.events[_i]));
        newState = newState.setIn([action.events[_i].id, 'startDate'], new Date(action.events[_i].startDate));
      }

      break;

    case _actionTypes.LOAD_STAGE_PARTIAL_EVENTS_COMPLETED:
      for (var _i2 in action.events) {
        newState = newState.set(action.events[_i2].id, _immutable.default.fromJS(action.events[_i2]));
        newState = newState.setIn([action.events[_i2].id, 'startDate'], new Date(action.events[_i2].startDate));
      }

      break;

    case _actionTypes.LOAD_UPCOMING_EVENTS_COMPLETED:
      for (var _i3 in action.events) {
        newState = newState.set(action.events[_i3].id, _immutable.default.fromJS(action.events[_i3]));
        newState = newState.setIn([action.events[_i3].id, 'startDate'], new Date(action.events[_i3].startDate));
      }

      break;

    case _actionTypes.LOAD_PAST_EVENTS_COMPLETED:
      for (var _i4 in action.events) {
        newState = newState.set(action.events[_i4].id, _immutable.default.fromJS(action.events[_i4]));
        newState = newState.setIn([action.events[_i4].id, 'startDate'], new Date(action.events[_i4].startDate));
      }

      break;

    case _actionTypes.LOAD_PROMOTED_UPCOMING_EVENTS_COMPLETED:
      for (var _i5 in action.events) {
        newState = newState.set(action.events[_i5].id, _immutable.default.fromJS(action.events[_i5]));
        newState = newState.setIn([action.events[_i5].id, 'startDate'], new Date(action.events[_i5].startDate));
      }

      break;

    case _actionTypes.STATUS_CHANGED:
      newState = newState.setIn([action.eventId, 'status'], action.status);
      newState = newState.setIn([action.eventId, 'liveUrl'], action.liveUrl);
      newState = newState.setIn([action.eventId, 'description'], action.description);
      newState = newState.setIn([action.eventId, 'hashTags'], action.hashTags);
      newState = newState.setIn([action.eventId, 'priority'], action.priority);
      break;

    case _actionTypes.LOAD_EVENT_COMPLETED:
      newState = newState.set(action.event.id, _immutable.default.fromJS(action.event));
      newState = newState.setIn([action.event.id, 'startDate'], new Date(action.event.startDate));
      break;

    case _actionTypes.LOAD_MUSIC_TYPE_EVENTS_COMPLETED:
      for (var _i6 in action.events) {
        newState = newState.set(action.events[_i6].id, _immutable.default.fromJS(action.events[_i6]));
        newState = newState.setIn([action.events[_i6].id, 'startDate'], new Date(action.events[_i6].startDate));
      }

      break;

    case _actionTypes.LOAD_SCHEDULED_EVENTS_MULTIPLE_MUSIC_TYPES_COMPLETED:
      for (var _i7 in action.events) {
        newState = newState.set(action.events[_i7].id, _immutable.default.fromJS(action.events[_i7]));
        newState = newState.setIn([action.events[_i7].id, 'startDate'], new Date(action.events[_i7].startDate));
      }

      break;

    case _actionTypes.LOAD_DJ_UPCOMING_EVENTS_COMPLETED:
      for (var _i8 in action.events) {
        newState = newState.set(action.events[_i8].id, _immutable.default.fromJS(action.events[_i8]));
        newState = newState.setIn([action.events[_i8].id, 'startDate'], new Date(action.events[_i8].startDate));
      }

      break;

    case _actionTypes.LOAD_EVENTS_BY_HASHTAG:
      for (var _i9 in action.events) {
        newState = newState.set(action.events[_i9].id, _immutable.default.fromJS(action.events[_i9]));
        newState = newState.setIn([action.events[_i9].id, 'startDate'], new Date(action.events[_i9].startDate));
      }

      break;

    default:
      break;
  }

  return newState;
}