Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_FESTIVAL_FOLLOWERS_COMPLETED = exports.LOAD_FESTIVAL_COMPLETED = exports.LOAD_FESTIVALS_COMPLETED = exports.NAME = void 0;
var NAME = 'festival';
exports.NAME = NAME;
var LOAD_FESTIVALS_COMPLETED = 'festival/LOAD_FESTIVALS_COMPLETED';
exports.LOAD_FESTIVALS_COMPLETED = LOAD_FESTIVALS_COMPLETED;
var LOAD_FESTIVAL_COMPLETED = 'festival/LOAD_FESTIVAL_COMPLETED';
exports.LOAD_FESTIVAL_COMPLETED = LOAD_FESTIVAL_COMPLETED;
var LOAD_FESTIVAL_FOLLOWERS_COMPLETED = 'festival/LOAD_FESTIVAL_FOLLOWERS_COMPLETED';
exports.LOAD_FESTIVAL_FOLLOWERS_COMPLETED = LOAD_FESTIVAL_FOLLOWERS_COMPLETED;