Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useNetworkingTablePageStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    dialog: {
      maxWidth: '40vh'
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, {
  container: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}).buildHook();
var _default = useNetworkingTablePageStyles;
exports.default = _default;