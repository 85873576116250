var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _bowser = _interopRequireDefault(require("bowser"));

var _core = require("@material-ui/core");

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _i18n = _interopRequireDefault(require("../../../../utils/i18n"));

var _Browsers = require("../../../common/agora/Browsers");

var IsPlayerSupported = function IsPlayerSupported(_ref) {
  var children = _ref.children;

  var response = _react.default.createElement(_Spacing.default, {
    pSpacing: ['m', 0]
  }, _react.default.createElement(_core.Typography, {
    align: "center"
  }, _i18n.default.translate('networking.openSupportedBrowser')));

  if (window && window.navigator) {
    var browser = _bowser.default.getParser(window.navigator.userAgent);

    var osName = browser.getOSName();
    var browserName = browser.getBrowserName();
    var browserImages = null;

    if (['macOS', 'iOS'].includes(osName) && !['Chrome', 'Firefox'].includes(browserName)) {
      browserImages = _react.default.createElement(_react.default.Fragment, null, _Browsers.CHROME, _Browsers.FIREFOX, _Browsers.EDGE);
    } else {
      return _react.default.createElement(_react.default.Fragment, null, children);
    }

    return _react.default.createElement(_Spacing.default, {
      alignItems: "center",
      justifyContent: "center",
      direction: "column",
      pSpacing: "xl",
      color: "alternativeBackground",
      shaping: "cards",
      flex: 1
    }, response, _react.default.createElement(_Spacing.default, {
      mSpacing: ['l', 0]
    }, browserImages));
  } else {
    return _Browsers.UnknownOSResponse;
  }
};

var _default = IsPlayerSupported;
exports.default = _default;