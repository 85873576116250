var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createMiddleware;

var _asyncActionHandler = _interopRequireDefault(require("../../utils/redux/async-action-handler"));

var _eventInfo = _interopRequireDefault(require("../event-info"));

var _constants = require("../event-info/constants");

var _user = _interopRequireDefault(require("../user"));

var _constants2 = require("../user/constants");

var _actions = require("./actions");

var _constants3 = require("./constants");

var _selectors = require("./selectors");

function createMiddleware(_ref) {
  var socket = _ref.socket,
      firebase = _ref.firebase;
  return function (store) {
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_CONNECTION, onReceiveConnection(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_MESSAGE, onReceiveMessage(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_DELETE_MESSAGE, onReceiveDeleteMessage(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_USER_JOIN, onReceiveUserJoin(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_USER_LEAVE, onReceiveUserLeave(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_ROOM_JOIN, onReceiveRoomJoin(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_ROOM_CHANGE, onReceiveRoomChange(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_ROOM_LEAVE, onReceiveRoomLeave(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_USER_BLOCK, onReceiveUserBlock(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_USER_UNBLOCK, onReceiveUserUnblock(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.DISCONNECT, onReceiveDisconnect(store));
    socket.on(_constants3.WEBSOCKET_EVENTS.RECEIVE_ERROR, onReceiveError(store));
    var asyncActionHandler = new _asyncActionHandler.default(function () {
      store.dispatch((0, _actions.sendConnection)());
    }).waitOnce({
      type: _constants2.FIREBASE_AUTH_STATE_CHANGED
    }).waitOnce({
      type: _constants.LOAD_EVENT_INFO_COMPLETED
    });
    return function (next) {
      return function (action) {
        var state = store.getState();
        var socketStatus = (0, _selectors.socketStatusSelector)(state);
        var chatRoom = (0, _selectors.currentRoomSelector)(state);
        var roomLoading = (0, _selectors.roomLoadingSelector)(state);
        var roomStatus = {
          chatRoom: chatRoom,
          loading: roomLoading
        };

        var eventId = _eventInfo.default.selectors.selectEventInfoId(state);

        switch (action.type) {
          case _actions.sendConnection.type:
            if (socket.connected || socket.io.readyState === _constants3.WEBSOCKET_STATUS.CONNECTED || socketStatus === _constants3.WEBSOCKET_STATUS.CONNECTING) {
              return next((0, _actions.connectFailed)({
                error: 'chat.error.alreadyConnected'
              }));
            }

            var uid = _user.default.selectors.getFirebaseUserUid(state);

            if (!uid) {
              return next((0, _actions.connectFailed)({
                error: 'chat.error.userIsNotLoggedIn'
              }));
            }

            if (!eventId) {
              return next((0, _actions.connectFailed)({
                error: 'chat.error.noEventId'
              }));
            }

            firebase.auth().currentUser.getIdToken().then(function (token) {
              socket.setupConfig({
                query: {
                  token: token,
                  eventId: eventId
                }
              });
              socket.connect();
            }).catch(function () {
              store.dispatch((0, _actions.connectFailed)({
                error: 'chat.error.userIsNotLoggedIn'
              }));
            });
            break;

          case _actions.sendRoomJoin.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.roomConnectFailed)({
                error: 'chat.error.noSocketConnection'
              }));
            }

            if (roomStatus.loading || !!roomStatus.chatRoom) {
              return next((0, _actions.roomConnectFailed)({
                error: 'chat.error.userAlreadyInRoom'
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_ROOM_JOIN, {
              room: action.payload
            });
            break;

          case _actions.sendRoomReJoin.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.roomConnectFailed)({
                error: 'chat.error.noSocketConnection'
              }));
            }

            if (roomStatus.loading || !!roomStatus.chatRoom) {
              return next((0, _actions.roomConnectFailed)({
                error: 'chat.error.userAlreadyInRoom'
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_ROOM_JOIN, {
              room: action.payload
            });
            break;

          case _actions.sendRoomChange.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.roomConnectFailed)({
                error: 'chat.error.noSocketConnection'
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_ROOM_CHANGE, {
              room: action.payload
            });
            break;

          case _actions.sendRoomLeave.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.roomLeaveFailed)({
                error: 'chat.error.noSocketConnection'
              }));
            }

            if (!roomStatus.chatRoom) {
              return next((0, _actions.roomLeaveFailed)({
                error: 'chat.error.userNotInRoom'
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_ROOM_LEAVE);
            break;

          case _actions.sendUserBlock.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.sendUserBlockFailed)({
                error: 'chat.error.noSocketConnection'
              }));
            }

            if (!roomStatus.chatRoom) {
              return next((0, _actions.sendUserBlockFailed)({
                error: 'chat.error.userNotInRoom'
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_USER_BLOCK, action.payload);
            break;

          case _actions.sendUserUnblock.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.sendUserUnblockFailed)({
                error: 'chat.error.noSocketConnection'
              }));
            }

            if (!roomStatus.chatRoom) {
              return next((0, _actions.sendUserUnblockFailed)({
                error: 'chat.error.userNotInRoom'
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_USER_UNBLOCK, action.payload);
            break;

          case _actions.sendMessage.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.sendMessageFailed)({
                error: 'chat.error.noSocketConnection',
                message: action.payload
              }));
            }

            if (!roomStatus.chatRoom) {
              return next((0, _actions.sendMessageFailed)({
                error: 'chat.error.userNotInRoom',
                message: action.payload
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_MESSAGE, action.payload);
            break;

          case _actions.sendDeleteMessage.type:
            if (socketStatus !== _constants3.WEBSOCKET_STATUS.CONNECTED) {
              return next((0, _actions.sendDeleteMessageFailed)({
                error: 'chat.error.noSocketConnection',
                message: action.payload
              }));
            }

            if (!roomStatus.chatRoom) {
              return next((0, _actions.sendDeleteMessageFailed)({
                error: 'chat.error.userNotInRoom',
                message: action.payload
              }));
            }

            socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_DELETE_MESSAGE, action.payload);
            break;

          case _actions.sendDisconnect.type:
            if (socketStatus === _constants3.WEBSOCKET_STATUS.CONNECTED || socketStatus === _constants3.WEBSOCKET_STATUS.CONNECTING) {
              if (roomStatus.chatRoom) {
                socket.emit(_constants3.WEBSOCKET_EVENTS.SEND_ROOM_LEAVE);
              }

              socket.disconnect();
            }

            break;
        }

        var result = next(action);
        asyncActionHandler.next(action);
        return result;
      };
    };
  };
}

var onReceiveConnection = function onReceiveConnection(store) {
  return function (response) {
    var user = response.data.user;
    store.dispatch((0, _actions.receiveConnection)({
      user: user
    }));
  };
};

var onReceiveDisconnect = function onReceiveDisconnect(store) {
  return function (response) {
    store.dispatch((0, _actions.receiveDisconnect)());
  };
};

var onReceiveMessage = function onReceiveMessage(store) {
  return function (response) {
    var message = response.data.message;
    store.dispatch((0, _actions.receiveMessage)({
      message: message
    }));
  };
};

var onReceiveDeleteMessage = function onReceiveDeleteMessage(store) {
  return function (response) {
    var message = response.data.message;
    store.dispatch((0, _actions.receiveDeleteMessage)({
      message: message
    }));
  };
};

var onReceiveUserJoin = function onReceiveUserJoin(store) {
  return function (response) {
    var user = response.data.user;
    store.dispatch((0, _actions.receiveUserJoin)({
      user: user
    }));
  };
};

var onReceiveUserLeave = function onReceiveUserLeave(store) {
  return function (response) {
    var user = response.data.user;
    store.dispatch((0, _actions.receiveUserLeave)({
      user: user
    }));
  };
};

var onReceiveRoomJoin = function onReceiveRoomJoin(store) {
  return function (response) {
    store.dispatch((0, _actions.receiveRoomJoin)(response.data));
  };
};

var onReceiveRoomChange = function onReceiveRoomChange(store) {
  return function (response) {
    store.dispatch((0, _actions.receiveRoomChange)(response.data));
  };
};

var onReceiveRoomLeave = function onReceiveRoomLeave(store) {
  return function (response) {
    store.dispatch((0, _actions.receiveRoomLeave)());
  };
};

var onReceiveUserBlock = function onReceiveUserBlock(store) {
  return function (response) {
    var blockedUser = response.data.user;
    var state = store.getState();
    var chatRoom = (0, _selectors.currentRoomSelector)(state);

    var userId = _user.default.selectors.getFirebaseUserUid(state);

    if (userId === blockedUser.id && chatRoom) {
      store.dispatch((0, _actions.sendRoomLeave)());
      setTimeout(function () {
        store.dispatch((0, _actions.sendRoomJoin)(chatRoom));
      }, 1);
    }

    store.dispatch((0, _actions.receiveUserBlock)({
      user: blockedUser
    }));
  };
};

var onReceiveUserUnblock = function onReceiveUserUnblock(store) {
  return function (response) {
    var blockedUser = response.data.user;
    var state = store.getState();

    var userId = _user.default.selectors.getFirebaseUserUid(state);

    var isBlocked = (0, _selectors.imBlockedSelector)(state);

    if (userId === blockedUser.id && isBlocked) {
      store.dispatch((0, _actions.sendRoomLeave)());
    }

    store.dispatch((0, _actions.receiveUserUnblock)({
      user: blockedUser
    }));
  };
};

var onReceiveError = function onReceiveError(store) {
  return function (response) {
    console.log(response);
    var error = response.error;

    switch (error.type) {
      case _constants3.WEBSOCKET_ERRORS.AUTH:
        store.dispatch((0, _actions.receiveConnectionFailedError)({
          error: error
        }));
        break;

      case _constants3.WEBSOCKET_ERRORS.GENERAL:
        store.dispatch((0, _actions.receiveConnectionFailedError)({
          error: error
        }));
        break;

      case _constants3.WEBSOCKET_ERRORS.SEND_MESSAGE:
        store.dispatch((0, _actions.sendMessageFailed)({
          error: error,
          message: response.data.message
        }));
        break;

      case _constants3.WEBSOCKET_ERRORS.DELETE_MESSAGE:
        store.dispatch((0, _actions.sendDeleteMessageFailed)({
          error: error,
          message: response.data.message
        }));
        break;

      case _constants3.WEBSOCKET_ERRORS.BLOCK_USER:
        store.dispatch((0, _actions.sendUserBlockFailed)({
          error: error,
          user: response.data.user
        }));
        break;

      case _constants3.WEBSOCKET_ERRORS.UNBLOCK_USER:
        store.dispatch((0, _actions.sendUserUnblockFailed)({
          error: error,
          user: response.data.user
        }));
        break;
    }
  };
};