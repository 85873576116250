Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ON_TABLE_CHANGED_BY_ID = exports.SUBSCRIBE_TABLE_CHANGE_BY_ID = exports.ON_TABLES_CHANGED = exports.UNSUBSCRIBE_TABLES_CHANGES = exports.SUBSCRIBE_TABLES_CHANGES = exports.GET_TABLES = exports.NAME = void 0;
var NAME = 'networking';
exports.NAME = NAME;
var GET_TABLES = "".concat(NAME, "/GET_TABLES");
exports.GET_TABLES = GET_TABLES;
var SUBSCRIBE_TABLES_CHANGES = "".concat(NAME, "/SUBSCRIBE_TABLES_CHANGES");
exports.SUBSCRIBE_TABLES_CHANGES = SUBSCRIBE_TABLES_CHANGES;
var UNSUBSCRIBE_TABLES_CHANGES = "".concat(NAME, "/UNSUBSCRIBE_TABLES_CHANGES");
exports.UNSUBSCRIBE_TABLES_CHANGES = UNSUBSCRIBE_TABLES_CHANGES;
var ON_TABLES_CHANGED = "".concat(NAME, "/ON_TABLES_CHANGED");
exports.ON_TABLES_CHANGED = ON_TABLES_CHANGED;
var SUBSCRIBE_TABLE_CHANGE_BY_ID = "".concat(NAME, "/SUBSCRIBE_TABLE_CHANGE_BY_ID");
exports.SUBSCRIBE_TABLE_CHANGE_BY_ID = SUBSCRIBE_TABLE_CHANGE_BY_ID;
var ON_TABLE_CHANGED_BY_ID = "".concat(NAME, "/ON_TABLE_CHANGED_BY_ID");
exports.ON_TABLE_CHANGED_BY_ID = ON_TABLE_CHANGED_BY_ID;