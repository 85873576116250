var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFestivalPriority = exports.isLiveFestival = exports.getAccessTicketCode = exports.getAccessTicket = exports.isVipFestival = exports.getAccessTicketID = exports.getThemeColor = exports.getLogo = exports.getBackgroundImage = exports.getImage = exports.isReady = exports.getDescription = exports.getName = exports.getEndDate = exports.getStartDate = exports.getNrFollowers = exports.hasLoadedFestivals = exports.getFestivals = void 0;

var _constants = require("./constants");

var _events = _interopRequireDefault(require("../events"));

var _lodash = _interopRequireDefault(require("lodash"));

var getFestivals = function getFestivals(state) {
  return _lodash.default.orderBy(state[_constants.NAME].toArray(), [function (festival) {
    return getFestivalPriority(state, festival.get('id'));
  }, function (festival) {
    return festival.get('startDate');
  }], ['desc', 'asc']);
};

exports.getFestivals = getFestivals;

var hasLoadedFestivals = function hasLoadedFestivals(state) {
  return state[_constants.NAME].size > 0;
};

exports.hasLoadedFestivals = hasLoadedFestivals;

var getNrFollowers = function getNrFollowers(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'nrFollowers']);
};

exports.getNrFollowers = getNrFollowers;

var getStartDate = function getStartDate(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'startDate']);
};

exports.getStartDate = getStartDate;

var getEndDate = function getEndDate(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'endDate']);
};

exports.getEndDate = getEndDate;

var getName = function getName(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'name']);
};

exports.getName = getName;

var getDescription = function getDescription(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'description']);
};

exports.getDescription = getDescription;

var isReady = function isReady(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'status']) === 'ready';
};

exports.isReady = isReady;

var getImage = function getImage(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'image', 'contentUrl']);
};

exports.getImage = getImage;

var getBackgroundImage = function getBackgroundImage(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'backgroundImage', 'contentUrl']);
};

exports.getBackgroundImage = getBackgroundImage;

var getLogo = function getLogo(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'logo', 'contentUrl']);
};

exports.getLogo = getLogo;

var getThemeColor = function getThemeColor(state, festivalId) {
  var theme = state[_constants.NAME].getIn([festivalId, 'theme']);

  if (theme) {
    var obj = JSON.parse(state[_constants.NAME].getIn([festivalId, 'theme']));
    return obj.color;
  } else {
    return 'white';
  }
};

exports.getThemeColor = getThemeColor;

var getAccessTicketID = function getAccessTicketID(state, festivalId) {
  var FREE_TICKET_CODE = 'FREE';

  if (getAccessTicketCode(state, festivalId) === FREE_TICKET_CODE) {
    return undefined;
  }

  return state[_constants.NAME].getIn([festivalId, 'accessTicket', 'id']);
};

exports.getAccessTicketID = getAccessTicketID;

var isVipFestival = function isVipFestival(state, festivalId) {
  var FREE_TICKET_CODE = 'FREE';

  if (getAccessTicketCode(state, festivalId) === FREE_TICKET_CODE) {
    return false;
  }

  return getAccessTicket(state, festivalId) !== undefined;
};

exports.isVipFestival = isVipFestival;

var getAccessTicket = function getAccessTicket(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'accessTicket']);
};

exports.getAccessTicket = getAccessTicket;

var getAccessTicketCode = function getAccessTicketCode(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'accessTicket', 'code']);
};

exports.getAccessTicketCode = getAccessTicketCode;

var isLiveFestival = function isLiveFestival(state, festivalId) {
  var endDate = _events.default.selectors.getFestivalEndDate(state, festivalId);

  var startDate = getStartDate(state, festivalId);

  if (Date.now() > startDate && endDate > Date.now()) {
    return true;
  } else {
    return false;
  }
};

exports.isLiveFestival = isLiveFestival;

var getFestivalPriority = function getFestivalPriority(state, festivalId) {
  return state[_constants.NAME].getIn([festivalId, 'priority'], 0);
};

exports.getFestivalPriority = getFestivalPriority;