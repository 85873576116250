var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _core = require("@material-ui/core");

var _eventRegistrationUtils = require("./event-registration-utils");

var paperTopPadding = 10;
var paperSidesMargin = 8;
var useEventRegistrationContainerStyles = (0, _core.makeStyles)(function (theme) {
  var _theme$breakpoints$up;

  return {
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    dialogContainer: (0, _defineProperty2.default)({
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100vh',
      borderRadius: 8,
      padding: '24px 32px'
    }, theme.breakpoints.down(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      maxWidth: "calc(100% - ".concat(paperSidesMargin * 2, "px)"),
      padding: "".concat(paperTopPadding, "px ").concat(paperSidesMargin, "px"),
      maxHeight: "calc(100% - ".concat(paperTopPadding * 2, "px)")
    }),
    paperContainer: (0, _defineProperty2.default)({
      boxSizing: 'border-box',
      backgroundColor: theme.palette.containerBackground.main,
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: function gridTemplateRows(props) {
        return props.isLastStep ? '0.1fr 0.3fr minmax(0, 3.4fr) 0.2fr 0.25fr' : '0.1fr 0.3fr minmax(0, 3.4fr) 1fr auto 0.3fr';
      },
      gridTemplateAreas: function gridTemplateAreas(props) {
        return props.isLastStep ? '"close-button" "stepper" "component-content" "error-block" "navigation"' : '"close-button" "stepper" "component-content" "summary" "error-block" "navigation"';
      },
      width: '100%',
      height: '100%',
      borderRadius: 8,
      gap: '8px 0px'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), (_theme$breakpoints$up = {
      gridTemplateColumns: function gridTemplateColumns(props) {
        return props.isLastStep ? '1fr' : '1.5fr 0.5fr';
      },
      gridTemplateRows: '0.1fr 0.3fr 3.8fr'
    }, (0, _defineProperty2.default)(_theme$breakpoints$up, "gridTemplateRows", function gridTemplateRows() {
      return '0.1fr 0.3fr minmax(0, 3.4fr) auto 0.2fr';
    }), (0, _defineProperty2.default)(_theme$breakpoints$up, "gridTemplateAreas", function gridTemplateAreas(props) {
      return props.isLastStep ? '"close-button close-button" "stepper stepper" "component-content" "error-block error-block" "navigation navigation"' : '"close-button close-button" "stepper stepper" "component-content summary" "error-block error-block" "navigation navigation"';
    }), (0, _defineProperty2.default)(_theme$breakpoints$up, "gap", '10px 10px'), (0, _defineProperty2.default)(_theme$breakpoints$up, "borderRadius", 24), _theme$breakpoints$up)),
    mainSection: (0, _defineProperty2.default)({
      boxSizing: 'border-box',
      gridArea: 'component-content',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      flexDirection: 'row'
    }),
    contentContainer: (0, _defineProperty2.default)({
      overflowY: 'scroll'
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      margin: '0px 10px',
      overflowY: 'auto',
      flex: 2
    }),
    paymentSummaryContainer: (0, _defineProperty2.default)({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gridArea: 'summary',
      alignItems: 'center',
      borderRadius: 4,
      padding: 8,
      backgroundColor: "".concat(theme.palette.button.main, "26")
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      backgroundColor: 'unset',
      padding: 'unset',
      flex: 1
    }),
    bottomActions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: 500,
      padding: '0px 0px 40px'
    },
    button: {
      width: '100%',
      maxWidth: 235
    },
    buttonContainer: {
      width: '100%',
      maxWidth: 235
    },
    errorBlockStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: 8,
      color: theme.palette.error.main
    },
    closeContainer: {
      width: 'auto',
      gridArea: 'close-button',
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    closeButton: (0, _defineProperty2.default)({
      padding: 0,
      borderRadius: 8
    }, theme.breakpoints.up(_eventRegistrationUtils.BREAKPOINTS.layoutChange), {
      padding: 8
    })
  };
});
var _default = useEventRegistrationContainerStyles;
exports.default = _default;