Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useNetworkingTableLivePageStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    container: {
      marginLeft: theme.spacing.leftMenu
    },
    secondaryContainer: {
      marginVertical: theme.spacing.xl,
      marginHorizontal: theme.spacing.s
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function () {
  return {
    container: {
      marginLeft: 0
    }
  };
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref2) {
  var breakpoints = _ref2.breakpoints;
  return breakpoints.smd;
}, function (theme) {
  return {
    container: {
      flexDirection: 'column',
      alignItems: 'stretch',
      flexWrap: 'nowrap'
    },
    secondaryContainer: {
      marginHorizontal: theme.spacing.s
    }
  };
}).buildHook();
var _default = useNetworkingTableLivePageStyles;
exports.default = _default;