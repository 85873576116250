var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ResponsiveLiveContainer;

var _react = _interopRequireDefault(require("react"));

var _Spacing = _interopRequireDefault(require("../../../theme/components/spacing/Spacing"));

var _DesignSystem = require("../../../theme/DesignSystem");

var useResponsiveLiveContainerStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  container: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    alignItems: 'stretch'
  }
}).addMediaQueryBasedStyle(_DesignSystem.MediaConstants.direction.WIDTH, _DesignSystem.MediaConstants.operator.DOWN, function (_ref) {
  var breakpoints = _ref.breakpoints;
  return breakpoints.bg;
}, function (theme) {
  return {
    container: {
      flexDirection: 'column',
      paddingTop: 0,
      paddingLeft: 0
    }
  };
}).buildHook();

function ResponsiveLiveContainer(_ref2) {
  var children = _ref2.children;
  var Styles = useResponsiveLiveContainerStyles();
  return _react.default.createElement(_Spacing.default, {
    style: Styles.container
  }, children);
}