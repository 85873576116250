Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LEAVE", {
  enumerable: true,
  get: function get() {
    return _constants.LEAVE;
  }
});
exports.LOAD_USERS_COMPLETED = exports.LOAD_USERS = exports.REFRESH_LISTENERS = exports.JOIN_COMPLETED = exports.JOIN = void 0;

var _constants = require("./constants");

var JOIN = 'live/JOIN';
exports.JOIN = JOIN;
var JOIN_COMPLETED = 'live/JOIN_COMPLETED';
exports.JOIN_COMPLETED = JOIN_COMPLETED;
var REFRESH_LISTENERS = 'live/REFRESH_LISTENERS';
exports.REFRESH_LISTENERS = REFRESH_LISTENERS;
var LOAD_USERS = 'live/LOAD_USERS';
exports.LOAD_USERS = LOAD_USERS;
var LOAD_USERS_COMPLETED = 'live/LOAD_USERS_COMPLETED';
exports.LOAD_USERS_COMPLETED = LOAD_USERS_COMPLETED;