Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var useBoothFilesStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    file: {
      width: '100%'
    }
  };
}).buildHook();
var _default = useBoothFilesStyles;
exports.default = _default;