var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Color = void 0;

var _color = _interopRequireDefault(require("color"));

var tintColors = {
  mainColor: '#331A5A',
  yellowLighter: '#fdf8bb',
  yellowLight: '#fbf176',
  yellow: '#F8E71B',
  yellowDark: '#dfd018',
  yellowDarker: '#aea213',
  red: '#FF5151',
  green: '#70FF00',
  orange: '#FF6B00',
  grayLightest: '#fff',
  grayLighter: '#858585',
  grayLight: '#474747',
  gray: '#333333',
  grayDark: '#292929',
  grayDarker: '#1b1b1b',
  grayDarkest: '#000'
};
var Color = {
  primaryLighter: tintColors.yellowLighter,
  primaryLight: tintColors.yellowLighter,
  primary: tintColors.mainColor,
  primaryDark: tintColors.yellowDark,
  primaryDarker: tintColors.yellowDark,
  secondary: tintColors.grayLightest,
  accent: tintColors.red,
  destructive: tintColors.orange,
  positive: tintColors.green,
  neutralDarkest: tintColors.grayDarkest,
  neutralDarker: tintColors.grayDarker,
  neutralDark: tintColors.grayDark,
  neutral: tintColors.gray,
  neutralLight: tintColors.grayLight,
  neutralLighter: tintColors.grayLighter,
  neutralLightest: tintColors.grayLightest,
  neutralLightestOpacity01: (0, _color.default)(tintColors.grayLightest).alpha(0.1),
  neutralLightestOpacity02: (0, _color.default)(tintColors.grayLightest).alpha(0.2),
  neutralLightestOpacity03: (0, _color.default)(tintColors.grayLightest).alpha(0.3),
  neutralLightestOpacity05: (0, _color.default)(tintColors.grayLightest).alpha(0.5),
  neutralLightestOpacity07: (0, _color.default)(tintColors.grayLightest).alpha(0.7),
  neutralLightestOpacity08: (0, _color.default)(tintColors.grayLightest).alpha(0.8),
  neutralLightestOpacity09: (0, _color.default)(tintColors.grayLightest).alpha(0.9),
  neutralDarkestOpacity01: (0, _color.default)(tintColors.grayDarkest).alpha(0.1),
  neutralDarkestOpacity02: (0, _color.default)(tintColors.grayDarkest).alpha(0.2),
  neutralDarkestOpacity03: (0, _color.default)(tintColors.grayDarkest).alpha(0.3),
  neutralDarkestOpacity05: (0, _color.default)(tintColors.grayDarkest).alpha(0.5),
  neutralDarkestOpacity07: (0, _color.default)(tintColors.grayDarkest).alpha(0.7),
  neutralDarkestOpacity08: (0, _color.default)(tintColors.grayDarkest).alpha(0.8),
  neutralDarkestOpacity09: (0, _color.default)(tintColors.grayDarkest).alpha(0.9),
  background: tintColors.grayDarkest,
  surface: tintColors.grayDarker,
  text: tintColors.grayLightest,
  facebook: '#3968AF'
};
exports.Color = Color;