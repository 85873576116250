var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Partner;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _queryParameter = require("../../../utils/queryParameter");

var _DesignSystem = require("../../../theme/DesignSystem");

var _partnerStyles = _interopRequireDefault(require("./partner-styles"));

function Partner(_ref) {
  var partner = _ref.partner;

  var _useTheme = (0, _DesignSystem.useTheme)(),
      colors = _useTheme.colors;

  var Styles = (0, _partnerStyles.default)();
  return _react.default.createElement(_reactNative.TouchableHighlight, (0, _extends2.default)({}, partner.link && partner.link.length && {
    onPress: function onPress() {
      return window && (0, _queryParameter.windowOpen)(partner.link, '_blank');
    }
  }, {
    underlayColor: "".concat(colors.buttonHover, "55"),
    style: Styles.container
  }), _react.default.createElement(_reactNative.Image, {
    resizeMode: 'contain',
    style: Styles.image,
    source: {
      uri: partner.logoUrl
    }
  }));
}