Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ARROW_SIZE = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var ARROW_SIZE = 20;
exports.ARROW_SIZE = ARROW_SIZE;
var useTooltipStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  root: {
    maxWidth: '90%',
    position: 'relative'
  },
  triangle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderStyle: 'solid'
  },
  triangleLeft: {
    borderTopWidth: ARROW_SIZE,
    borderTopColor: 'transparent',
    borderRightWidth: ARROW_SIZE * 2,
    borderRightColor: '#FFF',
    borderBottomWidth: ARROW_SIZE,
    borderBottomColor: 'transparent',
    left: -ARROW_SIZE,
    right: 'auto'
  },
  triangleRight: {
    borderTopWidth: ARROW_SIZE,
    borderTopColor: 'transparent',
    borderLeftWidth: ARROW_SIZE * 2,
    borderLeftColor: '#FFF',
    borderBottomWidth: ARROW_SIZE,
    borderBottomColor: 'transparent',
    right: -ARROW_SIZE,
    left: 'auto'
  },
  triangleTop: {
    borderLeftWidth: ARROW_SIZE,
    borderLeftColor: 'transparent',
    borderBottomWidth: ARROW_SIZE * 2,
    borderBottomColor: '#FFF',
    borderRightWidth: ARROW_SIZE,
    borderRightColor: 'transparent',
    top: -ARROW_SIZE,
    bottom: 'auto'
  },
  triangleBottom: {
    borderLeftWidth: ARROW_SIZE,
    borderLeftColor: 'transparent',
    borderTopWidth: ARROW_SIZE * 2,
    borderTopColor: '#FFF',
    borderRightWidth: ARROW_SIZE,
    borderRightColor: 'transparent',
    bottom: -ARROW_SIZE,
    top: 'auto'
  },
  bottom: {
    left: '50%'
  },
  'bottom-start': {
    left: '25%'
  },
  'bottom-end': {
    left: '75%'
  },
  left: {
    top: '50%'
  },
  'left-start': {
    top: '25%'
  },
  'left-end': {
    top: '75%'
  },
  right: {
    top: '50%'
  },
  'right-start': {
    top: '25%'
  },
  'right-end': {
    top: '75%'
  },
  top: {
    left: '50%'
  },
  'top-start': {
    left: '25%'
  },
  'top-end': {
    left: '75%'
  }
}).buildHook();
var _default = useTooltipStyles;
exports.default = _default;