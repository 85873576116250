Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../theme/DesignSystem");

var useAvatarsStyles = new _DesignSystem.MakeStyle().addConstantStyle({
  avatar: {
    borderRadius: '100%'
  },
  avatarContainer: {
    borderRadius: '100%'
  },
  container: {
    position: 'relative',
    zIndex: 2
  }
}).buildHook();
var _default = useAvatarsStyles;
exports.default = _default;