var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HandleMuteSpeaker;

var _react = _interopRequireDefault(require("react"));

var _reactRedux = require("react-redux");

var _selectors = require("../../../core/user-rooms/selectors");

var _AudioVideoProvider = require("./AudioVideoProvider");

function HandleMuteSpeaker(_ref) {
  var userId = _ref.userId,
      roomId = _ref.roomId;
  var isMuted = (0, _reactRedux.useSelector)((0, _selectors.isUserMutedSelector)(roomId, userId));

  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      disableAudio = _useAudioVideoContext.disableAudio,
      enableAudio = _useAudioVideoContext.enableAudio,
      loading = _useAudioVideoContext.loading;

  _react.default.useEffect(function () {
    if (!loading) {
      if (typeof isMuted === 'boolean') {
        if (!isMuted) {
          enableAudio();
        } else {
          disableAudio();
        }
      }
    }
  }, [loading, isMuted, disableAudio, enableAudio]);

  return null;
}