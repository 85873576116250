Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadFestivals = loadFestivals;
exports.loadFestivalsCompleted = loadFestivalsCompleted;
exports.loadFestival = loadFestival;
exports.loadFestivalCompleted = loadFestivalCompleted;
exports.loadNumberOfFollowes = loadNumberOfFollowes;
exports.loadNumberOfFollowersCompleted = loadNumberOfFollowersCompleted;

var _actionTypes = require("./actionTypes");

function loadFestivals() {
  return function (dispatch, getState, _ref) {
    var api = _ref.api;
    return api.get({
      action: '/festivals?status[]=ready&status[]=draft'
    }).then(function (festival) {
      dispatch(loadNumberOfFollowes(festival.data));
      dispatch(loadFestivalsCompleted(festival.data));
    });
  };
}

function loadFestivalsCompleted(festival) {
  return {
    festival: festival,
    type: _actionTypes.LOAD_FESTIVALS_COMPLETED
  };
}

function loadFestival(festivalId) {
  return function (dispatch, getState, _ref2) {
    var api = _ref2.api;
    return api.get({
      action: "/festivals/".concat(festivalId)
    }).then(function (festival) {
      dispatch(loadNumberOfFollowes([festival.data]));
      dispatch(loadFestivalCompleted(festival.data));
    });
  };
}

function loadFestivalCompleted(festival) {
  return {
    festival: festival,
    type: _actionTypes.LOAD_FESTIVAL_COMPLETED
  };
}

function loadNumberOfFollowes(festivals) {
  return function (dispatch, getState, _ref3) {
    var api = _ref3.api;
    var apiCalls = [];

    var _loop = function _loop(i) {
      apiCalls.push(api.head({
        action: "/festivals/".concat(festivals[i].id, "/followers")
      }).then(function (response) {
        var range = api.getContentRange(response.headers);
        dispatch(loadNumberOfFollowersCompleted(festivals[i].id, range.total));
      }));
    };

    for (var i = 0; i < festivals.length; i++) {
      _loop(i);
    }

    return Promise.all(apiCalls).then(function () {
      return festivals;
    });
  };
}

function loadNumberOfFollowersCompleted(festivalId, nrFollowers) {
  return {
    festivalId: festivalId,
    nrFollowers: nrFollowers,
    type: _actionTypes.LOAD_FESTIVAL_FOLLOWERS_COMPLETED
  };
}