var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RemainedParticipants;

var _react = _interopRequireDefault(require("react"));

var _Avatar = _interopRequireDefault(require("./Avatar"));

var _GridItem = _interopRequireDefault(require("./GridItem"));

var _Audio = _interopRequireDefault(require("./Audio"));

function RemainedParticipants(_ref) {
  var grid = _ref.grid,
      webRtcParticipants = _ref.webRtcParticipants;
  var remainedParticipants = grid.remainedParticipants;
  return _react.default.createElement(_GridItem.default, grid, _react.default.createElement(_Avatar.default, {
    sizes: "".concat(grid.width / 3, "px")
  }, "+".concat(remainedParticipants.length + 1)), remainedParticipants.map(function (p) {
    var _webRtcParticipants$g;

    return _react.default.createElement(_Audio.default, {
      key: p.id,
      audioTrack: (_webRtcParticipants$g = webRtcParticipants.get(p.id)) === null || _webRtcParticipants$g === void 0 ? void 0 : _webRtcParticipants$g.audioTrack
    });
  }));
}