var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShortCode = exports.getLastMonthShortCode = exports.getNewDates = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _constants = require("./constants");

var getNewDates = function getNewDates() {
  return {
    endOfMonth: (0, _moment.default)().utc(0).endOf('month').toDate(),
    startOfMonth: (0, _moment.default)().utc(0).startOf('month').toDate()
  };
};

exports.getNewDates = getNewDates;

var getShortCode = function getShortCode() {
  var leaderboardMonth = (0, _moment.default)(getNewDates().startOfMonth).format('YYYY-MM');
  var shortCode = _constants.TOP_DJ_LEADERBOARD + leaderboardMonth;
  return shortCode.toString();
};

exports.getShortCode = getShortCode;

var getLastMonthShortCode = function getLastMonthShortCode() {
  var startOfMonth = getNewDates().startOfMonth;
  var year = (0, _moment.default)(startOfMonth).format('YYYY');
  var lastMonth = (0, _moment.default)(startOfMonth).month();

  if (lastMonth.toString().length === 1) {
    lastMonth = "0".concat(lastMonth);
  }

  if (lastMonth === '00') {
    year = Number(year) - 1;
    lastMonth = '12';
  }

  var lastMonthShortCode = "".concat(_constants.TOP_DJ_LEADERBOARD).concat(year, "-").concat(lastMonth);
  return lastMonthShortCode;
};

exports.getLastMonthShortCode = getLastMonthShortCode;