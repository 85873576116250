Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeCancelable = makeCancelable;

function makeCancelable(promise) {
  var hasCanceled_ = false;
  var wrappedPromise = new Promise(function (resolve, reject) {
    promise.then(function (val) {
      return hasCanceled_ ? reject({
        isCancelled: true
      }) : resolve(val);
    }).catch(function (error) {
      return hasCanceled_ ? reject({
        isCancelled: true
      }) : reject(error);
    });
  });
  return {
    promise: wrappedPromise,
    cancel: function cancel() {
      hasCanceled_ = true;
    }
  };
}