var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Toaster;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _lab = require("@material-ui/lab");

var useStyles = (0, _core.makeStyles)(function () {
  return (0, _core.createStyles)({
    root: {
      maxWidth: 600
    },
    alert: {
      textAlign: 'left'
    }
  });
});

function Toaster(_ref) {
  var visible = _ref.visible,
      onClose = _ref.onClose,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 6000 : _ref$duration,
      title = _ref.title,
      text = _ref.text,
      _ref$severity = _ref.severity,
      severity = _ref$severity === void 0 ? 'error' : _ref$severity;
  var classes = useStyles();
  return _react.default.createElement(_core.Snackbar, {
    className: classes.root,
    autoHideDuration: duration,
    open: visible,
    onClose: onClose,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    }
  }, _react.default.createElement(_lab.Alert, {
    variant: "filled",
    severity: severity,
    onClose: onClose,
    className: classes.alert
  }, title && _react.default.createElement(_lab.AlertTitle, null, title), text));
}