var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formik = require("formik");

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _checkoutStepFormDetails = _interopRequireDefault(require("./checkout-step-form-details"));

var _handleFormValuesChange = _interopRequireDefault(require("../common/handle-form-values-change"));

var useStyles = (0, _core.makeStyles)(function () {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      marginBottom: 25,
      flex: 1
    },
    stretchColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch'
    }
  };
});

var CheckoutStepForm = function CheckoutStepForm() {
  var classes = useStyles();
  return _react.default.createElement(_formik.Form, null, _react.default.createElement(_core.Box, {
    className: classes.form
  }, _react.default.createElement(_core.Box, {
    className: classes.stretchColumn
  }, _react.default.createElement(_checkoutStepFormDetails.default, null), _react.default.createElement(_handleFormValuesChange.default, null))));
};

var _default = CheckoutStepForm;
exports.default = _default;