var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useContextParticipants = useContextParticipants;
exports.useParticipants = useParticipants;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _AudioVideoProvider = require("../../components/common/audio-video/AudioVideoProvider");

var _agoraConstants = require("../../constants/agora-constants");

var _logger = _interopRequireDefault(require("../../core/services/logger"));

var _useListenEvent = _interopRequireDefault(require("./useListenEvent"));

var _useSafeState3 = _interopRequireDefault(require("./useSafeState"));

function useContextParticipants() {
  var _useAudioVideoContext = (0, _AudioVideoProvider.useAudioVideoContext)(),
      client = _useAudioVideoContext.client;

  var _useState = (0, _react.useState)(function () {
    var map = new Map();
    if (!client) return map;
    client.remoteUsers.forEach(function (p) {
      map.set(p.uid, p);
    });
    return map;
  }),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      participantsMap = _useState2[0],
      setParticipantsMap = _useState2[1];

  var event = (0, _useListenEvent.default)(_agoraConstants.subscriptionObserver);
  (0, _react.useEffect)(function () {
    if ((event === null || event === void 0 ? void 0 : event.type) === _agoraConstants.UNSUBSCRIBE_ALL_EVENT) {
      setParticipantsMap(new Map());
    }
  }, [event, setParticipantsMap]);
  (0, _react.useEffect)(function () {
    if (!client) return;

    function handle() {
      var map = new Map();
      client.remoteUsers.forEach(function (p) {
        map.set(p.uid, p);
      });
      setParticipantsMap(map);
    }

    client.on('user-joined', handle);
    client.on('user-left', handle);
    client.on('user-published', handle);
    client.on('user-unpublished', handle);
    return function () {
      client.off('user-joined', handle);
      client.off('user-left', handle);
      client.off('user-published', handle);
      client.off('user-unpublished', handle);
    };
  }, [client]);
  return participantsMap;
}

function useParticipants(client) {
  var _useSafeState = (0, _useSafeState3.default)((0, _react.useState)(function () {
    var map = new Map();
    if (!client) return map;
    client.remoteUsers.forEach(function (p) {
      map.set(p.uid, p);
    });
    return map;
  })),
      _useSafeState2 = (0, _slicedToArray2.default)(_useSafeState, 2),
      participantsMap = _useSafeState2[0],
      setParticipantsMap = _useSafeState2[1];

  var event = (0, _useListenEvent.default)(_agoraConstants.subscriptionObserver);
  (0, _react.useEffect)(function () {
    if ((event === null || event === void 0 ? void 0 : event.type) === _agoraConstants.UNSUBSCRIBE_ALL_EVENT) {
      _logger.default.debug('Unsubscribe all participants, because of a leave action!');

      setParticipantsMap(new Map());
    }
  }, [event, setParticipantsMap]);
  (0, _react.useEffect)(function () {
    if (!client) return;

    function handle() {
      var map = new Map();
      client.remoteUsers.forEach(function (p) {
        map.set(p.uid, p);
      });
      setParticipantsMap(map);
    }

    client.on('user-joined', handle);
    client.on('user-left', handle);
    client.on('user-published', handle);
    client.on('user-unpublished', handle);
    return function () {
      client.off('user-joined', handle);
      client.off('user-left', handle);
      client.off('user-published', handle);
      client.off('user-unpublished', handle);
    };
  }, [client]);
  return participantsMap;
}