Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reactNative = require("react-native");

var _spacing = require("../spacing");

var _default = _reactNative.StyleSheet.create({
  displayLarge: {
    fontSize: 40,
    lineHeight: 48,
    fontWeight: 'bold'
  },
  displayMedium: {
    fontSize: 32,
    lineHeight: 40,
    fontWeight: 'bold'
  },
  display: {
    fontSize: 24,
    lineHeight: 32
  },
  heading: {
    fontSize: 21,
    lineHeight: 29,
    fontWeight: 'bold'
  },
  subheading: {
    fontSize: 17,
    lineHeight: 24
  },
  text: {
    fontSize: 15,
    lineHeight: 22
  },
  caption: {
    fontSize: 11,
    lineHeight: 19
  },
  textWithIcon: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  icon: {
    marginRight: _spacing.Spacing.tight
  }
});

exports.default = _default;