var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MenuItem;

var _react = _interopRequireDefault(require("react"));

var _LinkButton = _interopRequireDefault(require("../../../../theme/components/buttons/LinkButton"));

var _Spacing = _interopRequireDefault(require("../../../../theme/components/spacing/Spacing"));

var _Typography = _interopRequireDefault(require("../../../../theme/components/typography/Typography"));

var _menuItemStyles = _interopRequireDefault(require("./menu-item-styles"));

function MenuItem(_ref) {
  var onPress = _ref.onPress,
      color = _ref.color,
      icon = _ref.icon,
      children = _ref.children,
      innerKey = _ref.innerKey;
  var Styles = (0, _menuItemStyles.default)();
  return _react.default.createElement(_Spacing.default, {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    pSpacing: [0, 's'],
    style: Styles.menuItem
  }, _react.default.createElement(_LinkButton.default, {
    innerKey: innerKey,
    onPress: onPress,
    style: Styles.linkButton
  }, _react.default.createElement(_Spacing.default, {
    direction: "column",
    alignItems: "center",
    justifyContent: "center"
  }, _react.default.createElement(_Typography.default, {
    color: color
  }, icon), _react.default.createElement(_Typography.default, {
    variant: "smallText",
    color: color,
    style: Styles.linkText
  }, children))));
}