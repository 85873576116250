var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _core = require("@material-ui/core");

var _DesignSystem = require("../../../theme/DesignSystem");

function BlockIconButton(props) {
  var _useTheme = (0, _DesignSystem.useTheme)(),
      errorColor = _useTheme.colors.errorColor;

  var _BlockIconButton = (0, _core.withStyles)({
    root: {
      width: 24,
      height: 24,
      position: 'relative',
      backgroundColor: "".concat(errorColor, " !important"),
      borderRadius: '50%',
      cursor: 'pointer',
      '&:hover': {},
      '& div': {
        width: '58%',
        height: '17%',
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff'
      }
    }
  })(_core.IconButton);

  return _react.default.createElement(_BlockIconButton, props, _react.default.createElement("div", null));
}

var _default = BlockIconButton;
exports.default = _default;