var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CanvasLayout;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _LayoutStrategies = require("../LayoutStrategies");

function CanvasLayout(_ref) {
  var children = _ref.children,
      items = _ref.items,
      containerWidth = _ref.containerWidth,
      hasScreenShare = _ref.hasScreenShare;

  var _React$useState = _react.default.useState(new _LayoutStrategies.ScreenShareWithParticipantsLayoutStrategy()),
      _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
      strategy = _React$useState2[0],
      setStrategy = _React$useState2[1];

  _react.default.useEffect(function () {
    if (hasScreenShare) {
      setStrategy(new _LayoutStrategies.ScreenShareWithParticipantsLayoutStrategy());
    } else {
      setStrategy(new _LayoutStrategies.GridLayoutStrategy());
    }
  }, [hasScreenShare, setStrategy]);

  var _React$useMemo = _react.default.useMemo(function () {
    return strategy.calculate(items, containerWidth);
  }, [items, strategy, containerWidth]),
      grids = _React$useMemo.grids,
      type = _React$useMemo.type;

  return _react.default.createElement(_react.default.Fragment, null, children(grids, type));
}