var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _DesignSystem = require("../../../../theme/DesignSystem");

var _colorUtils = _interopRequireDefault(require("../../../../utils/color-utils"));

var liveStreamErrorsStyles = new _DesignSystem.MakeStyle().addThemeBasedStyle(function (theme) {
  return {
    actionContainer: {
      alignItems: 'center',
      paddingBottom: '56.25%',
      height: 0,
      position: 'relative',
      backgroundColor: (0, _colorUtils.default)(theme.colors.pageBackground, '#FFFFFF', '#000000'),
      borderRadius: theme.shape.cards.borderRadius
    }
  };
}).buildHook();
var _default = liveStreamErrorsStyles;
exports.default = _default;