var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactNative = require("react-native");

var _reactNavigationHooks = require("react-navigation-hooks");

var _reactRedux = require("react-redux");

var _recordedVideos = _interopRequireDefault(require("../../../core/recordedVideos"));

var _routesConsts = require("../../../navigation/client/routes-consts");

var _useHeader = _interopRequireDefault(require("../../../utils/hooks/useHeader"));

var _useNavigationExtended = _interopRequireDefault(require("../../../utils/hooks/useNavigationExtended"));

var _videoModalView = _interopRequireDefault(require("../../common/video-modal/video-modal-view"));

var _recorderVideoPlayerStyles = _interopRequireDefault(require("./recorder-video-player-styles"));

var _backButton = _interopRequireDefault(require("../../auth/back-button/"));

var _actions = require("../../../core/recordedVideos/actions");

var _selectors = require("../../../core/tickets/selectors");

var _i18n = _interopRequireDefault(require("../../../utils/i18n"));

var RecordedVideoPlayer = function RecordedVideoPlayer() {
  var id = (0, _reactNavigationHooks.useNavigationParam)(_routesConsts.navigationParams.RECORDED_VIDEO_ID);
  var navigation = (0, _useNavigationExtended.default)();
  var dispatch = (0, _reactRedux.useDispatch)();
  var accessRecordedSessions = (0, _reactRedux.useSelector)(_selectors.selectAccessRecordedSessions);
  var recordedVideo = (0, _reactRedux.useSelector)(function (state) {
    return _recordedVideos.default.selectors.selectRecordedVideoById(state, id);
  });
  var isLoaded = (0, _reactRedux.useSelector)(_recordedVideos.default.selectors.selectRecordedVideosIsLoaded);
  (0, _useHeader.default)({
    visible: false,
    withScroll: false,
    withLeftMenu: true
  });

  _react.default.useEffect(function () {
    if (!recordedVideo) {
      dispatch((0, _actions.getRecordedVideos)());
    }
  }, [dispatch, accessRecordedSessions, recordedVideo]);

  if (!recordedVideo && isLoaded) {
    return _react.default.createElement(_reactNative.View, null, _react.default.createElement(_backButton.default, null), _react.default.createElement(_reactNative.Text, null, _i18n.default.t('videos.notFoundVideo')));
  }

  return _react.default.createElement(_reactNative.ScrollView, {
    contentContainerStyle: _recorderVideoPlayerStyles.default.container
  }, recordedVideo && (recordedVideo.assetAccessId || recordedVideo.downloadUrl) && _react.default.createElement(_videoModalView.default, {
    video: recordedVideo.assetAccessId ? "https://stream.mux.com/".concat(recordedVideo.assetAccessId, ".m3u8") : recordedVideo.downloadUrl,
    type: recordedVideo.type,
    title: recordedVideo.title,
    hideModal: function hideModal() {
      navigation.navigate(_routesConsts.Routes.RECORDED_VIDEOS);
    }
  }));
};

var _default = RecordedVideoPlayer;
exports.default = _default;